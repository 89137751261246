export const URL_CUSTOMER_MANAGE_LIST = '/scrm-platform/wechat/customer/custom_manage'
export const URL_QUERY_ADD_WAY = '/scrm-platform/wechat/customer/channel/list'
export const URL_QUERY_ADD_USER_LIST = '/scrm-platform/wechat/customer/adduser/list'
export const URL_QUERY_CUSTOMER_DETAILS = '/scrm-platform/wechat/customer/custom_detail'
export const URL_UPDATE_CUSTOMER_LABEL = '/scrm-platform/wechat/customer/custom_detail/marktag'
export const URL_REFRESH_DATA_LIST = '/scrm-platform/wechat/customer/batch/getbyuser'

// 标签组
export const URL_QUERY_LABEL_LIST = '/scrm-platform/wechat/tag/tag_info_list'
export const URL_SYNCHRONIZE_LABEL = '/scrm-platform/wechat/tag/get_corp_tag_list'
export const URL_ADD_LABEL = '/scrm-platform/wechat/tag/add_corp_tag'
export const URL_UPDATE_LABEL = '/scrm-platform/wechat/tag/edit_corp_tag'
export const URL_DELETE_LABEL = '/scrm-platform/wechat/tag/del_corp_tag'
export const URL_POST_MARKTAG = '/scrm-platform/wechat/customer/custom_detail/marktag/batch'

// 素材库
export const URL_MATERIAL_LIBRARY_PAGE_LIST = '/scrm-platform/wechat/media/list'
export const URL_ADD_TEXT_MATERIAL = '/scrm-platform/wechat/media/upload/text'
export const URL_ADD_IMAGE_MATERIAL = '/scrm-platform/wechat/media/upload/pic'
export const URL_ADD_FILE_MATERIAL = '/scrm-platform/wechat/media/upload/temp'
export const URL_UPDATE_MATERIAL = '/scrm-platform/wechat/media/edit/text'
export const URL_DELETE_MATERIAL = '/scrm-platform/wechat/media/delete'
export const URL_DOWNLOAD_MATERIAL = '/scrm-platform/qrcode/generate/download/pic'
export const URL_MEDIA_UPLOAD_DOC = '/scrm-platform/wechat/media/upload/doc'
export const URL_MEDIA_SPEECHCRAFT_LIST = '/scrm-platform/wechat/media/speechcraft/list'
export const URL_MEDIA_SPEECHCRAFT_INSERT = '/scrm-platform/wechat/media/speechcraft/insert'
export const URL_MEDIA_SPEECHCRAFT_DELETE = '/scrm-platform/wechat/media/speechcraft/delete'
export const URL_MEDIA_SPEECHCRAFT_UPDATE = '/scrm-platform/wechat/media/speechcraft/update'

// 客户阶段
export const URL_CUSTOMER_STAGE_LIST = '/scrm-platform/wechat/customer/custom_stage/list'
export const URL_UPDATE_CUSTOMER_STAGE = '/scrm-platform/wechat/customer/custom_stage/marktag'
export const URL_CUSTOMER_LOST_CUSTOMER = '/scrm-platform/wechat/customer/lost/customer'
export const URL_CUSTOMER_STAGE_FILE_DOWNLOAD = '/scrm-platform/wechat/customer/custom_stage/file/download'
export const URL_CUSTOMER_STAGE_NOTSET = '/scrm-platform/wechat/customer/custom_stage/notset'
export const URL_CUSTOMER_STAGE_LIST_SPREAD = '/scrm-platform/wechat/customer/custom_stage/spread/out'
export const URL_CUSTOMER_GET_GROUPINFO = '/scrm-platform/wechat/customer/get/groupInfo'
export const URL_CUSTOMER_GET_ACTDETAIL = '/scrm-platform/wechat/customer/get/actDetail/groupByDate'
export const URL_CUSTOMER_CUSTOMER_REMARK = '/scrm-platform/wechat/customer/customer_remark'
export const URL_CUSTOMER_CUSTOMER_STAGE_LIST = '/scrm-platform/wechat/customer/customer/stage/list'
export const URL_CUSTOMER_CUSTOMER_STAGE_EDITANDADD = '/scrm-platform/wechat/customer/customer/stage/editandadd'
export const URL_CUSTOMER_CUSTOMER_STAGE_DELETE = '/scrm-platform/wechat/customer/customer/stage/delete'
// 短信模板
export const URL_LIST_SMS_TEMPLATE = '/scrm-platform/shortmessage/template/select'
export const URL_ADD_SMS_TEMPLATE = '/scrm-platform/shortmessage/template/add'
export const URL_EDIT_SMS_TEMPLATE = '/scrm-platform/shortmessage/template/edit'
export const URL_DELETE_SMS_TEMPLATE = '/scrm-platform/shortmessage/template/delete'
export const URL_REFRESH_SMS_TEMPLATE = '/scrm-platform/shortmessage/template/status'

// 短信任务
export const URL_LIST_SMS_TASK = '/scrm-platform/shortmessage/mission/list'
export const URL_ADD_SMS_TASK = '/scrm-platform/shortmessage/creat/mission'
export const URL_FILE_UPLOAD = '/scrm-platform/shortmessage/file/upload'
export const URL_FILE_DOWNLOAD = '/scrm-platform/shortmessage/file/download'
export const URL_MESSAGE_CONFIG_LIST = '/scrm-platform/shortmessage/config/list'
export const URL_MESSAGE_CONFIG_ADD = '/scrm-platform/shortmessage/config/add'
export const URL_MESSAGE_CONFIG_UPDATE = '/scrm-platform/shortmessage/config/update'
export const URL_MESSAGE_PACKAGE_CALCULATE = '/scrm-platform/shortmessage/package/calculate'
export const URL_MESSAGE_SEND_STATUES_STATISTIC = '/scrm-platform/shortmessage/send/status/statistic'
export const URL_MESSAGE_PULL_REPLY_STATUS = '/scrm-platform/shortmessage/pull/reply/status'

// 群列表
export const URL_QUERYPERSONAL_PAGE_LIST = '/scrm-platform/wechat/group/groupchat_list'
export const URL_WE_CHAT_SYNCHRONIZE_GROUP = '/scrm-platform/wechat/group/groupchat_sync'
export const URL_QUERY_GROUP_DETAILE = '/scrm-platform/wechat/group/groupchat_detail'

// 群数据统计
export const URL_GROUP_DATA_POLYMERIZATION = '/scrm-platform/wechat/group/groupchat/statistic'
export const URL_GROUP_DATA_DAY_POLYMERIZATION = '/scrm-platform/wechat/group/groupchat/statistic/groupbyday'

// 群SOP
export const URL_GROUP_SOP_PAGE_LIST = '/scrm-platform/wechat/group/sop/list'
export const URL_CREATE_GROUP_SOP_RULE = '/scrm-platform/wechat/group/sop/create'
export const URL_DELETE_GROUP_SOP_RULE = '/scrm-platform/wechat/group/sop/list'
export const URL_GROUP_SOP_RULE_DETAILES = '/scrm-platform/wechat/group/sop/detail'
export const URL_GET_SOP_PERSON_LIST = '/scrm-platform/wechat/customer/adduser/list'
export const URL_GROUP_SOP_EDIT = '/scrm-platform/wechat/group/sop/edit'
export const URL_GROUP_SOP_ADDUSER_LIST = '/scrm-platform/wechat/customer/adduser/list'
export const URL_GROUP_ADDRESS_LIST = '/scrm-platform/wechat/customer/address_list/search'

// 个人SOP
export const URLPERSONAL_SOP_PAGE_LIST = '/scrm-platform/wechat/customer/sop/list'
export const URL_CREATE_PERSONAL_SOP_RULE = '/scrm-platform/wechat/customer/sop/create'
export const URL_DELET_EPERSONAL_SOP_RULE = '/scrm-platform/wechat/customer/sop/list'
export const URL_PERSONAL_SOP_RULE_DETAILES = '/scrm-platform/wechat/customer/sop/detail'
export const URL_PERSONAL_SOP_EDIT = '/scrm-platform/wechat/customer/sop/edit'
export const URL_PERSONAL_SOP_CREATE = '/scrm-platform/wechat/customer/sop/create'
export const URL_PERSONAL_SOP_DELETE = '/scrm-platform/wechat/customer/sop/delete'
export const URL_MEDIA_UPLOAD_ATTACHMENT = '/scrm-platform/wechat/media/upload_attachment'

// 群标签
export const URL_GROUP_LABEL_LIST = '/scrm-platform/wechat/group/groupchat/taglist'
export const URL_CREATE_GROUP_LABEL = '/scrm-platform/wechat/group/groupchat/tagadd'
export const URL_DELETE_GROUP_LABEL = '/scrm-platform/wechat/group/groupchat/tagdelete'
export const URL_UPDATE_GROUP_LABEL = '/scrm-platform/wechat/group/groupchat/tagedit'
export const URL_UPDATE_CUSTOMER_TAGS_NUM = '/scrm-platform//wechat/customer/rule/num'
export const URL_GROUP_GROUPCHAT_LABEL_TAG = '/scrm-platform/wechat/group/groupchat/label/tag'
export const URL_GROUP_TAGS_NUM = '/scrm-platform/wechat/group/tags/num'
export const URL_GROUP_SOP_DELETE = '/scrm-platform/wechat/group/sop/delete'
// 二维码
export const URL_CODE_UPLOAD_PIC = '/scrm-platform/qrcode/generate/upload/pic'
export const URL_CODE_GENERATE_QRCODE = '/scrm-platform/qrcode/generate/generate/qrcode'
export const URL_CODE_TEMPLATE_ADD = '/scrm-platform/qrcode/generate/history/file/list'
export const URL_CODE_DOWNLOAD_PIC = '/scrm-platform/qrcode/generate/download/pic'
export const URL_CODE_DELETE_PIC = '/scrm-platform/qrcode/generate/delete/pic'

// 引流
export const URL_WECHAT_CONTACT_LIST = '/scrm-platform/wechat/contact/contact_way/list'
export const URL_WECHAT_CONTACT_ADD_WAY = '/scrm-platform/wechat/contact/add_contact_way'
export const URL_WECHAT_CONTACT_DEL_WAY = '/scrm-platform/wechat/contact/del_contact_way'
export const URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY_LIST = '/scrm-platform/wechat/group/groupchat/add_join_way_list'
export const URL_WECHAT_GROUP_GROUP_DETAIL = '/scrm-platform/wechat/group/group/detail'
export const URL_WECHAT_GROUPCHAT_ADD_JOIN_WAY = '/scrm-platform/wechat/group/groupchat/add_join_way'
export const URL_WECHAT_GROUPCHAT_DEL_JOIN_WAY = '/scrm-platform/wechat/group/groupchat/del_join_way'
export const URL_WECHAT_GROUPCHAT_UPDETA_JOIN_WAY = '/scrm-platform/wechat/group/groupchat/update_join_way'
export const URL_WECHAT_SHORTMESSAGE_GROUP_INFO = '/scrm-platform/shortmessage/group/info'

// 群欢迎语
export const URL_GROUP_WELCOME_TEMPLATE_LIST = '/scrm-platform/wechat/group/group_welcome_template/list'
export const URL_GROUP_WELCOME_TEMPLATE_ADD = '/scrm-platform/wechat/group/group_welcome_template/add'
export const URL_GROUP_WELCOME_TEMPLATE_GET = '/scrm-platform/wechat/group/group_welcome_template/get'
export const URL_GROUP_WELCOME_TEMPLATE_EDIT = '/scrm-platform/wechat/group/group_welcome_template/edit'
export const URL_GROUP_WELCOME_TEMPLATE_DEL = '/scrm-platform/wechat/group/group_welcome_template/del'

// 企微
export const URL_WECHAT_CUSTOMER_CONFIG_LIST = '/scrm-platform/wechat/customer/config/list'
export const URL_WECHAT_CUSTOMER_GET_JSAPI_TICKET = '/scrm-platform/wechat/customer/get/jsapi_ticket'
export const URL_WECHAT_CUSTOMER_CONFIG_UPDATE = '/scrm-platform/wechat/customer/config/update'
export const URL_WECHAT_CUSTOMER_ADDRESS_LIST = '/scrm-platform/wechat/customer/address_list/search'
export const URL_WECHAT_CUSTOMER_DEPARTMENT_LIST = '/scrm-platform/wechat/customer/department_list'
export const URL_WECHAT_CONTACT_INTERCEPT_LIST = '/scrm-platform/wechat/contact/intercept_rule_list'
export const URL_WECHAT_CONTACT_ADD_INTERCEPT_RULE = '/scrm-platform/wechat/contact/add_intercept_rule'
export const URL_WECHAT_CONTACT_UPDATE_INTERCEPT_RULE = '/scrm-platform/wechat/contact/update_intercept_rule'
export const URL_WECHAT_CONTACT_DEL_INTERCEPT_RULE = '/scrm-platform/wechat/contact/del_intercept_rule'
export const URL_WECHAT_CONTACT_DEPARTMENT_INFO = '/scrm-platform/wechat/customer/department/info'
export const URL_WECHAT_GROUP_GET_USERGROUP_INFO = '/scrm-platform/wechat/group/get/usergroup/info'
export const URL_WECHAT_CUSTOMER_TRANSFER_CUSTOMER = '/scrm-platform/wechat/customer/transfer_customer'
export const URL_WECHAT_CUSTOMER_GROUP_ONJOB_TRANSFER = '/scrm-platform/wechat/customer/groupchat/onjob_transfer'
export const URL_WECHAT_CUSTOMER_GROUP_TRANSFER_STATUS = '/scrm-platform/wechat/customer/groupchat/transfer/status'
export const URL_WECHAT_CUSTOMER_TRANSFER_RESULT_LIST = '/scrm-platform/wechat/customer/transfer_result/list'
export const URL_WECHAT_CUSTOMER_EXTERNALCONTACT_TRANSFER_RESULT = '/scrm-platform/wechat/customer/externalcontact/transfer_result'
export const URL_WECHAT_CUSTOMER_GET_USER_ALLINFOS = '/scrm-platform/wechat/customer/get/user/allinfos'

// 系统管理
export const URL_ROLE_LIST = '/scrm-platform/api/role/list'
export const URL_ROLE_DELETE = '/scrm-platform/api/role/delete'
export const URL_MENUS_LIST_MENU = '/scrm-platform/api/menus/list/menu'
export const URL_PERMISSION_LIST_MENU = '/scrm-platform/api/permission/list/menu'
export const URL_ROLE_UPDATE = '/scrm-platform/api/role/update'
export const URL_ROLE_ADD = '/scrm-platform/api/role/add'
export const URL_MENUS_UPDATE = '/scrm-platform/api/role/menus/update'
export const URL_PERMISSION_UPDATE = '/scrm-platform/api/role/permission/update'
export const URL_USER_LIST = '/scrm-platform/api/user/list'
export const URL_USER_DELETE = '/scrm-platform/api/user/delete'
export const URL_ROLE_LIST_ALL = '/scrm-platform/api/role/list/all'
export const URL_USER_ROLE_LIST = '/scrm-platform/api/user/role/list'
export const URL_USER_ROLE_UPDATE = '/scrm-platform/api/user/role/update'
export const URL_USER_ROLE_ADD = '/scrm-platform/api/user/role/add'
export const URL_PERMISSION_ADD = '/scrm-platform/api/permission/add'
export const URL_PERMISSION_DELETE = '/scrm-platform/api/permission/delete'
export const URL_USER_UPDATE = '/scrm-platform/api/user/update'
export const URL_USER_ADD = '/scrm-platform/api/user/add'
export const URL_ROLE_MENUS_LIST = '/scrm-platform/api/role/menus/list'
export const URL_ROLE_PERMISSION_LIST = '/scrm-platform/api/role/permission/list'

// 登陆
export const URL_USER_LOGIN = '/scrm-platform/api/user/login'
export const URL_USER_LOGOUT = '/scrm-platform/api/user/logout'
export const URL_USER_CODE = '/scrm-platform/message/verify/verifyCodeLogin'

// 首页
export const URL_GENERAL_VIEW = '/scrm-platform/wechat/report/general/view'

// 自动回复
export const URL_ACCOUNT_AUTO_REPLY_LIST = '/scrm-platform/official/account/auto/reply/list'
export const URL_OFFICIAL_ACCOUNT_ADD_AUTO_REPLY = '/scrm-platform/official/account/add/auto/reply'
export const URL_OFFICIAL_ACCOUNT_ADD_FOREVER_MATERIAL = '/scrm-platform/official/account/add/forever/material'
export const URL_ACCOUNT_AUTO_REPLY_DELETE = '/scrm-platform/official/account/delete/auto/reply'
export const URL_OFFICIAL_ACCOUNT_EDIT_AUTO_REPLY = '/scrm-platform/official/account/edit/auto/reply'
export const URL_OFFICIAL_ACCOUNT_EDIT_AUTO_REPLY_DETAIL = 'scrm-platform/official/account/autoreply/detail'

// 渠道二维码
export const URL_OFFICIAL_ACCOUNT_QRCODE_CALCULATE = '/scrm-platform/official/account/qrcode/calculate'
export const URL_OFFICIAL_ACCOUNT_QRCODE_LIST = '/scrm-platform/official/account/qrcode/list'
export const URL_OFFICIAL_ACCOUNT_QRCODE_CREATE = '/scrm-platform/official/account/qrcode/create'
export const URL_OFFICIAL_ACCOUNT_QRCODE_DELETE = '/scrm-platform/official/account/qrcode/delete'

// 素材管理
export const URL_OFFICIAL_ACCOUNT_FOREVER_MATERIAL_LIST = '/scrm-platform/official/account/forever/material/list'
export const URL_OFFICIAL_ACCOUNT_FOREVER_MATERIAL_DELETE = '/scrm-platform/official/account/forever/material/delete'
// 粉丝管理
export const URL_OFFICIAL_ACCOUNT_TAGSGET = '/scrm-platform/official/account/tagsget'
export const URL_OFFICIAL_ACCOUNT_TAGSCREATE = '/scrm-platform/official/account/tagscreate'
export const URL_OFFICIAL_ACCOUNT_TAGSEDIT = '/scrm-platform/official/account/tagsedit'
export const URL_OFFICIAL_ACCOUNT_TAGESDEL = '/scrm-platform/official/account/tagsdelete'
export const URL_OFFICIAL_ACCOUNT_USERLIST = '/scrm-platform/official/account/userlist'
export const URL_CUSTOMER_ACCOUNT_GETUSERLIST = '/scrm-platform/official/account/getuserlist'
export const URL_CUSTOMER_ACCOUNT_BATCHTAG = '/scrm-platform/official/account/batchtagging'
export const URL_CUSTOMER_ACCOUNT_BATCHUNTAG = '/scrm-platform/official/account/batchuntagging'

// 公众号
export const URL_OFFICIAL_ACCOUNT_UPDATE_CONFIG = '/scrm-platform/official/account/update/config'
export const URL_OFFICIAL_ACCOUNT_UPDATE_LIST = '/scrm-platform/official/account/update/list'
export const URL_OFFICIAL_ACCOUNT_MENU_LIST = '/scrm-platform/official/account/menu/list'
export const URL_OFFICIAL_ACCOUNT_CREATE_MENU_LIST = '/scrm-platform/official/account/menu/create'

// 消息推送
export const URL_OFFICIAL_SOP_TEMPLATE_MISSION_LIST = '/scrm-platform/official/sop/template/mission/list'
export const URL_OFFICIAL_SOP_TEMPLATE_LIST = '/scrm-platform/official/sop/template/get_all_template_list'
export const URL_OFFICIAL_SOP_TEMPLATE_PRIVATE_LIST = '/scrm-platform/official/sop/template/get_all_private_template'
export const URL_OFFICIAL_SOP_TEMPLATE_MISSION_CREATE = '/scrm-platform/official/sop/template/mission/create'
export const URL_OFFICIAL_SOP_TEMPLATE_MISSION_DELETE = '/scrm-platform/official/sop/template/mission/delete'
export const URL_OFFICIAL_SOP_TEMPLATE_LOG_LIST = '/scrm-platform/official/sop/template/log/list'
// 群发消息
export const URL_OFFICIAL_SOP_MASSSENDALL_LIST = '/scrm-platform/official/sop/massSendall/list'
export const URL_OFFICIAL_SOP_MASSSENDALL_DELETE = '/scrm-platform/official/sop/massSendall/delete'
export const URL_OFFICIAL_SOP_MEDIA_UPLOADNEWS = '/scrm-platform/official/sop/media/uploadnews'
export const URL_OFFICIAL_SOP_MEDIA_UPLOAD_TEMP = '/scrm-platform/official/sop/media/upload/temp'
export const URL_OFFICIAL_SOP_COVERUSERNUMS = '/scrm-platform/official/sop/rules/coverUserNums'
export const URL_OFFICIAL_SOP_MASSSENDALL = '/scrm-platform/official/sop/massSendall'
export const URL_OFFICIAL_SOP_MASS_PREVIEW = '/scrm-platform/official/sop/mass/preview'
export const URL_OFFICIAL_SOP_CHANNEL_LIST = '/scrm-platform/official/sop/channel/list'
// 小程序
export const URL_MINI_PROGRAM_GETUSERPORTRAIT = '/scrm-platform/mini/program/getUserportrait'
export const URL_MINI_PROGRAM_GETVISITPAGE = '/scrm-platform/mini/program/getVisitPage'
export const URL_MINI_PROGRAM_GETVISITDISTRIBUTION = '/scrm-platform/mini/program/getVisitDistribution'
export const URL_MINI_PROGRAM_GETDAILYVISITTREND = '/scrm-platform/mini/program/getDailyVisitTrend'
export const URL_MINI_PROGRAM_GETDAILYRETAININFO = '/scrm-platform/mini/program/getDailyRetaininfo'
export const URL_MINI_PROGRAM_GET_CONFIG = '/scrm-platform/mini/program/get/miniprogram/config'
export const URL_MINI_PROGRAM_UPDATE_CONFIG = '/scrm-platform/mini/program/update/miniprogram/config'
export const URL_MINI_PROGRAM_USERINFO_LIST = '/scrm-platform/mini/program/get/userInfo/list'
export const URL_MINI_SUBSCRIBE_SEND_LIST = '/scrm-platform/mini/subscribe/subscribe/send/list'
export const URL_MINI_SUBSCRIBE_SEND_DELETE = '/scrm-platform/mini/subscribe/subscribe/send/delete'
export const URL_MINI_SUBSCRIBE_EVENT_LIST = '/scrm-platform/mini/subscribe/event/list'

// 自定义表单
export const URL_OFFICIAL_CUSTOMER_FORM = '/scrm-platform/customize/form/list'
export const URL_OFFICIAL_CUSTOMER_FORM_SYNC_TAG = 'scrm-platform/customize/form/sync/tag'
export const URL_OFFICIAL_CUSTOMER_FORM_DELETE = '/scrm-platform/customize/form/delete'
export const URL_OFFICIAL_CUSTOMER_FORM_CHANNEL = '/scrm-platform/customize/form/search/utm/channel'
export const URL_OFFICIAL_CUSTOMER_FORM_CREATE = '/scrm-platform/customize/form/create'
export const URL_OFFICIAL_CUSTOMER_FORM_SEARCH = '/scrm-platform/customize/form/search'
export const URL_OFFICIAL_CUSTOMER_FORM_UPDATE = '/scrm-platform/customize/form/update'
export const URL_OFFICIAL_CUSTOMER_FORM_SAVE = '/scrm-platform/customize/form/save'
export const URL_OFFICIAL_CUSTOMER_FORM_GET_OPENID = '/scrm-platform/customize/form/oauth2/getopenid'
export const URL_OFFICIAL_CUSTOMER_FORM_SAVE_DETAIL = '/scrm-platform/customize/form/create/utm/channel'
export const URL_OFFICIAL_CUSTOMER_FORM_DEL_DETAIL = '/scrm-platform/customize/form/delete/utm/channel'
export const URL_OFFICIAL_CUSTOMER_FORM_GET_DATA = '/scrm-platform/customize/form/saved/form/list'

// 抖音
export const URL_DOUYIN_BACKEND_GET_CONFIG = '/scrm-platform/douyin/backend/get/config'
export const URL_DOUYIN_BACKEND_UPDATE_CONFIG = '/scrm-platform/douyin/backend/update/config'
export const URL_DOUYIN_BACKEND_GET_FANS_LIST = '/scrm-platform/douyin/backend/get/fans/list'
export const URL_DOUYIN_BACKEND_GET_ANTHIRIZATION_OPENID = '/scrm-platform/douyin/backend/get/authorization/openid'
export const URL_DOUYIN_VERIFY_GET_CODE = '/scrm-platform/douyin/verify/get/code'
export const URL_DOUYIN_BACKEND_PROVINVE_CITY_LIST = '/scrm-platform/douyin/backend/province/city/list'
export const URL_DOUYIN_VERIFY_GET_FANS_LIST = '/scrm-platform/douyin/verify/get/fans/list'
export const URL_DOUYIN_VERIFY_GET_ACCESS_TOKEN = '/scrm-platform/douyin/verify/get/access_token'
export const URL_DOUYIN_CALLBACK_GET_ACCESS_TOKEN = 'scrm-platform/douyin/callback/get/access_token'
export const URL_DOUYIN_BACKEND_GET_VIDEO_LIST = '/scrm-platform/douyin/backend/get/video/list'
export const URL_DOUYIN_BACKEND_GET_COMMENT_LIST = '/scrm-platform/douyin/backend/get/comment/list'
export const URL_DOUYIN_VERIFY_GET_VIDEO_LIST = '/scrm-platform/douyin/verify/get/video/list'
export const URL_DOUYIN_VERIFY_GET_USERINFO = '/scrm-platform/douyin/verify/get/userinfo'
// 小鹅通
export const URL_XIAOETONG_BACKEND_CONFIG_INFO = '/scrm-platform/xiaoet/backend/get/config/info'
export const URL_XIAOETONG_BACKEND_EDIT_CONFIG_INFO = '/scrm-platform/xiaoet/backend/edit/config/info'
export const URL_XIAOETONG_BACKEND_GET_USER_LIST = '/scrm-platform/xiaoet/backend/get/user/list'
export const URL_XIAOETONG_BACKEND_SYNC_USER_LIST = '/scrm-platform/xiaoet/backend/sync/user/list'
export const URL_XIAOETONG_BACKEND_GET_ORDER_LIST = '/scrm-platform/xiaoet/backend/get/order/list'
// 营销自动化
export const URL_AUTOMATION_FOLDER_LIST = '/scrm-platform/automation/folder/list'
export const URL_AUTOMATION_LIST = '/scrm-platform/automation/list'
export const URL_AUTOMATION_CREATE = '/scrm-platform/automation/create'
export const URL_AUTOMATION_DELETE = '/scrm-platform/automation/delete'

// 裂变活动
export const URL_FISSION_ACTIVITY_LIST = '/scrm-platform/fission/activity/list'
export const URL_FISSION_ACTIVITY_CREATE = '/scrm-platform/fission/activity/create'
export const URL_FISSION_ACTIVITY_DELETE = '/scrm-platform/fission/activity/delete'
export const URL_FISSION_ACTIVITY_USER_CREATE_POSTER = '/scrm-platform/fission/activity/user/create/poster'
export const URL_FISSION_ACTIVITY_USER_SEARCH = '/scrm-platform/fission/activity/user/search'
