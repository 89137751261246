<template>
  <div class="mapChart">
    <div class="name">{{ source.title }}</div>
    <div class="flexbox">
      <div :id="source.id" class="myChart"></div>
      <div class="table">
        <el-table :data="source.data" style="width: 100%" size="mini">
          <el-table-column prop="name" :label="source.label"></el-table-column>
          <el-table-column prop="value" label="用户数"></el-table-column>
          <el-table-column label="占比">
            <template slot-scope="scope">
              <span v-if="total>0">{{(scope.row.value / total ).toFixed(4)*100 }} %</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import China from './china.json';
export default {
  props: ['source'],
  data() {
    return {
      option: {
        geo: {
          show: true,
          map: 'china',
          zoom: 1.2,
          aspectScale: 0.9,
          label: {
            show: false
          },
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: "#000",
              borderWidth: 4, // 设置外层边框
              borderColor: '#F2F3F2'
            },
            emphasis: {
              show: false
            }
          }
        },
        visualMap: {
          min: 0,
          max: 0,
          right: 0,
          realtime: false,
          calculable: true,
          label: 'right',
          itemWidth: 10,
          itemHeight: 50,
          inRange: {
            color: ['#D1EFD1', '#1AAD19']
          }
        },
        series: [
          {
            type: 'map',
            mapType: 'china', // 地图名称
            roam: false,
            zoom: 1.2,
            aspectScale: 0.9,
            itemStyle: {
              normal: {
                show: true,
                areaColor: '#D1EFD1',
                borderColor: '#fff'
              },
              emphasis: {
                show: false
              } // 鼠标事件区块样式
            },
            data: []
          }
        ]
      },
      total: 0
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.setEchart();
  },
  watch: {
    source() {
      this.setEchart();
    }
  },
  methods: {
    refreshMap() {
      this.total = this.source.data
        .map(row => row.value)
        .reduce((acc, cur) => cur + acc, 0);
      var myChart = echarts.init(document.getElementById(this.source.id)); // 拿到一个实例
      echarts.registerMap('china', China, {}); // 引入地图文件
      this.option.series[0].data = this.source.data;
      this.option.visualMap.max = this.total;
      myChart.setOption(this.option);
    },
    setEchart() {
      this.refreshMap();
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
