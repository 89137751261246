<template>
  <div class="code-file-main">
    <p class="title">上传文件</p>
    <div class="code-file">
      <div class="list-box">
        <div class="code-box">
          <el-upload class="upload-file" drag action="#" ref="upload" accept=".docx,.doc,.ppt,.pptx,.pdf,.xls,.xlsx" :before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed" :on-remove="handleRemove" :file-list="fileList" :auto-upload="false" :on-change="uploadChange">
            <i class="iconfont icon-wenjianshangchuan fz-36" />
            <div class="el-upload__text">
              将文件拖到此处,或<span>点击上传</span><br />
              格式支持:docx、doc、ppt、pptx、pdf、xls、xlsx.
            </div>
          </el-upload>
          <el-row>
            <el-button type="primary" @click="uploadFile()">生成二维码
            </el-button>
          </el-row>
        </div>
        <div class="code-list code-box">
          <el-table :data="dataDoc" style="width: 100%" @row-click="getQRCode" :header-cell-style="{ background: '#e9eef3', color: '#000' }">
            <el-table-column type="index" label="序号" width="60px">
            </el-table-column>
            <el-table-column prop="chName" label="名称" width="350px">
            </el-table-column>
            <el-table-column prop="createTime" label="时间"> </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.qrCode"><i class="el-icon-success" />上传完毕</span>
                <span v-else><i class="el-icon-error" />上传失败</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="medium" @click="delItem(scope.row.picCode)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-row class="pagin">
          <el-pagination background layout="prev, pager, next" small :total="total" :hide-on-single-page="true" @current-change="pageChange">
          </el-pagination>
        </el-row>
      </div>
      <div class="qrcode-box">
        <div>
          <el-image :src="imgUrl" @click="downloadQRcode()">
            <div slot="error" class="image-slot">
              <img src="@/assets/code.svg" alt="" />
            </div>
          </el-image>
          <div class="code-text" v-if="!imgUrl">未生成</div>
          <div class="code-text" v-if="imgUrl">{{ chName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { postUpload, getHistoricalDocuments, creatQRCode, getDelPic } from '@/service/code'
// import axios from 'axios'
import qs from 'qs'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data() {
    return {
      dataDoc: [],
      imgUrl: '',
      pageno: 1,
      pagesize: 10,
      total: 0,
      type: 'doc',
      chName: '',
      width: 350,
      height: 350,
      fileList: [],
      file: null,
      fileName: null,
      url: '',
      downLoadUrl: 'https://cdp.h5sites.com/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
    this.getDocumentsList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    // 获取列表
    getDocumentsList() {
      getHistoricalDocuments({
        pageno: this.pageno,
        pagesize: this.pagesize,
        type: this.type
      }).then(res => {
        this.dataDoc = res && res.list
        this.total = res.num
      })
    },
    // 分页
    pageChange(currentPage) {
      this.pageno = currentPage
      this.getDocumentsList()
    },
    // 下载二维码
    downloadQRcode() {
      if (this.imgUrl) {
        window.open(
          this.downLoadUrl + qs.stringify({ urlString: this.imgUrl }), '_self'
        )
      }
    },
    creatQRCode() {
      creatQRCode({
        width: this.width,
        height: this.height,
        text: this.url
      }).then(res => {
        this.imgUrl = res
        this.chName = this.fileName
        this.fileList = []
        Message.success('成功！点击二维码可下载图片')
      })
    },
    // 文件上传
    uploadChange(file, fileList) {
      this.file = file.raw
      this.fileName = file.name
    },
    uploadFile() {
      if (!this.file) {
        Message.info('请先选择文件')
        return
      }
      postUpload({ file: this.file }).then((res) => {
        this.pageno = 1
        this.getDocumentsList()
        this.url = res
        this.creatQRCode()
      })
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove() {
      this.file = null
      this.url = null
      this.imgUrl = null
      this.chName = null
      this.fileName = null
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 获取列表二维码
    getQRCode(row, event, column) {
      this.imgUrl = row.qrCode
      this.chName = row.chName
    },
    delItem(id) {
      var data = { piccode: id }
      getDelPic(data).then(res => {
        this.getDocumentsList()
        this.imgUrl = null
        this.chName = null
        Message.success('删除成功')
      })
    }
  },
  components: {
  }
}
</script>

<style  lang="less" >
  @import url("./index.less");
</style>
