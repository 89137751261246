<template>
  <div class="addSmsTemplate">
    <el-dialog title="创建群发任务" :visible.sync="dialogFormVisible" @close="closeDia()">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="任务名称" prop="missionName">
          <el-input v-model="form.missionName" style="margin-right: 20px"></el-input>
        </el-form-item>
        <el-form-item label="签名名称" prop="qm">
          <el-select v-model="form.qm" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板名称" prop="templateId">
          <el-select v-model="form.templateId" placeholder="请选择">
            <el-option v-for="item in smsTemplateList" :key="item.templateId" :label="item.templateName" :value="item.templateId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送时间" prop="sendType">
          <el-radio v-model="form.sendType" label="立即发送">立即发送</el-radio>
          <el-radio v-model="form.sendType" label="定时发送">定时发送</el-radio>
          <el-date-picker v-if="form.sendType === '定时发送'" v-model="form.sendTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="短信内容" class="addSmsTemplate__content">
          <div class="addSmsTemplate__templateContent">
            <div v-html="templateContentChange">{{ templateContent }}</div>
            <div class="addSmsTemplate__form">
              <div class="addSmsTemplate__content" v-for="(item, index) in 5" :key="index" v-show="showFormList(index)">
                <div style="color: #409eff">{ {{ index + 1 }} }</div>
                <div>=</div>
                <input v-model="fomrlist[index]" />
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="人群选择" prop="groupname">
          <el-radio-group v-model="form.upnumber" @change='radioChange'>
            <el-radio label="历史人群">历史人群</el-radio>
            <el-radio label="新建人群">新建人群</el-radio>
          </el-radio-group>
          <div v-if="form.upnumber === '新建人群'">
            <div style="display: flex; align-items: center; padding-bottom:15px;">
              <span style="width: 150px;">请输入人群名称</span>
              <el-input v-model="form.groupname"></el-input>
            </div>
            <div>
              <div style="line-height: 15px; margin-top: 0px">
                单次群发任务最多支持上传100万条号码，请上传csv、xlsx格式文件，大小30MB以内
              </div>
              <div style="line-height: 15px; margin-top: 10px; padding-bottom: 10px">
                请根据模板要求填写客户手机号码和变量内容<a href="https://cdp.h5sites.com/scrm-platform/shortmessage/file/download" download="filename">下载标准模板</a>
              </div>
              <el-upload class="upload-demo" drag :on-success="handleAvatarSuccess" :action="uploadUrl" :data="{ groupname: form.groupname }" :limit="1" :before-upload="beforeAvatarUpload" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </el-upload>
            </div>
          </div>
          <div v-if="form.upnumber === '历史人群'">
            人群名称
            <el-select v-model="form.groupname" placeholder="请选择">
              <el-option v-for="item in histroyList" :key="item.groupname" :label="item.groupname" :value="item.groupname">
                <span style="float: left">{{ item.groupname }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.num }}</span>
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDia()">取 消</el-button>
        <el-button type="primary" @click="addSmstemplate('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSmsTask, getSmsTemplateList, getshortMessageGroupInfo } from '@/service/sms'

export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    editList: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      fomrlist: ['', '', '', '', ''],
      smsTemplateList: [],
      options: [{
        value: '群优派',
        label: '群优派'
      }],
      value: '',
      value2: '',
      form: {
        smsType: '0',
        remark: '',
        groupname: '',
        missionName: '',
        sendType: '立即发送',
        templateId: '',
        sendTime: '',
        qm: '',
        upnumber: '历史人群'
      },
      histroyList: [],
      rules: {
        missionName: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ],
        sendType: [
          { required: true, message: '请选择发送类型', trigger: 'blur' }
        ],
        qm: [
          { required: true, message: '请输入签名内容', trigger: 'blur' }
        ],
        templateId: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        groupname: [
          { required: true, message: '请输入人群名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getTemplate()
    this.getshortMessageGroupInfo()
  },
  computed: {
    templateContentChange () {
      return this.templateContent.replace(/{/g, '<span style="color:#409EFF">{').replace(/}/g, '}</span>')
    },
    uploadUrl () {
      return 'https://cdp.h5sites.com/scrm-platform/shortmessage/file/upload'
    },
    currentChooseList () {
      return this.smsTemplateList.filter(e => { return e.templateId === this.form.templateId }) || []
    },
    templateContent () {
      return (this.currentChooseList && this.currentChooseList[0] && this.currentChooseList[0].templateContent) || ''
    },
    showFormList () {
      return index => {
        return this.templateContent && this.templateContent.includes(`{${index + 1}}`)
      }
    },
    templateParam () {
      return this.fomrlist.filter(e => {
        return e
      })
    }
  },
  methods: {
    handleAvatarSuccess (e) {
      if (e.code === 200) {
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传失败')
      }
    },
    beforeAvatarUpload () {
      if (!this.form.groupname) {
        this.$message({
          showClose: true,
          message: '请先输入人群名称',
          type: 'warning'
        })
        return false
      }
    },
    getTime () {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var second = date.getSeconds()
      return year + '-' + (month > 10 ? month : '0' + month) + '-' + (day > 10 ? day : '0' + day) + ' ' + (hour > 10 ? hour : '0' + hour) + ':' + (minute > 10 ? minute : '0' + minute) + ':' + (second > 10 ? second : '0' + second)
    },
    getTemplate () {
      getSmsTemplateList({ pageno: 1, pagesize: 100 }).then(e => {
        this.smsTemplateList = e.list
      })
    },
    downloadTemplate () {
      window.location = '/scrm-platform/shortmessage/file/download'
    },
    closeDia () {
      this.form = {
        smsType: '0',
        remark: '',
        groupname: '',
        missionName: '',
        sendType: '立即发送',
        templateId: '',
        sendTime: '',
        qm: '',
        upnumber: '历史人群'
      }
      this.$emit('closeSmsTemplate')
    },
    addSmstemplate (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {
            groupName: this.form.groupname,
            missionName: this.form.missionName,
            sendType: this.form.sendType,
            templateId: this.form.templateId,
            templateParam: this.templateParam,
            sendTime: this.form.sendType === '立即发送' ? this.getTime() : this.form.sendTime
          }
          addSmsTask(obj).then(e => {
            this.$message.success('新增成功')
            this.$emit('getTextPageList')
          })
          this.closeDia()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getshortMessageGroupInfo () {
      getshortMessageGroupInfo().then(res => {
        this.histroyList = res
      })
    },
    radioChange () {
      this.form.groupname = ''
    }
  }
}
</script>
<style scoped>
.el-form-item {
  display: flex;
}
/deep/ .el-form-item__content {
  flex: 1;
}
/deep/ .el-dialog {
  width: 600px;
}
/deep/ .el-select {
  width: 100%;
}
.addSmsTemplate__short-href {
  border: 1px solid #dcdfe6;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #409eff;
  margin-bottom: 10px;
}
/deep/ .addSmsTemplate__content .el-form-item__error {
  top: 38.5% !important;
}
.addSmsTemplate__templateContent {
  border: 1px solid #dcdfe6;
  margin-left: 10px;
  padding: 10px;
  border-radius: 4px;
}
.addSmsTemplate__form {
  margin-top: 50px;
}
.addSmsTemplate__content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 22px;
}
</style>
