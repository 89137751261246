<template>
  <div>
    <el-row :gutter="10" style="margin-top: 15px">
      <el-col :span="6">
        <el-card class="card-box-1">
          <p class="text-card">
            <b class="card-title">新增注册用户数</b>
            <font color="#fff" class="card-date">{{ selectedDate1 }}</font>
          </p>
          <div style="
              border-top: 1px dashed #cccccc;
              height: 1px;
              overflow: hidden;
              margin-top: 10px;
              margin-bottom: 10px;
            "></div>
          <p class="text-card">
            <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDate1" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions1" value-format="yyyy-MM-dd" @change="getNewRegistNum">
            </el-date-picker>
          </p>
          <el-row style="margin-bottom: 0px" type="flex" justify="space-between">
            <el-col :span="11" style="margin-top: 24px">
              <p class="text-card">
                <span class="big-num" v-text="newRegistInfo.todayNum"></span>
                <font color="white" size="2" class="card-people">人</font>
              </p>
            </el-col>
            <el-col :span="13" style="margin-top: 47px">
              <p class="text-card text-right">
                <font color="white" size="2">环比</font>
                <span style="color: red" v-if="newRegistInfo.ratio[0] === '-'"><i class="el-icon-caret-bottom ratio-icon"></i><span v-text="newRegistInfo.ratio">-1%</span></span>
                <span style="color: lawngreen" v-else><i class="el-icon-caret-top ratio-icon"></i><span v-text="newRegistInfo.ratio">-1%</span></span>
              </p>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-box-2">
          <p class="text-card">
            <b class="card-title">日活用户数</b>
            <font color="#fff" class="card-date">{{ selectedDate2 }}</font>
          </p>
          <div style="
              border-top: 1px dashed #cccccc;
              height: 1px;
              overflow: hidden;
              margin-top: 10px;
              margin-bottom: 10px;
            "></div>
          <p class="text-card">
            <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDate2" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions1" value-format="yyyy-MM-dd" @change="getLiveUsers">
            </el-date-picker>
          </p>
          <el-row style="margin-bottom: 0px" type="flex" justify="space-between">
            <el-col :span="11" style="margin-top: 24px">
              <p class="text-card">
                <span class="big-num" v-text="liveUsers.todayNum"></span>
                <font color="white" size="2">人</font>
              </p>
            </el-col>
            <el-col :span="13" style="margin-top: 47px">
              <p class="text-card text-right">
                <font color="white" size="2">环比</font>
                <span style="color: red" v-if="liveUsers.ratio[0] === '-'"><i class="el-icon-caret-bottom ratio-icon"></i><span v-text="liveUsers.ratio">-1%</span></span>
                <span style="color: lawngreen" v-else><i class="el-icon-caret-top ratio-icon"></i><span v-text="liveUsers.ratio">-1%</span></span>
              </p>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-box-3">
          <p class="text-card">
            <b class="card-title">付费用户数</b>
            <font color="#fff" class="card-date">{{ selectedDate3 }}</font>
          </p>
          <div style="
              border-top: 1px dashed #cccccc;
              height: 1px;
              overflow: hidden;
              margin-top: 10px;
              margin-bottom: 10px;
            "></div>
          <p class="text-card">
            <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDate3" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions1" value-format="yyyy-MM-dd" @change="getPaidUsers">
            </el-date-picker>
          </p>
          <el-row style="margin-bottom: 0px" type="flex" justify="space-between">
            <el-col :span="11" style="margin-top: 24px">
              <p class="text-card">
                <span class="big-num" v-text="paidUsers.todayNum"></span>
                <font color="#fff" size="2">人</font>
              </p>
            </el-col>
            <el-col :span="13" style="margin-top: 47px">
              <p class="text-card text-right">
                <font color="#fff" size="2">环比</font>
                <span style="color: red" v-if="paidUsers.ratio[0] === '-'"><i class="el-icon-caret-bottom ratio-icon"></i><span v-text="paidUsers.ratio">-1%</span></span>
                <span style="color: lawngreen" v-else><i class="el-icon-caret-top ratio-icon"></i><span v-text="paidUsers.ratio">-1%</span></span>
              </p>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="card-box-4">
          <p class="text-card">
            <b class="card-title">付费订单量</b>
            <font color="#fff" class="card-date">{{ selectedDate4 }}</font>
          </p>
          <div style="
              border-top: 1px dashed #cccccc;
              height: 1px;
              overflow: hidden;
              margin-top: 10px;
              margin-bottom: 10px;
            "></div>
          <p class="text-card">
            <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDate4" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions1" value-format="yyyy-MM-dd" @change="getPaidOrders">
            </el-date-picker>
          </p>
          <el-row style="margin-bottom: 0px" type="flex" justify="space-between">
            <el-col :span="11" style="margin-top: 24px">
              <p class="text-card">
                <span class="big-num" v-text="paidOrders.todayNum"></span>
                <font color="#fff" size="2">人</font>
              </p>
            </el-col>
            <el-col :span="13" style="margin-top: 47px">
              <p class="text-card text-right">
                <font color="#fff" size="2">环比</font>
                <span style="color: red" v-if="paidOrders.ratio[0] === '-'"><i class="el-icon-caret-bottom ratio-icon"></i><span v-text="paidOrders.ratio">-1%</span></span>
                <span style="color: lawngreen" v-else><i class="el-icon-caret-top ratio-icon"></i><span v-text="paidOrders.ratio">-1%</span></span>
              </p>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-row>
        <el-col class="col-chart" :span="12">
          <el-card>
            <h1 class="title-chart">GMV</h1>
            <p class="text-chart" style="font-size: 12px; color: gray">
              <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartGMV">
              </el-date-picker>
            </p>
            <div id="mainGmv" style="width: 100%; height: 300px"></div>
          </el-card>
        </el-col>
        <el-col class="col-chart" :span="12">
          <el-card>
            <h1 class="title-chart">累计用户数</h1>
            <p class="text-chart" style="font-size: 12px; color: gray">
              <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartAllUser">
              </el-date-picker>
            </p>
            <div id="mainAllUser" style="width: 100%; height: 300px"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="col-chart" :span="12">
          <el-card>
            <h1 class="title-chart">日活用户渠道分布</h1>
            <p class="text-chart" style="font-size: 12px; color: gray">
              <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange3" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartChannelDis">
              </el-date-picker>
            </p>
            <div id="mainChannelDis" style="width: 100%; height: 300px"></div>
          </el-card>
        </el-col>
        <el-col class="col-chart" :span="12">
          <el-card>
            <h1 class="title-chart">日活用户城市分布</h1>
            <p class="text-chart" style="font-size: 12px; color: gray">
              <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange4" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartCityDis">
              </el-date-picker>
            </p>
            <div id="mainCityDis" style="width: 100%; height: 300px"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="col-chart" :span="12">
          <el-card>
            <h1 class="title-chart">日活用户平台分布</h1>
            <p class="text-chart" style="font-size: 12px; color: gray">
              <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange5" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartPlatformDis">
              </el-date-picker>
            </p>
            <div id="mainPlatformDis" style="width: 100%; height: 300px"></div>
          </el-card>
        </el-col>
        <el-col class="col-chart" :span="12">
          <el-card>
            <h1 class="title-chart">日活用户新老客分布</h1>
            <p class="text-chart" style="font-size: 12px; color: gray">
              <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange6" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartNewAndOldDis">
              </el-date-picker>
            </p>
            <div id="mainNewAndOldDis" style="width: 100%; height: 300px"></div>
          </el-card>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        onPick: (dateRange) => {
          this.queryData.startDate = dateRange.minDate
          this.queryData.endDate = dateRange.maxDate
        }
      },
      pickerOptions1: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      queryData: {
        startDate: '',
        endDate: '',
        dataDate: ''
      },
      selectedDate1: '',
      selectedDate2: '',
      selectedDate3: '',
      selectedDate4: '',
      selectedDateRange1: [],
      selectedDateRange2: [],
      selectedDateRange3: [],
      selectedDateRange4: [],
      selectedDateRange5: [],
      selectedDateRange6: [],
      newRegistInfo: {
        dataDate: '',
        todayNum: 0,
        ratio: '0'
      },
      liveUsers: {
        dataDate: '',
        todayNum: 0,
        ratio: '0'
      },
      paidUsers: {
        dataDate: '',
        todayNum: 0,
        ratio: '0'
      },
      paidOrders: {
        dataDate: '',
        todayNum: 0,
        ratio: '0'
      },
      chartGMVObj: null,
      optionGMV: {
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: '直接访问',
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            emphasis: {
              barBorderRadius: 15
            },
            normal: {
              barBorderRadius: 15,
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                  offset: 0,
                  color: '#91B6FD'
                },
                {
                  offset: 0.5,
                  color: '#719FFD'
                },
                {
                  offset: 1,
                  color: '#4A86FF'
                }
                ]
              )
            }
          },
          data: []
        }]
      },
      channelColor: [''],
      chartAllUserObj: null,
      optionAllUser: {
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: '直接访问',
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            emphasis: {
              barBorderRadius: 15
            },
            normal: {
              barBorderRadius: 15,
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                  offset: 0,
                  color: '#91B6FD'
                },
                {
                  offset: 0.5,
                  color: '#719FFD'
                },
                {
                  offset: 1,
                  color: '#4A86FF'
                }
                ]
              )
            }
          },
          data: []
        }]
      },
      chartChannelDisObj: null,
      optionChannelDis: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value'
        }],
        series: []
      },
      chartCityDis: null,
      optionCityDis: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: { // 保存为图片
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      },
      chartPlatformDis: null,
      optionPlatform: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: []
        }],
        yAxis: [{
          type: 'value'
        }],
        series: []
      },
      chartNewAndOldDis: null,
      optionNewAndOld: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: []
        }],
        yAxis: [{
          type: 'value'
        }],
        series: []
      }
    }
  },
  created () { },
  mounted () {
    this.getQueryData()
    this.chartGMVObj = this.$echarts.init(document.getElementById('mainGmv'))
    this.chartAllUserObj = this.$echarts.init(document.getElementById('mainAllUser'))
    this.chartChannelDisObj = this.$echarts.init(document.getElementById('mainChannelDis'))
    this.chartCityDisObj = this.$echarts.init(document.getElementById('mainCityDis'))
    this.chartPlatformDis = this.$echarts.init(document.getElementById('mainPlatformDis'))
    this.chartNewAndOldDis = this.$echarts.init(document.getElementById('mainNewAndOldDis'))
    this.setChartGMV()
    this.setChartAllUser()
    this.setChartChannelDis()
    this.setChartCityDis()
    this.setChartPlatformDis()
    this.setChartNewAndOldDis()
    this.getNewRegistNum()
    this.getLiveUsers()
    this.getPaidUsers()
    this.getPaidOrders()
  },
  computed: {},
  methods: {
    getQueryData () {
      const date1 = new Date()
      const year = date1.getFullYear()
      let month = date1.getMonth() + 1
      month = month >= 10 ? month : '0' + month
      let day = date1.getDate()
      day = day >= 10 ? day : '0' + day
      const end = year + '-' + month + '-' + day
      const date2 = new Date(date1)
      date2.setDate(date1.getDate() - 7)
      let month2 = date2.getMonth() + 1
      month2 = month2 >= 10 ? month2 : '0' + month2
      let day2 = date2.getDate()
      day2 = day2 >= 10 ? day2 : '0' + day2
      // num是正数表示之后的时间，num负数表示之前的时间，0表示今天
      var start = date2.getFullYear() + '-' + month2 + '-' + day2
      this.selectedDate1 = end
      this.selectedDate2 = end
      this.selectedDate3 = end
      this.selectedDate4 = end
      this.selectedDateRange1 = [start, end]
      this.selectedDateRange2 = [start, end]
      this.selectedDateRange3 = [start, end]
      this.selectedDateRange4 = [start, end]
      this.selectedDateRange5 = [start, end]
      this.selectedDateRange6 = [start, end]
      this.queryData = {
        startDate: start,
        endDate: end,
        dataDate: end
      }
    },
    resetQuery () {
      this.queryData = {
        startDate: '',
        endDate: '',
        dataDate: ''
      }
    },
    async getNewRegistNum () {
      this.queryData.dataDate = this.selectedDate1
      const queryDate = this.queryData
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/newRegistNum', {}, {
        params: queryDate
      })
      this.resetQuery()
      if (res.data) this.newRegistInfo = res.data
    },
    async getLiveUsers () {
      this.queryData.dataDate = this.selectedDate2
      const queryDate = this.queryData
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/liveUsers', {}, {
        params: queryDate
      })
      this.resetQuery()
      if (res.data) this.liveUsers = res.data
    },
    async getPaidUsers () {
      this.queryData.dataDate = this.selectedDate3
      const queryDate = this.queryData
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/paidUsers', {}, {
        params: queryDate
      })
      this.resetQuery()
      if (res.data) this.paidUsers = res.data
    },
    async getPaidOrders () {
      this.queryData.dataDate = this.selectedDate4
      const queryDate = this.queryData
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/paidOrders', {}, {
        params: queryDate
      })
      this.resetQuery()
      if (res.data) this.paidOrders = res.data
    },
    async setChartGMV (value) {
      const xdata = []
      const ydata = []
      let queryData = {}
      if (value) {
        queryData = {
          startDate: value[0],
          endDate: value[1]
        }
      } else {
        queryData = this.queryData
      }
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/orderAmount', {}, {
        params: queryData
      })
      this.resetQuery()
      res.data.info.forEach((item) => {
        xdata.push(item.datadate)
        ydata.push(item.num)
      })
      this.optionGMV.xAxis[0].data = xdata
      this.optionGMV.series[0].data = ydata
      this.chartGMVObj.setOption(this.optionGMV)
    },
    async setChartAllUser (value) {
      const xdata = []
      const ydata = []
      let queryData = {}
      if (value) {
        queryData = {
          startDate: value[0],
          endDate: value[1]
        }
      } else {
        queryData = this.queryData
      }
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/cumulativeUsers', {}, {
        params: queryData
      })
      this.resetQuery()
      res.data.info.forEach((item) => {
        xdata.push(item.datadate)
        ydata.push(item.num)
      })
      this.optionAllUser.xAxis[0].data = xdata
      this.optionAllUser.series[0].data = ydata
      this.chartAllUserObj.setOption(this.optionAllUser)
    },
    async setChartChannelDis (value) {
      let queryData = {}
      if (value) {
        queryData = {
          startDate: value[0],
          endDate: value[1]
        }
      } else {
        queryData = this.queryData
      }
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/userChannelDistribute', {}, {
        params: queryData
      })
      this.resetQuery()
      var xAxis = []
      var legend = []
      var series = []
      for (let i = 0; i < 7; i++) {
        let seriesData = []
        let mem = 0
        let color = ''
        for (let j = i; j < res.data.info.length; j += 7) {
          seriesData.push(
            parseInt(res.data.info[j].num)
          )
          xAxis.push(res.data.info[j].datadate)
          legend.push(res.data.info[j].channel)
          mem = j
        }
        switch (res.data.info[mem].channel) {
          case '搜狗':
            color = '#16D2AF'
            break
          case '公众号':
            color = '#FE5377'
            break
          case '支付宝':
            color = '#6E9CFA'
            break
          case '微博':
            color = '#F9B64D'
            break
          case 'H5':
            color = '#68D7F2'
            break
          case 'web':
            color = '#8B6AF9'
            break
          case '其他':
            color = '#86A5FF'
            break
        }
        series.push({
          name: res.data.info[mem].channel,
          type: 'bar',
          stack: 'channel',
          barWidth: '25%',
          color: color,
          data: seriesData
        })
        seriesData = []
      }
      this.optionChannelDis.legend.data = Array.from(new Set(legend))
      this.optionChannelDis.xAxis[0].data = Array.from(new Set(xAxis))
      this.optionChannelDis.series = series
      this.chartChannelDisObj.setOption(this.optionChannelDis)
    },
    async setChartCityDis (value) {
      let queryData = {}
      if (value) {
        queryData = {
          startDate: value[0],
          endDate: value[1]
        }
      } else {
        queryData = this.queryData
      }
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/cityDistribute', {}, {
        params: queryData
      })
      this.resetQuery()
      var xAxis = []
      var legend = []
      var series = []
      for (let i = 0; i < 11; i++) {
        let seriesData = []
        let mem = 0
        let color = ''
        for (let j = i; j < res.data.info.length; j += 11) {
          seriesData.push(
            parseInt(res.data.info[j].num)
          )
          xAxis.push(res.data.info[j].datadate)
          legend.push(res.data.info[j].city)
          mem = j
        }
        switch (res.data.info[mem].city) {
          case '杭州':
            color = '#80E8D6'
            break
          case '北京':
            color = '#F86187'
            break
          case '上海':
            color = '#4B80FF'
            break
          case '苏州':
            color = '#FCB957'
            break
          case '西安':
            color = '#80DDF0'
            break
          case '成都':
            color = '#9174FB'
            break
          case '重庆':
            color = '#FD684C'
            break
          case '武汉':
            color = '#37AF54'
            break
          case '石家庄':
            color = '#F37FBD'
            break
          case '广州':
            color = '#A463FF'
            break
          case '深圳':
            color = '#F1DB31'
            break
        }
        series.push({
          name: res.data.info[mem].city,
          type: 'line',
          stack: '总量',
          symbolSize: 8,
          data: seriesData,
          itemStyle: {
            normal: {
              color: color,
              lineStyle: {
                color: color
              }
            }
          },
          smooth: true
        })
        seriesData = []
      }
      this.optionCityDis.legend.data = Array.from(new Set(legend))
      this.optionCityDis.xAxis.data = Array.from(new Set(xAxis))
      this.optionCityDis.series = series
      this.chartCityDisObj.setOption(this.optionCityDis)
    },
    async setChartPlatformDis (value) {
      let queryData = {}
      if (value) {
        queryData = {
          startDate: value[0],
          endDate: value[1]
        }
      } else {
        queryData = this.queryData
      }
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/platformDistribute', {}, {
        params: queryData
      })
      this.resetQuery()
      var xAxis = []
      var legend = []
      var series = []
      for (let i = 0; i < 4; i++) {
        let seriesData = []
        let mem = 0
        let thisColor = ''
        let shadowColor0 = ''
        let shadowColor1 = ''
        for (let j = i; j < res.data.info.length; j += 4) {
          seriesData.push(
            parseInt(res.data.info[j].num)
          )
          xAxis.push(res.data.info[j].datadate)
          legend.push(res.data.info[j].platform)
          mem = j
        }
        console.log(seriesData, 1)
        console.log(xAxis, 2)
        console.log(legend, 3)
        switch (res.data.info[mem].platform) {
          case 'Android':
            thisColor = '#F98CA5'
            shadowColor0 = '#FAD9E4'
            shadowColor1 = '#FEF8FA'
            break
          case 'ios':
            thisColor = '#75A0FC'
            shadowColor0 = '#ECF0FA'
            shadowColor1 = '#F5FDFF'
            break
          case 'Web':
            thisColor = '#F8C972'
            shadowColor0 = '#FBF7EB'
            shadowColor1 = '#FEFDF9'
            break
          case 'H5':
            thisColor = '#57B76F'
            shadowColor0 = '#EEF8F0'
            shadowColor1 = '#F6FBF7'
            break
        }
        series.push({
          name: res.data.info[mem].platform,
          type: 'line',
          stack: '总量',
          symbolSize: 8,
          areaStyle: {
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: shadowColor0
                }, {
                  offset: 1,
                  color: shadowColor1
                }],
                globalCoord: false
              }
            }
          },
          itemStyle: {
            normal: {
              color: thisColor,
              lineStyle: {
                color: thisColor
              }
            }
          },
          data: seriesData,
          smooth: true
        })
        seriesData = []
      }
      this.optionPlatform.legend.data = Array.from(new Set(legend))
      this.optionPlatform.xAxis[0].data = Array.from(new Set(xAxis))
      this.optionPlatform.series = series
      this.chartPlatformDis.setOption(this.optionPlatform)
      console.log(this.optionPlatform.series, 4)
    },
    async setChartNewAndOldDis (value) {
      let queryData = {}
      if (value) {
        queryData = {
          startDate: value[0],
          endDate: value[1]
        }
      } else {
        queryData = this.queryData
      }
      const {
        data: res
      } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/Report/kpi/newoldlDistribute', {}, {
        params: queryData
      })
      this.resetQuery()
      var xAxis = []
      var legend = []
      var series = []
      for (let i = 0; i < 2; i++) {
        let seriesData = []
        let mem = 0
        let thisColor = ''
        let shadowColor0 = ''
        let shadowColor1 = ''
        for (let j = i; j < res.data.info.length; j += 2) {
          seriesData.push(
            parseInt(res.data.info[j].num)
          )
          xAxis.push(res.data.info[j].datadate)
          legend.push(res.data.info[j].type)
          mem = j
        }
        switch (res.data.info[mem].type) {
          case '新客':
            thisColor = '#F6C371'
            shadowColor0 = '#F0E3C0'
            shadowColor1 = '#E3F3E6'
            break
          case '老客':
            thisColor = '#34A64C'
            shadowColor0 = '#D7EFDE'
            shadowColor1 = '#F5FDFF'
            break
        }
        series.push({
          name: res.data.info[mem].type,
          type: 'line',
          stack: '总量',
          symbolSize: 8,
          areaStyle: {
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: shadowColor0
                }, {
                  offset: 1,
                  color: shadowColor1
                }],
                globalCoord: false
              }
            }
          },
          itemStyle: {
            normal: {
              color: thisColor,
              lineStyle: {
                color: thisColor
              }
            }
          },
          data: seriesData,
          smooth: true
        })
        seriesData = []
      }
      this.optionNewAndOld.legend.data = Array.from(new Set(legend))
      this.optionNewAndOld.xAxis[0].data = Array.from(new Set(xAxis))
      this.optionNewAndOld.series = series
      this.chartNewAndOldDis.setOption(this.optionNewAndOld)
    }
  }
}

</script>

<style scoped>
.el-row {
  padding: 0 10px;
  margin-bottom: 20px;
}

.text-card {
  margin: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
}

.big-num {
  font-size: 42px;
  color: white;
  font-weight: normal;
}

.ratio-icon {
  font-size: 12px;
}

.text-chart {
  margin: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
}

.col-chart {
  padding: 0 10px;
}

.card-box-1 {
  background: linear-gradient(to right, #4181ff, #6c9dfe, #9cbbfc);
}

.card-box-2 {
  background: linear-gradient(to right, #fe7c64, #fe8b76, #ff9e8c);
}

.card-box-3 {
  background: linear-gradient(to right, #8e6dfa, #a290fc, #a290fc);
}

.card-box-4 {
  background: linear-gradient(to right, #fdb348, #fcbd5e, #f8cf80);
}

.text-card /deep/ .el-input__inner {
  height: 28px;
  line-height: 28px;
  /* background: #9CBBFC; */
  background: rgba(0, 0, 0, 0);
  color: white;
}

.card-title {
  color: white;
}

.card-date {
  font-size: 13px;
  float: right;
}

.title-chart {
  text-align: left;
  line-height: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1em 0 !important;
}

@media screen and (max-width: 1500px) {
  .big-num {
    font-size: 35px;
    color: white;
    font-weight: normal;
  }

  .text-right {
    text-align: right;
  }
}

@media screen and (max-width: 1350px) {
  .big-num {
    font-size: 25px;
    color: white;
    font-weight: normal;
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
}
</style>
