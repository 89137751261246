<!-- 线性图 -->
<template>
  <div>
    <p>累计触发人次： {{sendNum}}</p>
    <div id="map">暂无数据...</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  /* eslint-disable */
  props: ["mapData"],
  data () {
    return {
      option: {
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          bottom: 80
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              normal: {
                color: '#3A4267',
                lineStyle: {
                  color: '#3A4267'
                }
              }
            }
          },
        ]
      },
      sendNum: 0,
      clickNum: 0
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    // console.log(this.LineMap);
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.flush();
  },
  methods: {
    refreshMap () {
      const myChart = echarts.init(document.getElementById("map"));
      myChart.setOption(this.option);
    },
    flush () {
      if (this.mapData) {
        let sendnum = [];
        let datadate = [];
        this.$http
          .get(
            `https://cdp.h5sites.com/scrm-platform/v1/Market/act/mission/analysis?missionid=${this.mapData}`
          )
          .then(res => {
            if (res.data.flag) {
              this.sendNum = res.data.data.sendNum;
              res.data.data &&
                res.data.data.list.map(item => {
                  datadate.push(item.datadate);
                  sendnum.push(item.sendnum);
                });
              this.option.xAxis.data = datadate;
              this.option.series[0].data = sendnum;
              this.refreshMap();
            }
          });
      }
    }
  },
  watch: {
    mapData () {
      this.flush();
    }
  }
};
</script>
<style  scoped lang='less'>
/* @import url(); 引入css类 */
#map {
  width: 100%;
  min-height: 430px;
}
p {
  text-align: left;
  font-weight: bold;
}
</style>
