<template >
  <div class="keyword-reply">
    <el-container>
      <el-header class="groupheader">关键词回复</el-header>
      <div class="replytop">
        <el-input
          placeholder="搜索关键词/规则名称"
          prefix-icon="el-icon-search"
          v-model="kewords"
          @focus="getPageList"
          :clearable="true"
          @clear="getPageList"
          @change="getPageList"
        >
        </el-input>
        <el-button class="topBtn" type="primary" @click="addGroupWel(null)"
          ><i class="el-icon-plus"></i>回复规则</el-button
        >
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#F9F9F9' }"
      >
        <el-table-column prop="keywords" label="关键词" width="200">
        </el-table-column>
        <el-table-column prop="type" label="回复类型" width="100">
          <template slot-scope="scope">
            <div>
              <span v-show="scope.row.type === 'text'">文字/超链接</span>
              <span v-show="scope.row.type === 'image'">图片</span>
              <span v-show="scope.row.type === 'news'">图文消息</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="reply_info" label="回复内容"> </el-table-column>
        <el-table-column prop="num" label="请求人次" width="110">
        </el-table-column>
        <el-table-column label="详情" width="130">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="showLine(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="addGroupWel(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="medium"
              @click="deleteItem(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-size="10"
        layout="prev, pager, next"
        background
        :total="total"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </el-container>
    <el-dialog title :visible.sync="showLineChart">
      <LineChart :mapData="mapData"></LineChart>
    </el-dialog>
  </div>
</template>

<script>
import { getReplyList, delReply } from '@/service/reply'
import {
  Message
} from 'element-ui'
import LineChart from './LineChart'
export default {
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      kewords: '',
      typeInfo: 'keywords',
      showLineChart: false,
      mapData: null
    }
  },
  computed: {

  },
  created() {
    this.getPageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getPageList() {
      getReplyList({ pageNo: this.pageNo, pageSize: this.pageSize, typeInfo: this.typeInfo, search: this.kewords }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    addGroupWel(row) {
      console.log('row', row)
      if (row) {
        this.$router.push({
          path: '/Wechat/AutomaticReply/addkeyword',
          query: {
            row: row
          }
        })
      } else {
        this.$router.push('/Wechat/AutomaticReply/addkeyword')
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem(id) {
      delReply({ id: id }).then(res => {
        Message.success('删除成功')
        this.getPageList()
      })
    },
    showLine(scope) {
      this.mapData = scope.keywords;
      this.showLineChart = true;
    }
  },
  components: {
    LineChart
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
