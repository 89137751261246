<!-- 定义留存 -->
<template>
  <div class="event-condition">
    <div class="condition-title">
      <span>定义留存</span>
    </div>
    <div class="indicate">
      <div class="indicate-valule-list">
        <div class="values-item">
          <h5>初始化行为</h5>
          <div class="values-selects">
            <el-dropdown size="mini" trigger="click" @command="changeIndicate1">
              <el-tag size="small">{{
                value1.event_name ? value1.event_name : "请选择"
              }}</el-tag>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand(v.event_code, v.event_name)" v-for="v in indicateData" :key="v.event_code">{{ v.event_name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="values-info">
            <p>行为重命名：{{ value1.event_name || "" }}</p>
          </div>
        </div>
        <div class="values-item">
          <h5>后续行为</h5>
          <div class="values-selects">
            <el-dropdown size="mini" trigger="click" @command="changeIndicate2">
              <el-tag size="small">{{
                value2.event_name ? value2.event_name : "请选择"
              }}</el-tag>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand(v.event_code, v.event_name)" v-for="v in indicateData" :key="v.event_code">{{ v.event_name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="values-info">
            <p>行为重命名：{{ value2.event_name || "" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['eventData'],
  data () {
    /* eslint-disable */
    return {
      indicateData: [],
      indicateTotal: [],
      defaultCode: 1,
      eventCodes: {},
      value1: {},
      value2: {},
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this._initIndicateData()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    _initIndicateData () {
      this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/DataAnalysis/eventList').then(data => {
        this.indicateData = data.data.data
      })
    },
    beforeHandleCommand (eventCode, event_name) {
      return {
        'eventCode': eventCode,
        'event_name': event_name
      }
    },
    changeIndicate1 (command) {
      const { code, eventCode, event_name, index } = command
      this.value1 = {
        eventCode, event_name
      }
      this.eventCodes = {
        value1: this.value1
      }
    },
    changeIndicate2 (command) {
      const { code, eventCode, event_name, index } = command
      this.value2 = {
        eventCode, event_name
      }
      this.eventCodes = {
        value2: this.value2
      }
    }
  },
  watch: {
    eventCodes (newValue) {
      this.$emit('getEventCodesValue', this.eventCodes)
    },
    eventData: {
      immediate: true,
      deep: true,
      handler (val) {
        setTimeout(() => {
          var t = this.indicateData.filter(value => value.event_code === val.value1.eventCode)
          this.value1 = {
            event_name: t[0].event_name,
            eventCode: val.value1.eventCode,
          }
          var t1 = this.indicateData.filter(value => value.event_code === val.value2.eventCode)
          this.value2 = {
            event_name: t1[0].event_name,
            eventCode: val.value2.eventCode,
          }
        }, 500);
      }
    }

  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
@import url("../../index.css");

.values-item > h5 {
  text-align: left;
  font-size: 12px;
  margin: 0px;
  position: relative;
  margin-left: 19px;
  margin-bottom: 8px;
}
.values-item > h5::before {
  content: "\5355";
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #2882ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: -22px;
  top: -2px;
  z-index: 999;
  color: #fff;
}
.values-info p {
  padding-left: 0px !important;
}
</style>
