<script src="../../config/index.js"></script>
<template>
  <div class="userInfo">
    <div class="search_box">
      <el-input v-model="searchInp" placeholder="请输入用户id"></el-input>
      <el-button type="primary" round @click="search">搜索</el-button>
    </div>
    <el-row :gutter="5">
      <el-col :span="19">
        <el-card>
          <h3>用户属性</h3>
          <el-row :gutter="20">
            <el-col v-for="item in userAttributes" :span="8" :key="item.tagId"><span style="color: #bbbbbb" v-if="!item.tagWeight">{{
                item.tagLevel
              }}</span><span style="color: #bbbbbb" v-else>{{ item.tagId }}</span><span style="color: #bbbbbb">：</span><span v-if="!item.tagWeight">{{ item.tagId }}</span><span v-else>{{ item.tagWeight }}</span></el-col>
          </el-row>
          <h3>用户行为</h3>
          <el-row :gutter="20">
            <el-col v-for="item in userBehavior" :span="8" :key="item.tagId"><span style="color: #bbbbbb" v-if="!item.tagWeight">{{
                item.tagLevel
              }}</span><span style="color: #bbbbbb" v-else>{{ item.tagId }}</span><span style="color: #bbbbbb">：</span><span v-if="!item.tagWeight">{{ item.tagId }}</span><span v-else>{{ item.tagWeight }}</span></el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card>
          <el-timeline style="padding: 0 !important">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" :type="activity.type" color="#0bbd87" :size="activity.size" :timestamp="activity.eventTime">
              {{ activity.eventName }}
            </el-timeline-item>
          </el-timeline>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchInp: '33590753989737265',
      userAttributes: [],
      userBehavior: [],
      timeLine: [],
      activities: []
    }
  },
  created () {
    this.search()
  },
  methods: {
    async search () {
      this.userAttributes = []
      this.userBehavior = []
      this.activities = []
      var that = this
      const { data: res1 } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/UserProfile/userTagSearch', {
        cookieid: this.searchInp
      });
      const { data: res2 } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/UserProfile/userActTimelineSearch', {
        cookieid: this.searchInp
      });
      if (res1.code === 1 && res2.code === 1) {
        res1.data.allTags.forEach((item) => {
          if (item.tagType === '用户属性') that.userAttributes.push(item);
          else that.userBehavior.push(item)
        });
        that.activities = res2.data.allTags
      } else {
        alert('数据错误！')
      }
    }
  }
}
</script>

<style scoped>
.userInfo {
  padding: 20px;
}

.search_box {
  overflow: hidden;
  margin-bottom: 20px;
  padding-left: 10px;
}

.el-input {
  float: left;
  margin-left: 20px;
  width: 200px;
}

.el-button {
  float: left;
  margin-left: 20px;
}

.el-card {
  overflow-y: auto;
  height: 500px;
}

h3 {
  float: left;
  padding: 0 0 10px 10px;
  width: 100%;
  border-bottom: 1px solid #8c939d;
  text-align: left;
}

.el-col {
  padding-left: 30px !important;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}

.el-col span {
  color: #000000;
}
</style>
