<template>
  <div class="drainage-page-list-wrapper">
    <el-form ref="form" :model="form" size="medium" label-width="80px">
      <el-row>
        <el-col :span="7">
          <el-form-item label="使用员工">
            <el-select v-model="form.stuff" style="width: 250px" placeholder="请选择" clearable>
              <el-option v-for="item in useUserList" :key="item.userId" :label="item.userName" :value="item.userId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="标签">
            <el-select v-model="form.labelCode" clearable placeholder="请选择">
              <el-option-group v-for="group in labelList" :key="group.groupId" :label="group.groupName">
                <el-option v-for="item in group.tagList" :key="item.tagId" :label="item.tagName" :value="item.tagName">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="活动场景">
            <el-input v-model="form.state" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="添加类型">
            <el-select v-model="form.addType" style="width: 250px" placeholder="请选择" clearable>
              <el-option v-for="item in addTypeList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建日期">
            <el-date-picker v-model="form.createTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7" class="btn">
          <el-button size="medium" type="success" @click="handleQuery">查询</el-button>
          <el-button size="medium" @click="handleReset">重置</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-button size="medium" type="primary" @click="addNewCode">新建员工活码</el-button>
      </el-row>
    </el-form>
    <template>
      <div class="user-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="date" label="样式" width="150">
            <template slot-scope="scope">
              <div class="uesr-infos">
                <el-image :src="scope.row.qrCode" alt=""></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="stuff" label="使用员工" width="180">
          </el-table-column>
          <el-table-column prop="labelCode" label="标签">
            <template slot-scope="scope" v-if="scope.row.labelCode">
              <el-tag v-for="v in scope.row.labelCode.split(',')" :key="v" size="mini">{{ v }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="活动场景"> </el-table-column>
          <el-table-column prop="addType" label="添加类型"> </el-table-column>
          <el-table-column prop="num" label="扫码人数"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="medium" @click="downLoadQrcode(scope.row.qrCode)">下载</el-button>
              <el-button type="text" size="medium" @click="goDetails(scope.row)">查看详情</el-button>
              <el-button type="text" size="medium" @click="postDelContactWay(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {
  getAdduserList,
  getUseUserList,
  getLabelList,
  postDelContactWay
} from '@/service/drainage'
import qs from 'qs'
import Details from '@/views/Drainage/details.js'
import {
  Message
} from 'element-ui'
export default {
  props: {

  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {
        stuff: '',
        labelCode: '',
        state: '',
        addType: ''
      },
      useUserList: [],
      tableData: [],
      labelList: [],
      addTypeList: ['单人', '多人'],
      dialogVisible: false,
      defaultChecked: [],
      currentRow: {},
      updateCursor: '',
      downLoadUrl: 'https://cdp.h5sites.com/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
    this.runList()
    this.getUseUserList()
    this.getLabelList()
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    runList(params) {
      getAdduserList({
        pageNo: this.pageNo, pageSize: this.pageSize, ...params
      }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    getUseUserList() {
      getUseUserList().then(res => {
        this.useUserList = res
      })
    },
    getLabelList() {
      getLabelList().then(res => {
        this.labelList = res
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.runList()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.runList()
    },
    handleQuery() {
      this.runList({
        ...this.form,
        createTimeBegin: this.form.createTime && this.form.createTime[0] ? this.$moment(this.form.createTime[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        createTimeEnd: this.form.createTime && this.form.createTime[0] ? this.$moment(this.form.createTime[1]).format('YYYY-MM-DD HH:mm:ss') : ''
      })
    },
    handleReset() {
      this.runList()
      this.form = {}
    },
    // 下载
    downLoadQrcode(url) {
      console.log('url,', url)
      if (url) {
        window.open(
          this.downLoadUrl + qs.stringify({ urlString: url }), '_self'
        )
      } else {
        Message.error('下载失败')
      }
    },
    addNewCode() {
      this.$router.push('/drainage/newstaffcode')
    },
    goDetails(data) {
      this.$router.push('/drainage/details')
      setTimeout(() => {
        Details.$emit('detailsData', data)
      }, 500)
    },
    settingLabel(row) {
      const { tag } = row
      this.currentRow = row
      this.defaultChecked = tag.split(',')
      this.labelRun()
      this.dialogVisible = true
    },
    filterTree(result) {
      return result.map(item => {
        if (item.tagList) {
          return {
            id: item.groupName,
            label: item.groupName,
            children: this.filterTree(item.tagList)
          }
        }
        return {
          id: item.tagName,
          label: item.tagName
        }
      })
    },
    // 删除记录
    postDelContactWay(val) {
      postDelContactWay({ config_id: val.configId }).then(res => {
        Message.success('删除成功')
        this.runList()
      })
    }
  },
  components: {

  }
}
</script>

<style lang="less">
  @import url("./index.less");
</style>
