<template>
  <div class="material-file">
    <Search :type="3" v-bind:reset="getFilePageList" />
    <el-table :data="tableData">
      <el-table-column prop="content" label="文件名"> </el-table-column>
      <el-table-column prop="introduction" label="描述"> </el-table-column>
      <el-table-column prop="createTime" label="创建日期"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="action">
            <el-popconfirm title="确定要删除当前素材文件嘛？" @confirm="removeFile(scope.row.id)">
              <a slot="reference">删除</a>
            </el-popconfirm>
            <a @click="downloadFile(scope.row)">下载</a>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 40, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import Search from '../Search'
import { getMaterialLibraryPageList, removeMaterial } from '@/service/materialLibrary'
import qs from 'qs'
export default {
  props: {

  },
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      downLoadUrl: 'https://cdp.h5sites.com/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
    this.getFilePageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getFilePageList(introduction) {
      getMaterialLibraryPageList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        mediaType: '文件',
        introduction
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getFilePageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getFilePageList()
    },
    downloadFile(row) {
      window.open(
        this.downLoadUrl + qs.stringify({ urlString: row.picUrl }), '_self'
      )
    },
    removeFile(id) {
      removeMaterial(id).then(() => {
        this.getFilePageList()
        this.$message.success('删除成功')
      })
    }
  },
  components: {
    Search
  }
}
</script>

<style scoped lang="less">
  .action {
    a {
      margin-right: 10px;
      color: #3498db;
    }
  }
</style>
