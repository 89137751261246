<template>
  <div class="customer-stage-JourneyStageConfig">
    <el-dialog title="客户旅程阶段配置" :visible.sync="visible" width="60%" @close="hanldeClose">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @row-click="tableclick"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column label="阶段名称">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.index + ','+ scope.column.index === currentCell"
              v-model="scope.row.stageName"
              :ref="scope.row.index + ','+ scope.column.index"
              @blur="hideInput"
            ></el-input>
            <span v-else>{{scope.row.stageName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="阶段描述" width="250">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.index + ','+ scope.column.index === currentCell"
              :ref="scope.row.index + ','+ scope.column.index"
              v-model="scope.row.stageDesc"
              @blur="hideInput"
            ></el-input>
            <span v-else>{{scope.row.stageDesc}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stageLabels" label="选择标签">
          <template slot-scope="scope">
            <i
              v-if="scope.row.index + ','+ scope.column.index === currentCell"
              class="el-icon-edit-outline fontS28"
              @click="EditTag(scope.row)"
            ></i>
            <div v-else>
              <el-tag
                v-for="(v,index) in scope.row.stageLabels.split(',')"
                :key="index"
                v-show="v"
                size="mini"
              >{{ v }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i v-if="scope.row.id" class="el-icon-delete fontS28" @click="DeleteRow(scope.row)"></i>
            <i v-else class="el-icon-circle-plus fontS28" @click="AddRow(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="Save">保存</el-button>
        <el-button @click="hanldeClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 企业标签 -->
    <el-dialog title="选择标签" :visible.sync="dialogChooseVisible">
      <div>
        <div v-for="(item, index) in treeData" :key="index" style="margin-top: 15px">
          <span>{{ item.groupName }}:</span>
          <div style="margin-top: 5px; margin-bottom: 10px" class="tagSelectDialog">
            <el-checkbox-group v-model="checkboxGroup" size="medium">
              <el-checkbox-button
                v-for="tag in item.tagList"
                :label="tag.tagName"
                :key="tag.tagId"
              >{{ tag.tagName }}</el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCustomerStageList,
  postCustomerStageEditAndAdd,
  getCustomerStageDel
} from '@/service/customerManage';
import { cloneDeep } from 'lodash';
import { Message } from 'element-ui';
import { getLableList } from '@/service/label';
export default {
  name: 'notset',
  props: ['dialogTableVisible'],
  data() {
    return {
      tableData: [],
      visible: false,
      currentCell: null, // 标识当前双击的单元格
      dialogChooseVisible: false,
      treeData: [],
      checkboxGroup: [],
      editTagRow: null
    };
  },

  computed: {},
  created() {
    this.runList();
    this.labelRun();
  },
  mounted() {},
  watch: {
    dialogTableVisible(newValue, oldValue) {
      this.visible = newValue;
      this.runList();
    }
  },
  methods: {
    // 列表模块
    runList() {
      getCustomerStageList().then(res => {
        this.tableData = res;
        var obj = {
          stageName: '',
          stageLabels: '',
          id: null
        };
        this.tableData.push(obj);
      });
    },
    labelRun() {
      getLableList().then(res => (this.treeData = res));
    },
    hanldeClose(type) {
      this.$emit('close-dialogStatus', !!type);
    },
    Save() {
      var data = cloneDeep(this.tableData);
      data = data.filter(i => i.id !== null);
      data.map(item => {
        if (item.id === 'newAdd') {
          item.id = null;
        }
      });
      postCustomerStageEditAndAdd({ data: data }).then(res => {
        this.hanldeClose(true);
      });
    },
    DeleteRow(row) {
      console.log('DeleteRow -> row.id', row.id);
      if (row.id === 'newAdd') {
        this.tableData = this.tableData.filter(i => i.id !== row.id);
        Message.success('删除成功');
      } else {
        getCustomerStageDel({ id: row.id }).then(res => {
          this.tableData = this.tableData.filter(i => i.id !== row.id);
          Message.success('删除成功');
        });
      }
    },
    AddRow(row) {
      if (row.stageName === '' || row.stageLabels === '') {
        Message.warning('阶段名称和标签不能为空');
      } else {
        this.tableData.map(item => {
          if (item.id === row.id) {
            item.id = 'newAdd';
          }
        });
        var obj = {
          stageName: '',
          stageLabels: '',
          id: null
        };
        this.tableData.push(obj);
      }
    },
    EditTag(row) {
      console.log('DeleteRow -> row', row);
      this.editTagRow = row;
      this.dialogChooseVisible = true;
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    tableclick(row, column) {
      this.currentCell = row.index + ',' + column.index;
      if (column.index < 2) {
        setTimeout(() => {
          this.$refs[row.index + ',' + column.index].focus();
        });
      }
    },
    hideInput() {
      this.currentCell = null;
    },
    handleCancel() {
      this.dialogChooseVisible = false;
      this.editTagRow = null;
      this.currentCell = null;
      this.checkboxGroup = [];
    },
    handleSelect() {
      this.tableData.map(item => {
        if (item.id === this.editTagRow.id) {
          item.stageLabels = this.checkboxGroup.join(',');
        }
      });
      this.handleCancel();
    }
  }
};
</script>

<style  lang="less">
@import url("./index.less");
</style>
