<template>
  <div class="Fans-douyin">
    <div class="userListBox">
      <ul>
        <li
          v-for="item in userList"
          :key="item.openId"
          :class="item.openId === openId?'liActive':''"
          @click="changeUser(item.openId)"
        >
          <el-image style="width: 50px; height: 50px" :src="item.avatar"></el-image>
        </li>
        <li>
          <el-image style="width: 50px; height: 50px" :src="add" @click="getDouyinVerifyGetCode"></el-image>
        </li>
      </ul>
    </div>
    <div>
      <el-tabs type="border-card" v-model="searchType" @tab-click="handleClick">
        <el-tab-pane label="粉丝" name="粉丝"></el-tab-pane>
        <el-tab-pane label="关注" name="关注"></el-tab-pane>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="昵称">
            <el-input v-model="formInline.name" placeholder="昵称"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="formInline.gender" placeholder="性别">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
              <el-option label="未知" value="未知"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="省份">
            <el-select v-model="formInline.province" placeholder="省份">
              <el-option v-for="item in province" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市">
            <el-select v-model="formInline.city" placeholder="城市">
              <el-option v-for="item in city" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="text" @click="postDouyinVerifyGetFansList">
              <i class="el-icon-refresh-right"></i>更新数据
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="fansList" style="width: 100%">
          <el-table-column label="头像/用户名称">
            <template slot-scope="scope">
              <div class="disflex">
                <el-avatar :src="scope.row.avatar"></el-avatar>
                <span style="padding-left:10px">{{ scope.row.nickname }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="性别" width="180"></el-table-column>
          <el-table-column prop="province" label="省份"></el-table-column>
          <el-table-column prop="city" label="城市"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import {
  postDouyinBackendGetFansList,
  getDouyinBackendAnthorOpenid,
  getDouyinVerifyGetCode,
  getDouyinProvinveCityList,
  postDouyinVerifyGetFansList
} from '@/service/douyin';
import { Message } from 'element-ui';
import eventBus from '../eventBus';
export default {
  components: {},
  data() {
    return {
      add: require('@/assets/douyinadd.png'),
      fansList: [],
      userList: [],
      openId: null,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      searchType: '粉丝',
      province: [],
      city: [],
      formInline: {
        name: '',
        province: '',
        city: '',
        gender: ''
      },
      nowUser: {}
    };
  },
  computed: {},
  created() {
    this.getDouyinBackendAnthorOpenid();
  },
  mounted() {
    eventBus.$on('loadPage', target => {
      this.getDouyinBackendAnthorOpenid();
    });
  },
  watch: {},
  methods: {
    // 获取已授权用户列表
    getDouyinBackendAnthorOpenid() {
      getDouyinBackendAnthorOpenid().then(res => {
        if (res && res.length !== 0) {
          this.userList = res;
          this.openId = res[0].openId;
          this.nowUser = res[0];
          // 获取当前用户粉丝列表
          this.postDouyinBackendGetFansList();
          // 获取省份城市
          this.getDouyinProvinveCityList();
        }
      });
    },
    changeUser(openId) {
      this.openId = openId;
      this.formInline = {
        name: '',
        province: '',
        city: '',
        gender: ''
      };
      this.pageNo = 1;
      this.pageSize = 10;
      // 获取当前用户粉丝列表
      this.postDouyinBackendGetFansList();
      // 获取省份城市
      this.getDouyinProvinveCityList();
    },
    postDouyinBackendGetFansList() {
      var data = {
        ownerId: this.openId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        searchType: this.searchType,
        ...this.formInline
      };
      postDouyinBackendGetFansList({ ...data }).then(res => {
        if (res) {
          this.fansList = res.list;
          this.total = res.num;
        }
      });
    },
    // 获取省份城市
    getDouyinProvinveCityList() {
      getDouyinProvinveCityList({
        ownerId: this.openId
      }).then(res => {
        this.city = res.city;
        this.province = res.province;
      });
    },
    // 获取跳转url
    getDouyinVerifyGetCode() {
      getDouyinVerifyGetCode().then(res => {
        if (res) {
          window.open(res, '_blank');
        }
      });
    },
    handleClick(tab, event) {
      this.searchType = tab.name;
      this.pageNo = 1;
      this.pageSize = 10;
      this.formInline = {
        name: '',
        province: '',
        city: '',
        gender: ''
      };
      this.postDouyinBackendGetFansList();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.postDouyinBackendGetFansList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
      this.postDouyinBackendGetFansList();
    },
    onSubmit() {
      this.postDouyinBackendGetFansList();
    },
    // 更新数据
    postDouyinVerifyGetFansList() {
      var data = {
        open_id: this.openId,
        searchType: this.searchType
      };
      postDouyinVerifyGetFansList({ ...data }).then(res => {
        Message.success('更新成功');
      });
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
