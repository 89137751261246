<!-- 列表 -->
<template>
  <div class="add-scheduled-task">
    <div class="btnBox">
      <el-button round @click="goBack">退出</el-button>
    </div>
    <div class="addCenter">
      <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
        <el-step title="1.设置活动信息"></el-step>
        <el-step title="2.目标用户"></el-step>
        <el-step title="3.推送内容"></el-step>
      </el-steps>

      <el-form ref="form" :model="form" class="addFrom">
        <div v-if="active === 0" class="w680">
          <el-form-item label="活动名称" label-width="80px">
            <el-input v-model="form.name" class="inputW300"></el-input>
          </el-form-item>
          <el-form-item label="推送规则" label-width="80px">
            <el-radio-group v-model="form.rule" size="medium" @change="radioGroupChange">
              <el-radio border label="仅发送">
                <el-select v-model="sendType" placeholder="仅发送" :disabled="form.rule !== '仅发送'" class="radioSelect">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-radio>
              <el-radio border label="周期性推送"></el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="fontCss" v-show="sendType === '定时发送'">
            消息将于
            <el-date-picker v-model="dating" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            <el-time-picker arrow-control v-model="timing" type="datetime" format="HH:mm" value-format="HH:mm" placeholder="任意时间点"></el-time-picker>准时发送
          </div>
          <div class="fontCss" v-show="form.rule === '周期性推送'">
            从
            <el-date-picker v-model="datingStart" type="date" placeholder="选择日期"></el-date-picker>到
            <el-date-picker v-model="datingEnd" type="date" placeholder="选择日期"></el-date-picker>时间范围内
            <br />每日
            <el-time-picker arrow-control v-model="timing" type="datetime" placeholder="任意时间点" format="HH:mm" value-format="HH:mm"></el-time-picker>发送
          </div>
        </div>
        <div v-if="active === 1" class="dflex">
          <div>
            <el-form-item label="人群名称" label-width="80px">
              <el-select v-model="valueMeta" collapse-tags placeholder="请选择人群" class="crowdSelect" @change="peopleSelect">
                <div class="el-input">
                  <el-input placeholder="请输入内容" v-model="dropDownValue" @change="dropDownSearch" class="selectInput">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                  <!-- <input type="text" placeholder="请输入" class="el-input__inner" /> -->
                </div>
                <el-option v-for="item in optionsMetaAll" :value="item.groupName" :key="item.groupId"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="ml20">
            <el-row v-if="groupId !== null">
              <el-col :span="24">
                <el-row>
                  <el-card shadow="never">
                    <el-row type="flex" justify="space-between" class="user-prop-title pd10">
                      <div style="font-weight: bold;">总用户数</div>
                    </el-row>
                    <el-row type="flex">{{num}} 人</el-row>
                  </el-card>
                </el-row>
                <!-- 用户属性满足 start -->
                <el-row>
                  <el-card shadow="never">
                    <el-row type="flex" justify="space-between" class="user-prop-title pd10">
                      <div style="font-weight: bold;">用户属性满足</div>
                    </el-row>
                    <el-row type="flex" v-if="propertyRules.ruleDetails.length > 0">
                      <el-col :span="1" style="width:40px">
                        <div class="relationship">
                          <div class="relationship-bar"></div>
                          <div class="relationship-text" @click="handleTurnOn('propertyRules')">{{propertyRules.relationship | relationship}}</div>
                        </div>
                      </el-col>
                      <el-col :span="propertyRules.ruleDetails.length > 1 ? 23 : 24">
                        <el-row style="margin:10px 0;" :gutter="24" v-for="(item,index) in propertyRules.ruleDetails" :key="index">
                          <el-col :span="5">
                            <el-select v-model="item.key" placeholder="请选择" @change="e => handelSelectKey(e,item)" size="small" disabled>
                              <el-row class="option-search">
                                <el-input v-model="levelTwoFilter" @change="handleChangeFilter" prefix-icon="el-icon-search" clearable size="small" disabled />
                              </el-row>
                              <div v-for="(e,i) in levelOneOption" :key="i">
                                <el-row class="leveltwo-option-header">{{`${e.levelOne}(${e.propertyList.length})`}}</el-row>
                                <el-option v-for="item in e.propertyList" :key="item.leveltTwoCode" :label="item.leveltTwo" :value="item.leveltTwoCode"></el-option>
                              </div>
                            </el-select>
                          </el-col>
                          <el-col :span="5">
                            <el-select v-model="item.condition" placeholder="请选择" size="small" @change="clearGroupNum" disabled>
                              <el-option v-for="f in conditionOption" :key="f.value" :label="f.text" :value="f.value"></el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="5">
                            <el-select v-if="item.enumValue&&item.enumValue.length" v-model="item.value" placeholder="请选择" size="small" @change="clearGroupNum" disabled>
                              <el-option v-for="f in item.enumValue" :key="f" :label="f" :value="f"></el-option>
                            </el-select>
                            <el-input v-else v-model="item.value" size="small" @input="clearGroupNum" disabled />
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
                <!-- 用户属性满足 end -->

                <!-- 用户行为满足 start -->
                <el-row>
                  <el-card shadow="never">
                    <el-form>
                      <el-row type="flex" justify="space-between" class="user-prop-title pd10">
                        <div style="font-weight: bold;">用户行为满足</div>
                      </el-row>
                      <el-row :gutter="24" type="flex" v-if="actRules.actDetails.length > 0">
                        <el-col :span="1" style="width:40px">
                          <div class="relationship">
                            <div class="relationship-bar"></div>
                            <div class="relationship-text" @click="handleTurnOn('actRules')">{{actRules.relationship | relationship}}</div>
                          </div>
                        </el-col>
                        <el-col :span="actRules.actDetails.length > 1 ? 23 :24">
                          <el-row v-for="(item,index) in actRules.actDetails" :key="index">
                            <el-col :span="24">
                              <!-- 第一行 -->
                              <el-row :gutter="24" style="margin:10px 0;">
                                <el-col :span="6" style="display:flex;width:320px;">
                                  <div v-if="actRules.actDetails.length > 1" class="index AB">{{String.fromCharCode(65+index)}}</div>
                                  <el-date-picker v-model="item.date" type="daterange" range-separator="至" size="small" class="date_picker" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :clearable="false" disabled @change="(e) => handleChangeActDetailsDate(e,item)"></el-date-picker>
                                </el-col>
                                <el-col :span="2" style="width:120px;">
                                  <el-select v-model="item.actType" placeholder="请选择" size="small" @change="clearGroupNum" disabled>
                                    <el-option v-for="item in actTypeOption" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                  </el-select>
                                </el-col>
                                <el-col :span="5">
                                  <el-select v-model="item.eventCode" placeholder="请选择" size="small" @change="clearGroupNum" disabled>
                                    <el-row class="option-search">
                                      <el-input v-model="eventListFilter" prefix-icon="el-icon-search" clearable size="small" disabled />
                                    </el-row>
                                    <el-option v-for="item in eventListOptionFilter" :key="item.event_code" :label="item.event_name" :value="item.event_code"></el-option>
                                  </el-select>
                                </el-col>
                              </el-row>
                              <!-- 第二行 -->
                              <el-row :gutter="24" type="flex" style="margin:0;">
                                <el-col :span="2" style="display:flex;width:120px;">
                                  <div style="margin-right:5px;padding-left:20px;" v-if="actRules.actDetails.length > 1"></div>
                                  <el-input size="small" value="总次数" disabled />
                                </el-col>
                                <el-col :span="1" style="width: 65px">
                                  <el-input size="small" value="≥" disabled />
                                </el-col>
                                <el-col :span="4" style="display:flex;align-items:center;width:155px;">
                                  <el-input-number v-model="item.num" :precision="0" :controls="false" size="small" class="input_num" @change="clearGroupNum" disabled />
                                  <span>次</span>
                                </el-col>
                              </el-row>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-card>
                </el-row>
                <!-- 用户行为满足 end -->
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="active === 2" class="w680">
          <el-form-item label="消息标题" label-width="80px">
            <el-input v-model="form.title" class="inputW300"></el-input>
          </el-form-item>
          <el-form-item label="消息内容" label-width="80px">
            <el-input type="textarea" v-model="form.conent" class="inputW300" :rows="10"></el-input>
          </el-form-item>
          <el-form-item label="投放渠道" label-width="80px">
            <el-select v-model="form.channel" placeholder="请选择投放渠道">
              <el-option v-for="item in channelList" :value="item" :key="item"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>

      <div class="bntList">
        <el-button v-if="active !== 0" round @click="Previous">上一步</el-button>
        <el-button v-if="active !== 2" type="primary" round @click="next">下一步</el-button>
        <el-button v-if="active === 2" type="primary" round @click="next">完成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  /* eslint-disable */
  props: ["editData"],
  filters: {
    relationship (val) {
      if (val) {
        return val == "and" ? "且" : "或";
      }
    }
  },
  data () {
    return {
      active: 0,
      form: {
        name: "",
        rule: "仅发送",
        title: "",
        conent: "",
        channel: ""
      },
      sendType: "",
      options: [
        {
          value: "立即发送",
          label: "立即发送"
        },
        {
          value: "定时发送",
          label: "定时发送"
        }
      ],
      dating: new Date(),
      timing: "23:59",
      datingStart: new Date(),
      datingEnd: new Date(),
      dropDownValue: "",
      optionsMetaAll: [],
      valueMeta: [],
      propertyRules: {
        relationship: "and",
        ruleDetails: []
      },
      actRules: {
        relationship: "and",
        actDetails: []
      },
      levelTwoFilter: "",
      levelOneOption: [],
      conditionOption: [
        {
          text: "等于",
          value: "="
        },
        {
          text: "不等于",
          value: "!="
        },
        {
          text: "大于等于",
          value: ">="
        },
        {
          text: "小于等于",
          value: "<="
        },
        {
          text: "包含",
          value: "like"
        },
        {
          text: "不包含",
          value: "not like"
        }
      ],
      actTypeOption: [
        {
          text: "做过",
          value: "="
        },
        {
          text: "没做过",
          value: "!="
        }
      ],
      relationship: "且",
      eventListFilter: "",
      num: 0,
      groupId: null,
      channelList: [],
      missionId: null,
      status: 0
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this.getPeopleList();
    this.getEventList();
    this.getFilterList();
    this.getChannelList();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.init();
  },
  computed: {
    eventListOptionFilter () {
      if (this.eventListFilter) {
        let arr = this.eventListOption.filter(e =>
          e.event_name.includes(this.eventListFilter)
        );
        if (arr.length) {
          return arr;
        }
        return this.eventListOption;
      }
      return this.eventListOption;
    }
  },
  methods: {
    init () {
      if (this.editData) {
        this.missionId = this.editData.missionId;
        this.status = this.editData.status
        this.form = {
          name: this.editData.missionName,
          rule:
            this.editData.sendRules.period === "周期性推送"
              ? "周期性推送"
              : "仅发送",
          title: this.editData.messageTitle,
          conent: this.editData.messageContent,
          channel: this.editData.channel
        };
        this.sendType =
          this.editData.sendRules.period === "周期性推送"
            ? ""
            : this.editData.sendRules.sendType;
        this.dating = this.editData.sendRules.beginDate;
        this.timing = this.editData.sendRules.sendTime
        this.datingStart = this.editData.sendRules.beginDate;
        this.datingEnd = this.editData.sendRules.endDate;
        this.groupId = this.editData.groupId;
        this.valueMeta = this.editData.groupName;
        this.num = this.editData.num
        this.propertyRules = this.editData.propertyRules
          ? this.editData.propertyRules
          : {
            relationship: "and",
            ruleDetails: []
          };
        this.actRules = this.editData.actRules
          ? this.editData.actRules
          : {
            relationship: "and",
            actDetails: []
          };
      } else {
        this.missionId = null;
        this.status = 0;
        this.form = {
          name: "",
          rule: "仅发送",
          title: "",
          conent: "",
          channel: ""
        };
        this.dating = new Date(),
          this.timing = "23:59",
          this.datingStart = new Date(),
          this.datingEnd = new Date(),
          this.sendType = "";
        this.groupId = null;
        this.valueMeta = null;
        this.propertyRules = {
          relationship: "and",
          ruleDetails: []
        },
          this.actRules = {
            relationship: "and",
            actDetails: []
          }
      }
    },
    radioGroupChange (value) {
      if (value === "周期性推送") {
        this.sendType = "";
      }
    },
    dropDownSearch () {
      this.$http
        .get(
          `https://cdp.h5sites.com/scrm-platform/v1/Market/regular/usergroup/list?name=${this.dropDownValue}`
        )
        .then(res => {
          this.optionsMetaAll = res.data.data;
        });
    },
    async getFilterList (callback) {
      const { data } = await this.$http.get(
        "https://cdp.h5sites.com/scrm-platform/v1/UserGroup/property/list"
      );
      if (data.flag) {
        this.levelOneOption = data.data;
        this.levelOne = [...this.levelOneOption];
        if (callback) callback();
      }
    },
    async getChannelList (callback) {
      const { data } = await this.$http.get(
        "https://cdp.h5sites.com/scrm-platform/v1/Market/channel/list"
      );
      if (data.flag) {
        this.channelList = data.data;
      }
    },
    Previous () {
      this.active--;
    },
    next () {
      switch (this.active) {
        case 0:
          if (this.form.name === "") {
            this.$message.warning("请填写活动名称");
          } else if (this.form.rule === "仅发送" && this.sendType === "") {
            this.$message.warning("请下拉选择仅发送方式");
          } else if (
            this.form.rule === "仅发送" &&
            this.sendType === "定时发送" &&
            this.dating === ""
          ) {
            this.$message.warning("请填写定时发送时间");
          } else if (
            this.form.rule === "周期性推送" &&
            (this.timing === "" ||
              this.datingStart === "" ||
              this.datingEnd === "" ||
              this.datingStart > this.datingEnd)
          ) {
            this.$message.warning("请检查推送时间，填写错误");
          } else {
            this.active++;
          }
          break;
        case 1:
          if (this.groupId) {
            this.active++;
          } else {
            this.$message.warning("请选择人群");
          }
          break;
        case 2:
          if (this.form.title === "") {
            this.$message.warning("请填写消息标题");
          } else if (this.form.conent === "") {
            this.$message.warning("请填写消息内容");
          } else if (this.form.channel === "") {
            this.$message.warning("请选择投放渠道");
          } else {
            this.overAdd();
          }
          break;
        default:
          break;
      }
    },
    getPeopleList () {
      this.$http
        .get(
          `https://cdp.h5sites.com/scrm-platform/v1/Market/regular/usergroup/list`
        )
        .then(res => {
          this.optionsMetaAll = res.data.data;
        });
    },
    async getEventList () {
      const { data } = await this.$http.post(
        "https://cdp.h5sites.com/scrm-platform/v1/DataAnalysis/eventList"
      );
      if (data.code == 0) {
        this.eventListOption = data.data;
      }
    },
    peopleSelect (value) {
      let selectData = [];
      selectData = this.optionsMetaAll.filter(item => item.groupName === value);
      this.groupId = selectData[0].groupId;
      this.num = selectData[0].num;
      this.propertyRules = selectData[0].propertyRules
        ? selectData[0].propertyRules
        : {
          relationship: "and",
          ruleDetails: []
        };
      this.actRules = selectData[0].actRules
        ? selectData[0].actRules
        : {
          relationship: "and",
          actDetails: []
        };
      if (this.actRules.actDetails && this.actRules.actDetails.length > 0) {
        this.actRules.actDetails.forEach(e => {
          this.$set(e, "date", [e.startDate, e.endDate]);
        });
      }
    },
    handleChangeFilter () { },
    handelSelectKey () { },
    handleChangeFilter () { },
    clearGroupNum () { },
    goBack () {
      this.$emit("goBack", false);
    },
    overAdd () {
      let data = {};
      data.missionName = this.form.name;
      data.status = this.status
      if (this.missionId) {
        data.missionId = this.missionId;
      }
      data.groupId = this.groupId;
      data.messageTitle = this.form.title;
      data.messageContent = this.form.conent;
      data.channel = this.form.channel;
      data.sendRules = {};
      if (this.form.rule === "仅发送") {
        data.sendRules.period = "仅发送一次";
        if (this.sendType === "定时发送") {
          data.sendRules.sendType = "定时发送";
          data.sendRules.sendTime = this.timing;
          data.sendRules.beginDate = moment(this.dating).format("YYYY-MM-DD");
        } else {
          data.sendRules.sendType = "立即发送";
        }
      } else {
        data.sendRules.period = "周期性推送";
        data.sendRules.sendTime = this.timing;
        data.sendRules.beginDate = moment(this.datingStart).format("YYYY-MM-DD")
        data.sendRules.endDate = moment(this.datingEnd).format("YYYY-MM-DD");
      }
      this.$http
        .post(
          "https://cdp.h5sites.com/scrm-platform/v1/Market/regular/mission/create",
          data
        )
        .then(res => {
          if (res.data.flag) {
            this.$message.success(res.data.message);
            this.active = 0;
            this.$emit("goBack", false);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    }
  },
  watch: {
    editData () {
      this.init();
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
