<template>
  <div class="flow-menu autoflow" ref="tool">
    <div class='chooseComponents'>
      <el-image :src="components" fit="contain"></el-image>&nbsp;选择组件进行编排
    </div>
    <div v-for="menu in menuList" :key="menu.id">
      <span class="ef-node-pmenu" @click="menu.open = !menu.open">{{ menu.name }}</span>
      <ul v-show="menu.open" class="ef-node-menu-ul">
        <draggable @end="end" @start="move" v-model="menu.children" :options="draggableOptions">
          <li
            v-for="subMenu in menu.children"
            class="ef-node-menu-li"
            :key="subMenu.id"
            :type="subMenu.type"
            :style="subMenu.style"
          >
          <el-image :src="subMenu.img" fit="contain"></el-image>
           <span> {{ subMenu.name }}</span>
          </li>
        </draggable>
      </ul>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
/* eslint-disable */
var mousePosition = {
  left: -1,
  top: -1
};

export default {
  data() {
    return {
      components: require("@/assets/components.png"),
      activeNames: "1",
      // draggable配置参数参考 https://www.cnblogs.com/weixin186/p/10108679.html
      draggableOptions: {
        preventOnFilter: false,
        sort: false,
        disabled: false,
        ghostClass: "tt",
        // 不使用H5原生的配置
        forceFallback: true
        // 拖拽的时候样式
        // fallbackClass: 'flow-node-draggable'
      },
      // 默认打开的左侧菜单的id
      defaultOpeneds: ["1", "2"],
      menuList: [
        {
          id: "1",
          type: "common",
          name: "通用",
          ico: "el-icon-connection",
          open: true,
          children: [
            {
              id: "11",
              type: "start",
              name: "开始",
              img: require("@/assets/process/start.png"),
              nodeId:'startNode'
            },
            {
              id: "12",
              type: "wait",
              name: "等待",
              img: require("@/assets/process/wait.png"),
              nodeId:'timeDelayNode'
            },
            {
              id: "13",
              type: "judge",
              name: "条件判断",
              img: require("@/assets/process/judge.png"),
              nodeId: 'judgeNode'
            }
          ]
        },
        {
          id: "2",
          type: "trigger",
          name: "触发条件",
          open: true,
          children: [
            {
              id: "21",
              type: "wechat",
              name: "微信好友",
              img: require("@/assets/process/wechat.png"),
              nodeId:'wechatPersonSelect'
            },
            {
              id: "22",
              type: "officialFans",
              name: "公众号粉丝",
              img: require("@/assets/process/officialFans.png"),
              nodeId: "accountPersonSelect"
            },
            // {
            //   id: "23",
            //   type: "officialEvent",
            //   name: "小程序用户",
            //   img: require("@/assets/officialEvent.png"),
            //   nodeId:'accountEventNode'
            // },
            {
              id: "24",
              type: "wechatGroup",
              name: "微信群",
              img: require("@/assets/process/wechatGroup.png"),
              nodeId:'wechatGroupSelect'
            },
            {
              id: "25",
              type: "officialEvent",
              name: "公众号事件",
              img: require("@/assets/process/officialEvent.png"),
              nodeId:'accountEventNode'
            },
            {
              id: "26",
              type: "wechatEvent",
              name: "微信事件",
              img: require("@/assets/process/wechatEvent.png"),
              nodeId:'wechatEventNode'
            },
            {
              id: "27",
              type: "miniproEvent",
              name: "小程序事件",
              img: require("@/assets/process/miniproEvent.png"),
              nodeId:'miniproEventNode'
            },
            {
              id: "28",
              type: "formEvent",
              name: "填写表单",
              img: require("@/assets/process/formEvent.png"),
              nodeId:'formEventNode'
            }
          ]
        },
        {
          id: "3",
          type: "group",
          name: "营销触达",
          ico: "el-icon-connection",
          open: true,
          children: [
            {
              id: "31",
              type: "wechatMsg",
              name: "微信消息",
              img: require("@/assets/process/wechatMsg.png"),
              nodeId:'wechatPersonNode'
            },
            {
              id: "32",
              type: "accountGroup",
              name: "微信群消息",
              img: require("@/assets/process/accountGroup.png"),
              nodeId:'wechatGroupNode'
            },
            {
              id: "33",
              type: "accountFormwork",
              name: "公众号模板消息",
              img: require("@/assets/process/accountFormwork.png"),
              nodeId:'accountFormworkNode'
            },
            {
              id: "34",
              type: "officialMsg",
              name: "公众号消息",
              img: require("@/assets/process/officialMsg.png"),
              nodeId:'accountMessageNode'
            },
            {
              id: "35",
              type: "miniproMessage",
              name: "通知服务",
              img: require("@/assets/process/miniproMessage.png"),
              nodeId:'miniproMessageNode'
            },
            {
              id: "36",
              type: "shortMessage",
              name: "短信",
              img: require("@/assets/process/shortMessage.png"),
              nodeId:'shortMessageNode'
            }
          ]
        }
      ],
      nodeMenu: {}
    };
  },
  components: {
    draggable
  },
  created() {
    /**
     * 以下是为了解决在火狐浏览器上推拽时弹出tab页到搜索问题
     * @param event
     */
    if (this.isFirefox()) {
      document.body.ondrop = function(event) {
        // 解决火狐浏览器无法获取鼠标拖拽结束的坐标问题
        mousePosition.left = event.layerX;
        mousePosition.top = event.clientY - 50;
        event.preventDefault();
        event.stopPropagation();
      };
    }
  },
  methods: {
    // 根据类型获取左侧菜单对象
    getMenuByType(type) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].type === type) {
            return children[j];
          }
        }
      }
    },
    // 拖拽开始时触发
    move(evt, a, b, c) {
      var type = evt.item.attributes.type.nodeValue;
      this.nodeMenu = this.getMenuByType(type);
    },
    // 拖拽结束时触发
    end(evt, e) {
      this.$emit("addNode", evt, this.nodeMenu, mousePosition);
    },
    // 是否是火狐浏览器
    isFirefox() {
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Firefox") > -1) {
        return true;
      }
      return false;
    }
  }
};
</script>
