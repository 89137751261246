<template>
  <div class="h5-form">
    <div v-if="status==0">表单未发布</div>
    <div v-if="status==2">活动已结束</div>
    <el-form v-if="status==1" ref="form" label-width="80px" label-position="top">
      <div v-for="(item,index) in formList.formItems" :key="index">
        <el-form-item :label="item.type== 'textarea' ? '' :item.itemName" :rules="['upload','textarea'].includes(item.type)?[]:[{required:item.isMust}]">
          <el-input style="width: 100%;" v-if="item.type== 'input'" :placeholder="item.placeholder" v-model.trim="item.data[0]" upload-demo></el-input>
          <el-input-number style="width: 100%;" :placeholder="item.placeholder" v-if="item.type== 'number'" :min="1" upload-demo v-model.trim="item.data[0]"></el-input-number>
          <el-select style="width: 100%;" :placeholder="item.placeholder" v-if="item.type== 'select'" upload-demo v-model.trim="item.data[0]">
            <el-option v-for="(i,index) in item.checkbox" :key="index" :value="i"></el-option>
          </el-select>
          <el-checkbox-group v-model.trim="item.data" upload-demo v-if="item.type== 'checkbox'" style="display: flex;flex-direction: column;">
            <el-checkbox v-for="(i,index) in item.checkbox" :key="index" :label="i"></el-checkbox>
          </el-checkbox-group>
          <el-radio-group v-model.trim="item.data[0]" upload-demo v-if="item.type== 'radio'" style="display: flex;flex-direction: column;">
            <el-radio v-for="(i,index) in item.checkbox" :key="index" :label="i"></el-radio>
          </el-radio-group>
          <el-rate v-model.trim="item.data[0]" upload-demo v-if="item.type== 'rate'"></el-rate>
          <el-date-picker style="width: 100%;" :placeholder="item.placeholder" v-model.trim="item.data[0]" upload-demo v-if="item.type== 'date'" type="datetime">
          </el-date-picker>
          <div class="text" v-if="item.type== 'textarea'" :style="{'text-align':item.position,'word-break': 'break-all'}">{{item.data[0]}}</div>
          <div class="" v-if="item.type== 'upload'">
            <img :src="item.url" alt="" width="100%" height="100%">
          </div>
        </el-form-item>
      </div>
      <div class="submit-button">
        <el-button type="primary" style="width:100%;" @click="onSubmit">提交</el-button>
      </div>
      <div class="submit-button-fill"> </div>
    </el-form>
  </div>
</template>

<script>
import { formSearch, formSave, getOpenid } from '@/service/customerForm';
import { getOfficialAccountUpdateList } from '@/service/enterprise';
import wx from 'weixin-js-sdk'
export default {
  name: '',
  data () {
    return {
      dialogVisible: false,
      queryParams: {
        utm: null,
        formId: null
      },
      formList: [],
      form: {},
      url: '',
      secret: '',
      appid: '',
      openid: '',
      redirect_uri: '',
      status: 1 // 1 正常 0 草稿 2 结束
    }
  },
  created () {
    const { formId, utm = null } = this.$route.query
    this.queryParams.formId = formId
    this.queryParams.utm = utm
    this.getCode()
  },
  methods: {
    async init () {
      const data = await formSearch(this.queryParams)
      this.formList = data
      this.status = data.status
      document.title = this.formList.h5FormName
    },
    async getCode () {
      const res = await getOfficialAccountUpdateList()
      const code = this.getUrlParam('code')
      // const local = encodeURIComponent(window.location.href);
      this.secret = res.app_secret
      this.appid = res.app_id
      let query = '?formId=' + this.queryParams.formId
      if (this.queryParams.utm) {
        query += '&utm=' + this.queryParams.utm
      }
      this.redirect_uri = res.redirect_uri + query + '&space=0'
      // this.redirect_uri = 'https://cdp.h5sites.com//#support'
      const APPID = res.app_id
      const scope = 'snsapi_userinfo'; // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url = 'https://open.weixin.qq.com' + '/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + encodeURIComponent(this.redirect_uri) + '+%0A&response_type=code&scope=' + scope + '&state=#wechat_redirect';
      if (code === 'null' || code === null || code === '') {
        this.url = url
        window.location.href = url
      } else {
        this.getOpenId(code) // 把code传给后台获取用户信息
      }
    },
    async getOpenId (code) {
      const data = await getOpenid({ code })
      this.openid = data.unionid
      this.init()
    },
    async onSubmit () {
      let flag = true
      for (let i = 0; i < this.formList.formItems.length; i++) {
        const e = this.formList.formItems[i];
        if (e.isMust) {
          if ((e.data.toString().length > 0 || e.type === 'upload' || e.type === 'textarea') || (e.type === 'rate' && (e.data[0] !== 0 && e.data[0] !== '0'))) {
          } else {
            flag = false
            this.$message.error(e.placeholder)
            break
          }
        }
      }
      if (flag) {
        if (this.openid) {
          // eslint-disable-next-line no-constant-condition
          const resObj = {
            ...this.formList,
            openId: this.openid,
            utmId: this.queryParams.utm
          }
          await formSave(resObj)
          let successFlag = true
          for (const key in resObj.skipForms) {
            if (Object.hasOwnProperty.call(resObj.skipForms, key)) {
              const e = resObj.skipForms[key];
              if (!e) {
                successFlag = false
              }
            }
          }
          this.$message('提交成功')

          if (successFlag) {
            this.$router.push({
              name: 'h5Success',
              query: { ...resObj.skipForms }
            })
          } else {
            setTimeout(() => {
              wx.closeWindow()
            }, 500);
          }
        } else {
          this.getCode()
        }
      }
    },
    getUrlParam (key) {
      const hash = location.hash
      if (hash) {
        return this.getHashParam(key)
      } else {
        return this.getHistoryParam(key)
      }
    },
    getHashParam (key) {
      const url = location.href
      // 获取 hash 值，不包含 '#' 号
      const hash = url.substring(url.indexOf('#') + 1)
      // 查找 '?' 号所在的索引
      const searchIndex = hash.indexOf('?')
      // '?' 号后面接的是索引参数，如果找到则+1，去除'?' 号
      const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ''
      // 把搜索参数字符串提过URLSearchParams转成对象形式
      const usp = new URLSearchParams(search)
      // 通过URLSearchParams自带的get方法，查询键所对应的值
      return usp.get(key)
    },
    // 获取url中的参数
    getHistoryParam (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); // 构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); // 匹配目标参数
      if (r != null) {
        return unescape(r[2]);
      }
      return null; // 返回参数值
    }
  }
}
</script>

<style lang="less" scoped>
.h5-form {
  padding: 20px;
  .el-date-editor.el-input {
    margin: 0;
  }
  .submit-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    border-top: 1px solid #ececec;
    width: 100%;
    box-sizing: border-box;
    z-index: 333;
    background: #fff;
    height: 80px;
    line-height: 80px;
  }
  .submit-button-fill {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    height: 80px;
    line-height: 80px;
  }
}
</style>
