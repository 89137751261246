
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card>
          <div class="info_card">
            <h2 v-text="orderInfo.tag_name" />
            <el-divider />
            <p class="info_line">
              <span class="info_title">标签id：</span><span v-text="orderInfo.tag_id">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">1级主题名称：</span><span v-text="orderInfo.level_1_name">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">2级主题名称：</span><span v-text="orderInfo.level_2_name">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">用户类型：</span><span v-text="orderInfo.user_type">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">标签类型：</span><span v-text="orderInfo.tag_type">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">开发类型：</span><span v-text="orderInfo.develop_type">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">是否互斥：</span><span v-text="orderInfo.exclusion">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">是否在线使用：</span><span v-text="orderInfo.is_online">...</span>
            </p>
            <p class="info_line">
              <span class="info_title">标签的业务意义：</span><span v-text="orderInfo.tag_desc">...</span>
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div id="pieChartOrder" style="width: 100%; height: 360px"></div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-card>
        <p>
          <el-date-picker :default-time="['08:00:00', '24:00:00']" size="mini" v-model="selectedDateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="setChartTag">
          </el-date-picker>
        </p>
        <div id="chartTag" style="width: 100%; height: 400px"></div>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  /* eslint-disable */
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        onPick: (dateRange) => {
          this.queryData.startDate = dateRange.minDate
          this.queryData.endDate = dateRange.maxDate
        }
      },
      queryData: {
        tagId: '',
        startDate: '',
        endDate: '',
        dataDate: ''
      },
      orderInfo: {},
      pieChartOrder: null,
      optionOrder: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'horizontal',
          top: 'auto',
          data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              { value: 335, name: '直接访问' },
              { value: 310, name: '邮件营销' },
              { value: 234, name: '联盟广告' },
              { value: 135, name: '视频广告' },
              { value: 1548, name: '搜索引擎' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      chartTagObj: null,
      optionTag: {
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '标签总量'
          }
        ],
        series: [
          {
            name: '直接访问',
            type: 'bar',
            barWidth: '60%',
            data: [10, 52, 200, 334, 390, 330, 220]
          }
        ]
      },
      selectedDateRange: []
    }
  },
  created () {
  },
  mounted () {
    this.getQueryData()
    console.log(this.queryData)
    this.pieChartOrder = this.$echarts.init(document.getElementById('pieChartOrder'))
    this.chartTagObj = this.$echarts.init(document.getElementById('chartTag'))
    this.getOrderInfo()
    this.setPieChartOrder()
    this.setChartTag()
  },
  methods: {
    getQueryData () {
      const date1 = new Date()
      const year = date1.getFullYear()
      let month = date1.getMonth() + 1
      month = month >= 10 ? month : '0' + month
      let day = date1.getDate()
      day = day >= 10 ? day : '0' + day
      const end = year + '-' + month + '-' + day
      const date2 = new Date(date1)
      date2.setDate(date1.getDate() - 30)
      let month2 = date2.getMonth() + 1
      month2 = month2 >= 10 ? month2 : '0' + month2
      let day2 = date2.getDate()
      day2 = day2 >= 10 ? day2 : '0' + day2
      //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
      var start = date2.getFullYear() + "-" + month2 + "-" + day2
      this.selectedDateRange = [start, end]
      this.queryData = {
        startDate: start,
        endDate: end,
        dataDate: end,
        tagId: window.localStorage.getItem('tagId')
      }
    },
    resetQuery () {
      this.queryData.startDate = ''
      this.queryData.endDate = ''
    },
    async getOrderInfo () {
      const { data: res } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/UserProfile/searchTagId', {}, {
        params: this.queryData
      })
      this.orderInfo = res.data.list[0]
    },
    async setPieChartOrder () {
      this.queryData.dataDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
      console.log("🚀 ~ file: TagDetails.vue ~ line 246 ~ setPieChartOrder ~ this.queryData.dataDate", this.queryData.dataDate)
      let xdata = []
      let ydata = []
      const { data: res } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/UserProfile/checkTagRatio', {}, {
        params: this.queryData
      })
      res.data.list.forEach((item) => {
        xdata.push(item.tagname)
        ydata.push({
          value: item.num,
          name: item.tagname
        })
      })
      this.optionOrder.legend.data = xdata
      this.optionOrder.series[0].data = ydata
      this.pieChartOrder.setOption(this.optionOrder)
    },
    async setChartTag (value) {
      let queryData = {}
      if (value) {
        this.queryData.startDate = value[0]
        this.queryData.endDate = value[1]
      }
      queryData = this.queryData
      let xdata = []
      let ydata = []
      console.log(queryData)
      const { data: res } = await this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/UserProfile/checkTagNum', {}, {
        params: queryData
      })
      res.data.list.forEach((item) => {
        xdata.push(item.datadate)
        ydata.push(item.num)
      })
      this.optionTag.xAxis[0].data = xdata
      this.optionTag.series[0].data = ydata
      this.chartTagObj.setOption(this.optionTag)
    }
  }
}
</script>

<style scoped>
h2,
p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.el-row {
  padding: 0 20px;
  margin-top: 5px;
}
.info_card {
  height: 360px;
}
.info_line {
  margin: 8px 0;
}
.info_line .info_title {
  font-weight: bolder;
}
</style>
