<!-- 公共过滤条件 -->
<template>
  <div class="common-condition">
    <div class="title">公共过滤条件</div>
    <div class="common-item" v-for="item in filterCommon" :key="item.title">
      <el-tag size="small">{{ item.title }}</el-tag>
      <el-tag size="small">等于</el-tag>
      <el-dropdown placement="bottom-start" @command="handelCurMenu" trigger="click" size="mini">
        <el-tag size="small">{{ item.default.name }}</el-tag>
        <el-tag size="small" @click="removeTag(item)"><i class="el-icon-delete"></i></el-tag>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="v" v-for="v in item.children" :key="v.name">{{ v.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dropdown size="mini" class="dropdown-box" trigger="click" @command="selectCommand" placement="bottom-end">
      <div class="common-select">
        <div class="icon-add">
          <i class="iconfont icon-xinjian"></i>
        </div>
        <el-tag size="small">请选择</el-tag>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="item" v-for="item in currentList" :key="item.title">{{ item.title }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: ['filterParameter'],
  data () {
    /* eslint-disable */
    return {
      setName: new Set(),
      currentList: [],
      filterCommon: [],
      resData: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this._getCommenCondition()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    _getCommenCondition () {
      this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/DataAnalysis/dropDownList').then(res => {
        this.resData = res.data.data.list
        this.resData.forEach(item => {
          this.setName.add(item.filter)
        })
        this.setName.forEach(item => {
          const itemV = this.resData.filter(value => item === value.filter)
          this.currentList.push({
            title: item,
            children: itemV,
            default: itemV[0]
          })
        })

      })
    },
    selectCommand (command) {
      this.filterCommon.push(command)
    },
    handelCurMenu (command) {
      this.filterCommon = this.filterCommon.map(item => {
        if (item.title === command.filter) {
          item.default = command
        }
        return item
      })
    },
    removeTag (item) {
      let index = this.filterCommon.findIndex(v => v === item)
      if (index !== -1) {
        this.filterCommon.splice(index, 1)
      }
    }
  }
  , watch: {
    filterCommon () {
      this.$emit('getComonData', this.filterCommon)
    },
    filterParameter: {
      immediate: true,
      deep: true,
      handler (val) {
        var res = []
        setTimeout(() => {
          val && val.forEach(t => {
            const data = this.resData.filter(value => t.tag === value.tag)
            res.push({
              title: t.name,
              default: data[0],
              children: data,
            })
          })
          this.filterCommon = res
        }, 1000);
      }
    }
  }
}
</script>
<style  scoped>
/* @import url(); 引入css类 */
@import url("./index.css");
</style>
