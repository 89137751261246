<template>
  <!-- 服务通知 -->
  <div class="wechatFriends">
    <div class="nodeTitle">服务通知配置</div>
     <el-select v-model="data.template_id" placeholder="请选择" @change="tempLateSelect">
            <el-option v-for="item in templateOptions" :value="item.priTmplId" :key="item.priTmplId" :label="item.title"></el-option>
          </el-select>
          <div v-show="data.template_id" class="disflexf">
            <div class="previewBox">
              <div class="topBox">
                <el-image :src="miniPro"></el-image>小程序名称
              </div>
              <div class="centerBox">
                <p>{{ templateTitle }}</p>
                <ul>
                  <li v-for="item in templateContent" :key="item.key">
                    <span>{{ item.label }}</span>
                    <span>{{ item.value }}</span>
                  </li>
                </ul>
              </div>
              <div class="bottomBox">
                查看详情<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="input_box">
              <div class="inputBox">
                <span class="label">模板ID</span>
                <span class="value">{{ data.template_id }}</span>
              </div>
              <div class="inputBox">
                <span class="label">模板名称</span>
                <span class="value">{{ templateTitle }}</span>
              </div>
              <div class="inputBox">
                <span class="label">跳转页面</span>
                <span class="value">
                  <el-input v-model="data.page"> </el-input>
                </span>
              </div>
              <div class="inputListBox">
                <span class="label">详细内容</span>
                <span class="value">
                  <div v-for="item in templateContent" :key="item.key" class="inputBox">
                    <span class="label">{{ item.label }}</span>
                    <el-input v-model="item.value"> </el-input>
                  </div>
                </span>
              </div>
            </div>
          </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Message } from "element-ui";

export default {
  props: ["editnode"],
  data() {
    return {
      data:{
        template_id: "",
        page: "",
        miniprogram_state: "formal",
        lang: "zh_CN",
        data:{}
      },
      miniPro: require('@/assets/minipro.png'),
      templateContent: [],
      templateTitle: '',
      templateOptions: [],
    };
  },
  mounted() {},
  created() {
    this.gettemplate();
  },
  watch: {
    editnode(val) {
      console.log("wewe-> val", val);
      this.init(val);
    }
  },
  methods: {
    async gettemplate() {
      const { data } = await this.$http.get(
        "http://127.0.0.1:8090/scrm-platform/mini/subscribe/gettemplate"
      );
      if (data.code === 200) {
        this.templateOptions = data.data.data
      }
    },
    save() {
      console.log("save -> this.node", this.data);
      if (this.data.template_id === "" ) {
        Message.warning("请选择模板！");
        return false;
      }
      if (this.data.page === "" ) {
        Message.warning("请输入跳转页面");
        return false;
      }
      this.templateContent.map(item => {
        this.data.data[item.key] = { value: item.value }
      })
      console.log("save -> this.node", this.data);
      return this.data;
    },
    tempLateSelect (value) {
      if(!value) return
      this.templateContent = []
      this.templateTitle = ''
      var contentStr = ''
      var selectTemp = this.templateOptions.filter(i => i.priTmplId === value)[0]
      contentStr = selectTemp.example
      this.templateTitle = selectTemp.title
      var content = contentStr.split("\n");
      var keyArr = selectTemp.content.split("\n")
      content.map((item, index) => {
        if (item !== '') {
          var obj = { label: item.split(":")[0], value: item.split(":")[1], key: this.setKey(keyArr[index].split(':')[1]) }
          this.templateContent.push(obj)
        }
      })
    },
    setKey (str) {
      var a1 = /\{{(.+?)\}}/g;
      let a2 = str.match(a1).toString()    //因为输出的是数组，所以得转化成字符串
      var a3 = a2.replace("{{", "");         //用空字符替换掉括号
      a3 = a3.replace("}}", "");
      a3 = a3.replace(".DATA", "");
      return a3
    },
    init(val) {
      console.log("init -> val", val);
      this.data = val || {
        template_id: "",
        page: "",
        miniprogram_state: "formal",
        lang: "zh_CN",
        data:{}
      }
      this.tempLateSelect(this.data.template_id)
    }
  }
};
</script>

<style lang="less">
.wechatFriends {
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-select {
    margin-bottom: 10px;
  }
  .disflexf {
    display: flex;
    flex-direction: column;
    align-items: center;

    .previewBox {
      width: 100%;
      display: flex;
      border: 1px solid #DCDFE6;
      flex-direction: column;

      .topBox {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #DCDFE6;
        padding: 20px 0 10px 0;
        color: #606266;

        img {
          width: 40px;
          padding: 0 10px 0px 20px;
        }
      }

      .centerBox {
        padding: 20px 10px 70px;

        ul {
          padding-left: 10px;
          font-size: 14px;

          li {
            display: flex;
            justify-content: flex-start;
            padding-bottom: 5px;
            span:first-child {
              color: #606266;
              width: 100px;
            }
          }
        }
      }

      .bottomBox {
        border-top: 1px solid #DCDFE6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        color: #606266;
      }
    }
    .inputListBox{
      width: 100%;
      .label {
        width: 100px;
      }
      display: flex;
    }
    .input_box{
      width: 100%;
      margin-top: 50px;
    }
    .inputBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 25px;

      .label {
        width: 100px;
      }
      .value{
        width: calc(100% - 100px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
