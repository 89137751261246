<template>
  <el-dialog title="详情" :visible.sync="visible">
    <el-button class="btn" type='primary' @click="add">新增</el-button>
    <el-table :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" :data="tableData" style="width: 100%">
      <el-table-column prop="utm" label="utm参数">
        <template v-slot="{row}">
          <span v-if="row.createTime">{{row.utm}}</span>
          <el-input v-else v-model="row.utm"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="姓名">
        <template v-slot="{row}">
          <span v-if="row.createTime">{{row.username}}</span>
          <el-input v-else v-model="row.userName"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="browseNum" label="浏览人次"></el-table-column>
      <el-table-column prop="fillNum" label="填写人数"></el-table-column>
      <el-table-column label="链接">
        <template v-slot="{row}">
          <el-button v-if="row.utmUrl" type="text" @click="copy(row)">复制</el-button>
        </template>
      </el-table-column>
      <el-table-column label="二维码">
        <template v-slot="{row}">
          <img class="code" :src="row.qrcodeUrl" @click="downLoad(row.qrcodeUrl)">
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot="{row}">
          <span v-if="row.createTime" class="el-icon-circle-close del" @click="del(row)"></span>
          <el-button type="primary" size="small" v-else @click="save(row)">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { queryChannel, addChannel, delChannel } from '@/service/customerForm'
export default {
  data() {
    return {
      formId: '',
      visible: false,
      tableData: []
    }
  },
  methods: {
    downLoad(urlString) {
      const url = 'https://cdp.h5sites.com/scrm-platform/qrcode/generate/download/pic?urlString=' + urlString;
      window.open(url, '_self');
    },
    openDialog(row) {
      this.formId = row.formId
      this.getList(row.formId);
      this.visible = true
    },
    getList(formId) {
      queryChannel(formId).then(res => {
        this.tableData = res.list
      })
    },
    add() {
      this.tableData.push({})
    },
    del(row) {
      row.formId = this.formId
      delChannel(row).then(res => {
        this.getList(this.formId);
        this.$message.success('删除成功')
      })
    },
    save(row) {
      row.formId = this.formId
      addChannel(row).then(res => {
        this.getList(this.formId);
        this.$message.success('保存成功')
      })
    },
    copy(row) {
      const input = document.createElement('input'); // 创建input对象
      input.value = row.utmUrl; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功！');
    }
  }
}
</script>

<style lang="less" scoped>
  .del {
    cursor: pointer;
    font-size: 20px;
  }
  .btn {
    margin-bottom: 10px;
  }
</style>
