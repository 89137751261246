<template>
  <div class="message-push">
    <el-tabs v-model="activeName" @tab-click="handleClick" v-show="!showAdd">
      <el-tab-pane label="我的消息" name="first">
        <el-row>
          <el-col :span="5" class="mgl-20">
            <el-card @click.native="addTemplate">
              <div class="add-push">
                <i class="iconfont icon-add-copy"></i>
                <span>新建消息</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="5" v-for="item in list" :key="item.id" class="mgl-20" @mouseenter.native="onEnter(item)" @mouseleave.native="onLeave(item)">
            <el-card>
              <div class="cardTitle">{{ item.missionName }}</div>
              <div class="cardContent">
                <ul>
                  <li v-for="(i, index) in setArray(item.data)" :key="index">
                    <!-- 字段{{ index + 1 }} -->
                    <span>{{ item.templateTitle[index] }} : {{ i.value }}</span>
                  </li>
                </ul>
              </div>
              <div class="cardBottom">
                <span>更新于</span>
                <span>{{ item.createTime }}</span>
              </div>
              <transition name="el-zoom-in-center">
                <div class="btnList" v-show="item.edit">
                  <span style="padding-right: 16%" @click="goDetails(item)">
                    <i class="iconfont icon-bianji"></i>
                    <span>编辑</span>
                  </span>
                  <span @click="delTemplate(item)">
                    <i class="iconfont icon-shouye-shanchu"></i>
                    <span>删除</span>
                  </span>
                </div>
              </transition>
            </el-card>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" :total="total" :hide-on-single-page="true" @current-change="pageChange"></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="发送日志" name="second">
        <el-row>
          <el-col :span="6">
            <el-input v-model="missionName" placeholder="任务名称"></el-input>
          </el-col>
          <el-col :span="10" style="marginleft: 10px" :offset="1">
            <el-button type="primary" @click="searchName">查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="logList" style="width: 100%">
          <el-table-column prop="mission_name" label="任务名称" width="180"></el-table-column>
          <el-table-column prop="title" label="消息模板" width="180"></el-table-column>
          <el-table-column prop="tags" label="推送对象"></el-table-column>
          <el-table-column prop="sendnum" label="发送人数"></el-table-column>
          <el-table-column prop="successnum" label="成功接收"></el-table-column>
          <el-table-column prop="num" label="预计覆盖人数"></el-table-column>
          <el-table-column prop="send_type" label="定时任务"></el-table-column>
          <el-table-column prop="create_time" label="创建时间" width="160"></el-table-column>
          <el-table-column prop="send_time" label="推送时间" width="160"></el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="logTotal" :hide-on-single-page="true" @current-change="logpageChange"></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <AddTemplate v-show="showAdd" @callBack="callBack" :dataDetail="dataDetail"></AddTemplate>
  </div>
</template>

<script>
import {
  getTemplateMissionList,
  getLogList,
  delTemplate
} from '@/service/push';
import AddTemplate from './AddTemplate';
import { Message } from 'element-ui';
export default {
  data () {
    return {
      activeName: 'first',
      pageNo: 1,
      pageSize: 10,
      list: [],
      total: 0,
      showAdd: false,
      missionName: null,
      logList: [],
      logTotal: 0,
      dataDetail: {}
    };
  },
  computed: {},
  created () {
    this.getTemplateMissionList();
    // this.getLogList();
  },
  mounted () { },
  watch: {},
  methods: {
    handleClick (tab, event) {
      console.log('🚀 ~ file: index.vue ~ line 142 ~ handleClick ~ tab', tab)
      this.pageNo = 1;
      this.pageSize = 10;
      if (tab.name === 'second') {
        this.getLogList()
      }
    },
    getTemplateMissionList () {
      getTemplateMissionList({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        this.list = res.list;
        this.total = res.num;
      });
    },
    // 对象转数组
    setArray (data) {
      var qyArry = Object.keys(data).map(item => {
        // 去除空值
        if (data[item] == null) {
          delete data[item];
        }
        return data;
      });
      // 转为数组
      return Object.values(qyArry[0]);
    },
    onEnter (item) {
      this.list = this.list.map(i => {
        if (item.id === i.id) {
          i.edit = true;
          return i;
        } else {
          i.edit = false;
          return i;
        }
      });
    },
    onLeave (item) {
      this.list = this.list.map(i => {
        i.edit = false;
        return i;
      });
    },
    addTemplate () {
      this.showAdd = true;
    },
    getLogList () {
      getLogList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        missionName: this.missionName
      }).then(res => {
        this.logList = res.list;
        this.logTotal = res.num;
      });
    },
    searchName () {
      this.getLogList();
    },
    callBack () {
      this.getTemplateMissionList();
      this.showAdd = false;
    },
    delTemplate (item) {
      delTemplate({
        id: item.id
      }).then(res => {
        this.getTemplateMissionList();
        this.getLogList();
        Message.success('删除成功');
      });
    },
    goDetails (item) {
      this.showAdd = true;
      this.dataDetail = item;
    },
    pageChange (currentPage) {
      this.pageNo = currentPage
      this.getTemplateMissionList()
    },
    logpageChange (currentPage) {
      this.pageNo = currentPage
      this.getLogList()
    }
  },
  components: {
    AddTemplate
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
