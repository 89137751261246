<template>
  <div class="Xiao-OrderList">
    <div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="昵称">
          <el-input v-model="formInline.nickname" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="formInline.productName" placeholder="商品名称"></el-input>
        </el-form-item>
        <el-form-item label="客户端类型">
          <el-input v-model="formInline.clientType" placeholder="客户端类型"></el-input>
        </el-form-item>
        <el-form-item label="付款时间范围">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="List" style="width: 100%">
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="userNickname" label="商品图片">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" fit='cover' :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"></el-image>
          </template>
        </el-table-column>
         <el-table-column prop="userNickname" label="用户昵称"></el-table-column>
        <el-table-column prop="clientType" label="客户端类型"></el-table-column>
        <el-table-column prop="paymentType" label="支付类型"></el-table-column>
        <el-table-column prop="count" label="购买数量"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column prop="payTime" label="付款时间"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { postXiaoErtongBackendGetOrderList } from '@/service/xiaoetong';
// import { Message } from "element-ui";
import moment from 'moment';
export default {
  components: {},
  data() {
    return {
      List: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      date: '',
      formInline: {
        nickname: '',
        productName: '',
        startTime: '',
        endTime: '',
        clientType: ''
      }
    };
  },
  computed: {},
  created() {
    this.postXiaoErtongBackendGetOrderList();
  },
  mounted() {},
  watch: {},
  methods: {
    postXiaoErtongBackendGetOrderList() {
      var data = {
        ...this.formInline,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      postXiaoErtongBackendGetOrderList({ ...data }).then(res => {
        this.List = res.list;
        this.total = res.num;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.postXiaoErtongBackendGetOrderList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
      this.postXiaoErtongBackendGetOrderList();
    },
    onSubmit() {
      if (this.date) {
        this.formInline.startTime = moment(this.date[0]).format('YYYY-MM-DD');
        this.formInline.endTime = moment(this.date[1]).format('YYYY-MM-DD');
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      this.postXiaoErtongBackendGetOrderList();
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
