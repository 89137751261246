<template>
  <div class="mapChart">
    <div class="name">{{ source.title }}</div>
    <div class="flexbox">
      <div id="city" class="myChart"></div>
      <div class="table">
        <div class="tableSelect">
          <el-select
            v-model="province"
            placeholder="请选择"
            :clearable="false"
            @change="provinceChange"
          >
            <el-option
              v-for="item in source.province"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
        <el-table :data="tableData" style="width: 100%" size="mini">
          <el-table-column prop="name" :label="source.label" ></el-table-column>
          <el-table-column prop="value" label="用户数"></el-table-column>
          <el-table-column label="占比">
            <template slot-scope="scope">
              <span v-if="total>0">{{(scope.row.value / total ).toFixed(4)*100 }} %</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import shanghai from './City/shanghai.json';
import shangdong from './City/shangdong.json';
import jiangsu from './City/jiangsu.json';
import zhejiang from './City/zhejiang.json';
import anhui from './City/anhui.json';
import fujian from './City/fujian.json';
import jiangxi from './City/jiangxi.json';
import guangdong from './City/guangdong.json';
import guangxi from './City/guangxi.json';
import hainan from './City/hainan.json';
import henan from './City/henan.json';
import hunan from './City/hunan.json';
import hubei from './City/hubei.json';
import neimenggu from './City/neimenggu.json';
import hebei from './City/hebei.json';
import tianjin from './City/tianjin.json';
import beijing from './City/beijing.json';
import shanxi from './City/shanxi.json';
import shanxi_ from './City/shanxi_.json';
import xinjiang from './City/xinjiang.json';
import ningxia from './City/ningxia.json';
import qinghai from './City/qinghai.json';
import gansu from './City/gansu.json';
import xizang from './City/xizang.json';
import yunnan from './City/yunnan.json';
import sichuan from './City/sichuan.json';
import guizhou from './City/guizhou.json';
import chongqing from './City/chongqing.json';
import heilongjiang from './City/heilongjiang.json';
import jilin from './City/jilin.json';
import liaoning from './City/liaoning.json';
import xianggan from './City/xianggan.json';
import taiwan from './City/taiwan.json';
import aomen from './City/aomen.json';
export default {
  props: ['source'],
  data() {
    return {
      option: {
        geo: {
          show: true,
          map: '新疆',
          zoom: 1.2,
          aspectScale: 0.9,
          label: {
            show: false
          },
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: "#000",
              borderWidth: 4, // 设置外层边框
              borderColor: '#F2F3F2'
            },
            emphasis: {
              show: false
            }
          }
        },
        visualMap: {
          min: 0,
          max: 0,
          right: 0,
          realtime: false,
          calculable: true,
          label: 'right',
          itemWidth: 10,
          itemHeight: 50,
          inRange: {
            color: ['#D1EFD1', '#1AAD19']
          }
        },
        series: [
          {
            type: 'map',
            mapType: 'xinjiang', // 地图名称
            roam: false,
            zoom: 1.2,
            aspectScale: 0.9,
            itemStyle: {
              normal: {
                show: true,
                areaColor: '#D1EFD1',
                borderColor: '#fff'
              },
              emphasis: {
                show: false
              } // 鼠标事件区块样式
            },
            data: []
          }
        ]
      },
      total: 0,
      province: '',
      city: null,
      tableData: []
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.setEchart();
  },
  watch: {
    source() {
      this.setEchart();
    }
  },
  methods: {
    refreshMap() {
      var myChart = echarts.init(document.getElementById('city')); // 拿到一个实例
      echarts.registerMap(this.province, this.city, {}); // 引入地图文件
      this.option.series[0].data = this.tableData;
      this.option.series[0].mapType = this.province;
      this.option.visualMap.max = this.total;
      this.option.geo.map = this.province;
      myChart.setOption(this.option);
    },
    setEchart() {
      this.province = this.source.province && this.source.province[0].name;
      this.provinceChange();
    },
    provinceChange() {
      var t = this.province.substring(0, 2);
      switch (t) {
        case '香港':
          this.city = xianggan;
          break;
        case '澳门':
          this.city = aomen;
          break;
        case '台湾':
          this.city = taiwan;
          break;
        case '黑龙':
          this.city = heilongjiang;
          break;
        case '吉林':
          this.city = jilin;
          break;
        case '辽宁':
          this.city = liaoning;
          break;
        case '西藏':
          this.city = xizang;
          break;
        case '重庆':
          this.city = chongqing;
          break;
        case '云南':
          this.city = yunnan;
          break;
        case '贵州':
          this.city = guizhou;
          break;
        case '四川':
          this.city = sichuan;
          break;
        case '宁夏':
          this.city = ningxia;
          break;
        case '青海':
          this.city = qinghai;
          break;
        case '陕西':
          this.city = shanxi_;
          break;
        case '甘肃':
          this.city = gansu;
          break;
        case '新疆':
          this.city = xinjiang;
          break;
        case '北京':
          this.city = beijing;
          break;
        case '山西':
          this.city = shanxi;
          break;
        case '内蒙':
          this.city = neimenggu;
          break;
        case '天津':
          this.city = tianjin;
          break;
        case '河北':
          this.city = hebei;
          break;
        case '河南':
          this.city = henan;
          break;
        case '湖南':
          this.city = hunan;
          break;
        case '湖北':
          this.city = hubei;
          break;
        case '山东':
          this.city = shangdong;
          break;
        case '江苏':
          this.city = jiangsu;
          break;
        case '上海':
          this.city = shanghai;
          break;
        case '浙江':
          this.city = zhejiang;
          break;
        case '安徽':
          this.city = anhui;
          break;
        case '福建':
          this.city = fujian;
          break;
        case '江西':
          this.city = jiangxi;
          break;
        case '广东':
          this.city = guangdong;
          break;
        case '广西':
          this.city = guangxi;
          break;
        case '海南':
          this.city = hainan;
          break;
        default:
          break;
      }
      var id = this.source.province.filter(i => i.name === this.province)[0].id;
      var newdata = [];
      // 找出对应省 市
      this.source.data.map(item => {
        var itemid = item.id + '';
        if (id + '' === itemid.substr(0, 2)) {
          newdata.push({
            name: item.name + '市',
            id: item.id,
            value: item.value
          });
        }
      });
      this.tableData = newdata;
      this.total = this.tableData
        .map(row => row.value)
        .reduce((acc, cur) => cur + acc, 0);
      this.refreshMap();
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./index.less");
</style>
