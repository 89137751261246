<template >
  <div class="official-less">
    <el-tabs @tab-click="handleClick" v-model="type">
      <el-tab-pane name="text">
        <span slot="label" class="tabAdd">
          <i class="el-icon-edit"></i> 文字/超链接
        </span>
        <el-input type="textarea" :rows="8" placeholder="请填写内容" v-model="form.replyInfo"></el-input>
      </el-tab-pane>
      <el-tab-pane name="news" class="newstabpane">
        <span slot="label" class="tabAdd">
          <i class="el-icon-tickets"></i>回复图文消息
        </span>
        <el-popover placement="right-start" width="400" trigger="manual" v-model="visible">
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="form"
            class="replyFrom"
          >
            <el-form-item label="封面介绍">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="封面图片">
              <el-upload
                action="#"
                class="upload-box"
                ref="upload"
                :auto-upload="true"
                :limit="1"
                :on-exceed="handleExceed"
                accept=".jpg, .jpeg, .png, .gif"
                :http-request="uploadPic"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload"
              >
                <el-button type="primary" class="uploadBtn">
                  <i class="iconfont icon-yunshangchuan"></i>
                  上传图片
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="简介">
              <el-input type="text" v-model="form.digest"></el-input>
            </el-form-item>
            <el-form-item label="摘要（选填，该摘要只在发送图文信息为单条时显示）">
              <el-input type="textarea" :rows="5" v-model="form.replyInfo"></el-input>
            </el-form-item>
            <el-form-item label="跳转地址">
              <el-input v-model="form.url">
                <template slot="append">跳转地址</template>
              </el-input>
            </el-form-item>
            <el-form-item label="作者">
              <el-input v-model="form.author"></el-input>
            </el-form-item>
            <el-form-item label="是否打开评论">
              <el-radio-group v-model="form.need_open_comment" size="medium">
                <el-radio :label="1" :value="1">是</el-radio>
                <el-radio :label="0" :value="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否粉丝可评论">
              <el-radio-group v-model="form.only_fans_can_comment" size="medium">
                <el-radio :label="1" :value="1">是</el-radio>
                <el-radio :label="0" :value="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="news" slot="reference">
            <img :src="src" />
            <div class="newsTitle">
              <span>{{ form.title }}</span>
            </div>
          </div>
        </el-popover>
      </el-tab-pane>
      <el-tab-pane name="image">
        <span slot="label" class="tabAdd">
          <i class="el-icon-picture"></i>回复图片
        </span>
        <el-upload
          action="#"
          list-type="picture-card"
          ref="uploadPic"
          :limit="1"
          :on-exceed="handleExceed"
          :before-upload="beforeAvatarUpload"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemovePic"
          :http-request="uploadRePic"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { uploadTemp, uploadNews } from '@/service/sendtogroup';
import { uploadImg } from '@/service/materialLibrary';
// , postAddAutoReply
import { Message } from 'element-ui';
export default {
  props: ['editnode'],
  data() {
    return {
      keywords: '',
      replyInfo: '',
      type: 'text',
      src: require('@/assets/error.jpg'),
      visible: false,
      form: {
        title: '标题',
        replyInfo: '',
        url: '',
        typeInfo: 'subscribe',
        type: 'news',
        author: '',
        digest: '',
        need_open_comment: 0,
        only_fans_can_comment: 0
      },
      labelPosition: 'top',
      upload: {
        media: null,
        type: 'image'
      },
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: '',
      meidaId: '',
      uploading: false,
      fileList: [],
      meidaIdNews: ''
    };
  },
  computed: {},
  created() {
    // this.getPageList()
  },
  mounted() {},
  watch: {
    editnode(val) {
      console.log('wewe-> val', val);
      this.initData(val);
    }
  },
  methods: {
    handleClick(tab, event) {
      this.type = tab.name;
      this.initData();
      if (tab.name === 'news') {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    initData(val) {
      console.log('initData -> val', val);
      if (val) {
        this.type = val.msgtype;
        var mpnewsObj = {};
        if (val.mpnewsObj) {
          mpnewsObj = val.mpnewsObj;
        }
        this.form = {
          touser: '',
          url: mpnewsObj.content_source_url,
          digest: mpnewsObj.digest,
          title: mpnewsObj.title,
          author: mpnewsObj.author,
          need_open_comment: mpnewsObj.need_open_comment
            ? mpnewsObj.need_open_comment
            : 0,
          only_fans_can_comment: mpnewsObj.only_fans_can_comment
            ? mpnewsObj.only_fans_can_comment
            : 0,
          replyInfo: mpnewsObj.content
        };
        this.imageUrl = mpnewsObj.thumb_media_id;
        if (this.type === 'mpnews') {
          this.meidaIdNews = val.mpnews.media_id;
          console.log('initData -> this.src', this.src);
          this.fileList = mpnewsObj.thumb_media_id
            ? [{ name: '', url: mpnewsObj.thumb_media_id }]
            : [];
        } else {
          this.fileList = val.imageObj ? [{ name: '', url: val.imageObj }] : [];
        }
        if (this.type === 'image') {
          this.meidaId = val.images.media_ids[0];
        }
        switch (this.type) {
          case 'text':
            this.form.replyInfo = val.text.content;
            break;
          case 'mpnews':
            this.type = 'news';
            this.visible = true;
            this.src = mpnewsObj.thumb_media_id;
            break;
          default:
            break;
        }
      } else {
        this.$refs.upload.clearFiles();
        this.$refs.uploadPic.clearFiles();
        this.imageUrl = '';
        this.meidaId = '';
        this.src = require('@/assets/error.jpg');
        this.replyInfo = '';
        this.form = {
          title: '标题',
          replyInfo: '',
          url: '',
          typeInfo: 'subscribe',
          type: 'news',
          author: '',
          digest: '',
          need_open_comment: 0,
          only_fans_can_comment: 0
        };
      }
    },
    save() {
      var data = {};
      if (this.type === 'text') {
        if (this.form.replyInfo === '') {
          Message.warning('请填写内容');
          return false;
        }
      }
      if (this.type === 'news') {
        if (
          this.form.title === '' ||
          this.form.url === '' ||
          this.meidaIdNews === ''
        ) {
          Message.warning('请检查填写项，未填写完整');
          return false;
        } else {
          var reg = /^(((https?|ftp|news):\/\/|\w+(\.\w+)+)(:\w+)?).*/;
          if (!reg.test(this.form.url)) {
            Message.warning('请检查链接格式是否正确');
            return false;
          }
        }
      }
      if (this.type === 'image') {
        if (this.imageUrl === '' || this.meidaId === '') {
          Message.warning('请上传图片');
          return false;
        }
      }
      data.msgtype = this.type;
      switch (this.type) {
        case 'text':
          data.text = {
            content: this.form.replyInfo
          };
          break;
        case 'image':
          var mediaid = [];
          mediaid.push(this.meidaId);
          data.images = {
            media_ids: mediaid
          };
          data.imageObj = this.imageUrl;
          break;
        case 'news':
          this.setMpnews();
          data.msgtype = 'mpnews';
          data.mpnews = {
            media_id: this.meidaIdNews
          };
          data.send_ignore_reprint = 0;
          data.mpnewsObj = {
            thumb_media_id: this.imageUrl,
            author: this.form.author,
            title: this.form.title,
            content_source_url: this.form.url,
            content: this.form.replyInfo,
            digest: this.form.digest,
            show_cover_pic: 1,
            need_open_comment: this.form.need_open_comment,
            only_fans_can_comment: this.form.only_fans_can_comment
          };
          break;
        default:
          break;
      }
      return data;
    },
    handleRemovePic(file, fileList) {
      this.$refs.uploadPic.clearFiles();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadRePic(val) {
      this.upload.media = val.file;
      this.uploading = true;
      this.handleChange(val);
      uploadTemp({ ...this.upload }).then(res => {
        this.meidaId = res;
        this.uploading = false;
      });
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles();
      this.src = require('@/assets/error.jpg');
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    async uploadPic(val) {
      this.uploading = true;
      this.src = await this.getBase64(val.file);
      this.upload.media = val.file;
      this.handleChange(val);
      uploadTemp({ ...this.upload }).then(res => {
        this.meidaIdNews = res;
        this.uploading = false;
      });
    },
    setMpnews() {
      var data = { articles: [] };
      var list = {
        thumb_media_id: this.meidaId,
        author: this.form.author,
        title: this.form.title,
        content_source_url: this.form.url,
        content: this.form.replyInfo,
        digest: this.form.digest,
        show_cover_pic: 1,
        need_open_comment: this.form.need_open_comment,
        only_fans_can_comment: this.form.only_fans_can_comment
      };
      data.articles.push(list);
      uploadNews(data).then(res => {
        this.meidaIdNews = res;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    closeTab() {
      this.visible = false;
    },
    async handleChange(file, fileList) {
      console.log('handleChange -> file', file);
      const res = await uploadImg({
        file: file.file,
        introduction: '公众号群发消息'
      });
      this.imageUrl = res;
    }
  },
  components: {}
};
</script>

<style lang="less">
@import url("./officialLess.less");
.official-less {
  .el-textarea {
    margin: 8px;
    width: 95% !important;
  }
  .upload-box {
    display: flex;
    align-items: flex-start !important;
  }
}
</style>
