<!-- 事件分析 -->
<template>
  <div class="event-wrapper">
    <div class="utils-event">
      <div class="event-title">
        <div class="title-name">
          <i class="iconfont icon-dianji"></i>
          <span>事件分析</span>
        </div>
        <div class="event-list">
          <el-dropdown @command="handleCommand" size="medium" trigger="click">
            <span class="el-dropdown-link">
              <i class="iconfont icon-caidan"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item" v-for="item in menuListData" :key="item.modelId">
                <div class="menulist">
                  <span>{{ item.modelName }}</span>
                  <i @click.stop="removeMenulist(item)" class="iconfont icon-delete"></i>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="new-event-info">
        <span> 模型条件 </span>
        <span>
          <i class="iconfont icon-xinjian"></i>
          新建
        </span>
      </div>
      <div class="utils-filter">
        <Indicate @getEventCodesValue="getEventCodesValue" :eventData="eventCodes_" />
        <Dimension @getDimensionData="getDimensionData" :target="target_" />
        <Common @getComonData="getComonData" :filterParameter="filterParameter_" />
      </div>
      <div class="query-btn">
        <el-button @click="getFilterMapData">查询</el-button>
      </div>
    </div>
    <div class="event-map-list">
      <el-row>
        <el-col :span="24">
          <el-card class="map-header" style="display: block;">
            <el-row>
              <el-col :span="18">
                <div class="left">
                  <h3>{{ newModelName ? newModelName : "未命名图表" }}</h3>
                  <el-input size="small" v-model="modelName" placeholder="此处可添加说明"></el-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="save">
                  <span @click="saveEvent"><i class="iconfont icon--custom-save"></i>保存</span>
                  <span @click="getFilterMapData"><i class="iconfont icon-refresh"></i>刷新</span>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="map-min">
        <el-card>
          <div class="map-tap">
            <el-date-picker v-model="date" type="daterange" align="right" size="mini" format="yyyy-MM-dd" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
            </el-date-picker>
            <div class="map-select">
              <ul>
                <li @click="handelClickSelect(item)" :class="{ active: item.show, disable: item.disable }" v-for="item in mapSelect" :key="item.id">
                  <i :class="`iconfont ${item.icon}`"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="map-table">
            <LineChart v-if="mapSelect[0].show" :mapData="mapData" />
            <AreaChart v-if="mapSelect[1].show" :mapData="mapData" />
            <BarChart v-if="mapSelect[2].show" :mapData="mapData" />
            <PieChart v-if="mapSelect[3].show" :mapData="mapData" />
          </div>
        </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import Indicate from './component/Indicate'
import Dimension from './component/Dimension'
import Common from './component/CommonCondition'
import LineChart from './component/LineChart'
import AreaChart from './component/AreaChart'
import BarChart from './component/BarChart'
import PieChart from './component/PieChart'
import TableChart from './component/TableChart'
export default {
  data () {
    /* eslint-disable */
    return {
      eventCodes: [],
      target: [],
      filterParameter: [],
      modelName: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      date: [],
      mapSelect: [
        {
          id: 1,
          icon: 'icon-xian_huaban1',
          show: true,
          disable: false
        },
        {
          id: 2,
          icon: 'icon-duijimianjitu',
          show: false,
          disable: false
        },
        {
          id: 3,
          icon: 'icon-zhuxingtu',
          show: false,
        }
        , {
          id: 4,
          icon: 'icon-bingzhuangtu',
          show: false,
        },
        // {
        //   id: 5,
        //   icon: 'icon-biaoge',
        //   show: false,
        //   disable: true
        // }
      ],
      curSelect: 1,
      mapData: [],
      menuListData: [],
      startDate: '',
      endDate: '',
      newModelName: '',
      eventCodes_: [],
      target_: [],
      filterParameter_: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this._getInitMenuList()
  },
  methods: {
    //指示参数
    getEventCodesValue (value) {
      this.eventCodes = value.map(item => ({ eventCode: item.eventCode, countType: item.countType }))
    },
    //细分维度
    getDimensionData (value) {
      this.target = value.map(item => item.property_code)
    },
    //公共过滤条件
    getComonData (value) {
      this.filterParameter = value.map(item => {
        return {
          type: item.default.type,
          field: item.default.field,
          name: item.default.filter,
          tag: item.default.tag
        }
      })
    },
    //查询
    getFilterMapData () {
      this.mapData = []
      // console.log(this.date);
      // console.log(this.eventCodes)
      if (this.eventCodes.length >= 2) {
        this.mapSelect[0].disable = true
        this.mapSelect[1].disable = true
        this.mapSelect[0].show = false
        this.mapSelect[1].show = false
        this.mapSelect[2].show = true
      }
      this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/DataAnalysis/clickhouse/eventAnalysis', {
        eventCodes: this.eventCodes,
        filterParameter: this.filterParameter,
        target: this.target,
        startDate: this.startDate,
        endDate: this.endDate
      }).then(data => {
        this.mapData = data.data.data
        this.date = []
        this.date.push(this.startDate)
        this.date.push(this.endDate)
      })
    },
    handelClickSelect (res) {
      if (res.disable) return
      this.mapSelect = this.mapSelect.map(item => {
        item.show = false
        if (item.id === res.id) {
          item.show = true
          this.curSelect = item.id
        }
        return item
      })
    },
    //查询列表
    _getInitMenuList () {
      this.$http.post(`https://cdp.h5sites.com/scrm-platform/v1/modelList/listEventModel`).then(res => {
        this.menuListData = res.data.data.list
        if (this.menuListData.length !== 0) {
          this.handleCommand(this.menuListData[0])
        }
      })
    },
    // 删除菜单项
    removeMenulist (item) {
      this.$http.post(`https://cdp.h5sites.com/scrm-platform/v1/modelList/deleteEventModel?modelId=${item.modelId}`).then(() => {
        this._getInitMenuList()
      })
    },
    handleCommand (item) {
      this.newModelName = item.modelName
      this.eventCodes = item.eventCodes
      this.eventCodes_ = item.eventCodes
      this.filterParameter = item.filterParameter
      this.filterParameter_ = item.filterParameter
      this.target = item.target
      this.target_ = item.target
      this.startDate = item.startDate
      this.endDate = item.endDate
      this.getFilterMapData()
    },
    handelDate () {
      console.log(this.date)
    },
    saveEvent () {
      if (!this.modelName) {
        this.$message.info('请添加命名')
        return
      }
      this.$http.post(`https://cdp.h5sites.com/scrm-platform/v1/modelList/eventAnalysisModel`, {
        eventCodes: this.eventCodes,
        filterParameter: this.filterParameter,
        target: this.target,
        startDate: this.startDate,
        endDate: this.endDate,
        modelName: this.modelName
      }).then(res => {
        this._getInitMenuList()
      })

      this.modelName = ''
    }
  },
  components: {
    Indicate,
    Dimension,
    Common,
    LineChart,
    AreaChart,
    BarChart,
    PieChart,
    TableChart
  },
  filters: {

  },
  watch: {
    date () {
      this.startDate = this.date[0]
      this.endDate = this.date[1]
    }
  }
}
</script>
<style scoped>
@import url("./index.less");
</style>
