<template>
  <div class="group-drainage-detail">
    <div class="topInput">
      <i class="el-icon-arrow-left" style="width: 20px" @click="back"></i>
      <h3>群活码</h3>
    </div>
    <el-row :gutter="20">
      <el-col :span="6" class="bgc borderR">
        <el-steps direction="vertical" :active="active">
          <el-step title="基本信息" icon="iconfont icon-icon-test">
            <span slot="description">设置群活码基本信息
            </span>
          </el-step>
          <el-step title="实际群码" icon="iconfont icon-duorenxiezuo">
            <span slot="description">
              添加并管理实际群码
            </span>
          </el-step>
          <el-step title="群活码" icon="iconfont icon-xinfeng">
            <span slot="description">
              设置完成后生成群活码
            </span>
          </el-step>
        </el-steps>
      </el-col>
      <el-col :span="18" class="bgc">
        <div class="grid-content bg-purple">
          <div v-show="active == 1">
            <el-form :model="data" :rules="rules" ref="ruleForm">
              <el-form-item label="活动名称" prop="codeName">
                <el-input v-model="data.codeName" ref='codeName'></el-input>
              </el-form-item>
              <el-form-item label="活动描述" prop="codeDescribe">
                <el-input v-model="data.codeDescribe"></el-input>
              </el-form-item>
              <el-form-item label="自动建群-群名前缀" prop="room_base_name">
                <el-input v-model="data.room_base_name"></el-input>
              </el-form-item>
              <el-form-item label="自动建群-起始序号" prop="room_base_id">
                <el-input v-model.number="data.room_base_id"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="active == 2">
            选择群
            <el-select v-model="chooseGroupList" value-key="group_id" multiple placeholder="请选择" :multiple-limit='5' @change='selectChange'>
              <el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name" :value="item">
              </el-option>
            </el-select>
            <el-table v-show="chooseGroupList.length > 0" :data="chooseGroupList" style="width: 100%">
              <el-table-column prop="group_name" label="群名称" width="200">
              </el-table-column>
              <el-table-column prop="create_time" label="创建时间" width="180">
              </el-table-column>
              <el-table-column prop="user_name" label="创建人">
              </el-table-column>
              <el-table-column prop="num" label="当前群人数">
              </el-table-column>
            </el-table>
          </div>
          <div v-show="active == 3">
            <el-result icon="success" title="" subTitle="群活码创建成功，支持复制二维码连接或直接下载">
              <template slot="extra">
                <el-image :src="codeUrl"></el-image>
                <div class="linkbtnList">
                  <el-link type="primary" @click="copyUrl">复制链接</el-link>
                  <el-link type="primary" @click="downloadGroup">下载二维码</el-link>
                </div>
              </template>
            </el-result>
          </div>
        </div>
        <div class="postionBottom">
          <div v-if="active != 3">
            <el-button @click="previous" type="primary" plain :disabled="active == 1">上一步</el-button>
            <el-button @click="next" type="primary" :disabled="active == 3" :loading="loading">下一步</el-button>
          </div>
        </div>
        <div class="over">
          <el-button type="primary" @click="back" v-show="active == 3">完成</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getGroupDetail, postAddJoinGrouptWay, postUpdateJoinGrouptWay } from '@/service/drainage'
import { Message } from 'element-ui'
import qs from 'qs'
export default {
  props: ['dataDetail'],
  data () {
    return {
      active: 1,
      data: {
        scene: 2,
        codeName: '',
        codeDescribe: '',
        auto_create_room: 1,
        room_base_name: '',
        room_base_id: 1,
        chat_id_list: []
      },
      rules: {
        codeName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        codeDescribe: [
          { required: true, message: '请输入活动描述', trigger: 'blur' }
        ],
        room_base_name: [
          { max: 40, message: '长度不超过40字符', trigger: 'blur' }
        ],
        room_base_id: [
          { type: 'number', message: '请输入数字' }
        ]
      },
      groupList: [],
      chooseGroupList: [],
      codeUrl: '',
      loading: false,
      edit: false,
      downLoadUrl: `${process.env.NODE_ENV === 'development' ? 'https://cdp.h5sites.com' : window.location.protocol + '//' + window.location.hostname + ':' + '8090'}/scrm-platform/qrcode/generate/download/pic?`
    }
  },
  computed: {},
  created () {
    this.getGroupDetail()
  },
  mounted () {
    this.$ref.codeName.focus()
  },
  watch: {
    dataDetail (val) {
      console.log('val', val)
      if (val && val.id) {
        this.data.id = val.id
        this.data.codeName = val.codeName
        this.data.codeDescribe = val.codeDescribe
        this.data.room_base_name = val.roomBaseName
        this.data.room_base_id = val.roomBaseId
        this.data.config_id = val.configId
        this.codeUrl = val.codeUrl
        this.chooseGroupList = val.groupLists
        this.edit = true
      }
    }
  },
  methods: {
    getGroupDetail () {
      getGroupDetail().then(res => {
        this.groupList = res.list
      })
    },
    back () {
      this.active = 1
      this.data = {
        scene: 2,
        codeName: '',
        codeDescribe: '',
        auto_create_room: 1,
        room_base_name: '',
        room_base_id: 1,
        chat_id_list: []
      }
      this.chooseGroupList = []
      this.codeUrl = ''
      this.edit = false
      this.$emit('visabledetails', false)
    },
    next () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.active++ > 2) {
            this.active = 0
          }
        } else {
          return false
        }
      })
      if (this.active === 3) {
        this.active--
        if (this.chooseGroupList.length === 0) {
          Message.warning('请选择群！')
        } else {
          this.loading = true
          this.chooseGroupList.map(item => {
            this.data.chat_id_list.push(item.group_id)
          })
          if (this.edit) {
            postUpdateJoinGrouptWay({ ...this.data }).then(res => {
              this.codeUrl = res
              this.loading = false
              this.back()
            })
          } else {
            postAddJoinGrouptWay({ ...this.data }).then(res => {
              this.codeUrl = res
              this.loading = false
              this.active++
            })
          }
        }
      }
    },
    previous () {
      if (this.active-- < 0) this.active = 0
    },
    selectChange () {
      console.log('ddddd=>', this.chooseGroupList)
    },
    copyUrl () {
      var oInput = document.createElement('input')
      oInput.value = this.codeUrl
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      Message.success('复制成功')
    },
    downloadGroup () {
      if (this.codeUrl) {
        window.open(this.downLoadUrl + qs.stringify({ urlString: this.codeUrl }), '_self')
      } else {
        Message.error('下载失败')
      }
    }
  },
  components: {}
}
</script>

<style lang="less">
@import url("./index.less");
</style>
