<template>
  <div class="enterprise-settings">
    <el-dialog title="小鹅通设置" :visible.sync="dialogFormVisible" :before-close="back">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="clientId" prop="clientId">
          <el-input v-model="ruleForm.clientId"></el-input>
        </el-form-item>
        <el-form-item label="secretKey" prop="secretKey">
          <el-input v-model="ruleForm.secretKey"></el-input>
        </el-form-item>
        <el-form-item label="appId" prop="appId">
          <el-input v-model="ruleForm.appId"></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getXiaoErtongBackendGetConfig,
  postXiaoErtongBackendUpdateConfig
} from '@/service/xiaoetong';
import { Message } from 'element-ui';
export default {
  components: {},
  props: ['show'],
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        clientId: '',
        secretKey: '',
        appId: ''
      },
      rules: {
        clientId: [
          { required: true, message: '请输入clientId', trigger: 'blur' }
        ],
        secretKey: [
          { required: true, message: '请输入secretKey', trigger: 'blur' }
        ],
        appId: [
          { required: true, message: '请输入appId', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {},
  created() {
    this.getXiaoErtongBackendGetConfig();
  },
  mounted() {},
  watch: {
    show(val) {
      this.dialogFormVisible = val;
    }
  },
  methods: {
    getXiaoErtongBackendGetConfig() {
      getXiaoErtongBackendGetConfig().then(res => {
        if (res) {
           this.ruleForm = res;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          postXiaoErtongBackendUpdateConfig({ ...this.ruleForm })
            .then(res => {
              console.log(
                '🚀 ~ file: index.vue ~ line 86 ~ postEnterpriseWechatConfig ~ res',
                res
              );
              this.dialogFormVisible = false;
              this.$emit('visableXiaoeShow', false);
              Message.success('添加成功');
            })
            .catch(function(error) {
              // 处理 getJSON 和 前一个回调函数运行时发生的错误
              console.log('发生错误！', error);
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    back() {
      this.dialogFormVisible = false;
      this.$emit('visableXiaoeShow', false);
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
