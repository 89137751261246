/* eslint-disable no-undef */
import axios from 'axios'
import {
  Message
} from 'element-ui'
import qs from 'qs'
// const { CancelToken } = axios
const api = axios.create({
  timeout: 150000,
  headers: {
    'Content-Type': 'application/json',
    withCredentials: false
  }
})
api.defaults.baseURL = 'https://cdp.h5sites.com'

// 添加请求拦截器
api.interceptors.request.use(
  config => {
    config.headers.Authorization = window.sessionStorage.getItem('cookie') || ''
    return config
  },
  err => {
    console.log(err)
  }
)

// api.interceptors.request.use((config) => {
//   config.headers.Authorization = sessionStorage.getItem('token')
//   config.cancelToken = new CancelToken((c) => {
//     cancelFetch = c
//   })
//   return config
// })

// fetch.interceptors.response.use((response) => {

//       // if (code === 200) {
//       //   return result
//       // }
//       // if (code === 1) {
//       //   return data
//       // }
//       // if (code === 401 || code === 41601) {
//       //   window.location.href = '#/login'
//       //   return Promise.reject(msg)
//       // }
//     message.error(msg)
//     return Promise.reject(msg)
//   },
//   (error) => {
//     if (axios.isCancel(error)) {
//       return Promise.reject(error)
//     }
//     const { code, message: reason } = error.response.data || {}
//     const msg = reason || '网络请求异常，请稍后重试!'
//     if (code === 401 || code === 41601) {
//       window.location.href = '#/login'
//       return Promise.reject(msg)
//     }
//     message.error(msg)
//     return Promise.reject(msg)
//   })
api.interceptors.response.use((response) => {
  let {
    code,
    message: reason,
    data
  } = response.data
  reason = response.data.message || response.data.msg
  if (code === 200) {
    return data
  }
  if (code === 401) {
    window.location.href = '#/login'
    return Promise.reject(msg)
  }
  // Message.error(reason)
  Message({
    dangerouslyUseHTMLString: true,
    type: 'error',
    iconClass: 'error-icon',
    customClass: 'custome-msg',
    showClose: true,
    message: `<strong style="color: black;">${reason}</strong>`
  });
  return Promise.reject(reason)
}, (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }
  let {
    message: reason
  } = error.response.data || {}
  reason = response.data.message || response.data.msg
  return Promise.reject(reason)
})

export function post(url, params = {}, config) {
  return api.post(url, params, config)
}

export function get(url, params, config) {
  const URL = params ? [url, params && qs.stringify(params)].join('?') : url
  return api.get(URL, config).then((res) => {
    return res
  })
}

export function create(url, params = {}, config) {
  return api.post(url, params, config).then((res) => {
    Message.success('创建成功')
    return res
  })
}
export function update(url, params = {}, config) {
  return api.post(url, params, config).then((res) => {
    Message.success('修改成功')
    return res
  })
}

// // delete 不允许使用，remove 替代
export function remove(url, params = {}, config) {
  return api.post(url, params, config).then((res) => {
    Message.success('删除成功')
    return res
  })
}

// // 文件上传
export function upload(url, params, config) {
  const formData = new FormData() // 创建form对象
  Object.keys(params).map(v => formData.append(v, params[v]))
  return api.post(url, formData, config)
}

// export function deletes<T>(url: string, params?: object, config?: ConfigMsg) {
//   const URL = params ? [url, params && qs.stringify(params)].join('?') : url
//   return fetch.delete<T, T>(URL, config).then((res) => {
//     if (config?.message) {
//       message.success(config?.message || '删除成功')
//     }
//     return res
//   })
// }
