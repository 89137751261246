<!-- 事件分析 -->
<template>
  <div class="event-wrapper">
    <div class="utils-event">
      <div class="event-title">
        <div class="title-name">
          <i class="iconfont icon-dianji"></i>
          <span>建立漏斗</span>
        </div>
        <div class="event-list">
          <el-dropdown @command="handleCommand" size="medium" trigger="click">
            <span class="el-dropdown-link">
              <i class="iconfont icon-caidan"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item" v-for="item in menuListData" :key="item.modelId">
                <div class="menulist">
                  <span>{{ item.modelName }}</span>
                  <i @click.stop="removeMenulist(item)" class="iconfont icon-delete"></i>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="new-event-info">
        <span> 模型条件 </span>
        <span>
          <i class="iconfont icon-xinjian"></i>
          新建
        </span>
      </div>
      <div class="utils-filter">
        <Indicate @getEventCodesValue="getEventCodesValue" :eventData="eventCodes_" />
        <Dimension @getDimensionData="getDimensionData" :target="target_" />
        <Common @getComonData="getComonData" :filterParameter="filterParameter_" />
      </div>
      <div class="query-btn">
        <el-button @click="getFilterMapData">查询</el-button>
      </div>
    </div>
    <div class="event-map-list">
      <el-row>
        <el-col :span="24">
          <el-card class="map-header" style="display: block;">
            <el-row>
              <el-col :span="18">
                <div class="left">
                  <h3>{{ newModelName ? newModelName : "未命名图表" }}</h3>
                  <el-input size="small" v-model="modelName" placeholder="此处可添加说明"></el-input>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="save">
                  <span @click="saveEvent"><i class="iconfont icon--custom-save"></i>保存</span>
                  <span @click="getFilterMapData"><i class="iconfont icon-refresh"></i>刷新</span>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="map-min">
        <el-card>
          <div class="map-tap">
            <div class="date-selct">
              <el-date-picker v-model="date" type="daterange" align="right" size="mini" format="yyyy-MM-dd" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
              </el-date-picker>
              <div class="select-option" v-if="headeSele">
                <div @click="
                    () => {
                      changeFlag = !changeFlag;
                    }
                  " :class="{
                    'change-v': true,
                    'change-active': changeFlag,
                  }">
                  转化
                </div>
                <div @click="handeltrend" :class="{
                    'change-m': true,
                    'change-active': !changeFlag,
                  }">
                  转化趋势
                </div>
                <div class="chage-m-select" v-if="!changeFlag">
                  <el-dropdown size="medium" placement="bottom" @command="conversionRates">
                    <span class="el-dropdown-link el-step">
                      {{ conversionRatesData }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="item" v-for="item in setpValue" :key="item">
                        {{ item }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <span>的</span>
                  <el-dropdown size="medium" placement="bottom" @command="handleStep">
                    <span class="el-dropdown-link">
                      {{ handleStepData }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1"> 转化率 </el-dropdown-item>
                      <el-dropdown-item command="2">
                        转化用户数
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="map-select">
              <ul>
                <li @click="handelClickSelect(item)" :class="{ active: item.show, disable: item.disable }" v-for="item in mapSelect" :key="item.id">
                  <i :class="`iconfont ${item.icon}`"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="map-table">
            <template v-if="headeSele">
              <template v-if="changeFlag">
                <BarChart v-if="mapSelect[0].show" :mapData="mapData" />
              </template>
              <LineChart v-if="!changeFlag" :mapData="lineDatas" />
            </template>
            <TableChart v-if="mapSelect[1].show" :mapData="mapData" :target="target" />
          </div>
        </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import Indicate from './component/Indicate'
import Dimension from './component/Dimension'
import Common from './component/CommonCondition'
import LineChart from './component/LineChart'
// import AreaChart from './component/AreaChart'
import BarChart from './component/BarChart'
// import PieChart from './component/PieChart'
import TableChart from './component/TableChart'
export default {
  data () {
    /* eslint-disable */
    return {
      eventCodes: [],
      target: '',
      filterParameter: [],
      modelName: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      date: [],
      mapSelect: [
        {
          id: 1,
          icon: 'icon-zhuxingtu',
          show: true,
        },
        {
          id: 2,
          icon: 'icon-biaoge',
          show: false,
        }
      ],
      curSelect: 1,
      mapData: [],
      menuListData: [],
      startDate: '',
      endDate: '',
      changeFlag: true,
      conversionRatesData: '第1步到第2步',
      handleStepData: '转化率',
      setpValue: [],
      setpNum: 2,
      rateString: 'rate',
      lineDatas: {},
      headeSele: true,
      newModelName: '',
      eventCodes_: [],
      target_: '',
      filterParameter_: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this._getInitMenuList()
  },
  methods: {
    //建立漏斗
    getEventCodesValue (value) {
      this.eventCodes = value.map(item => (item.eventCode))
      console.log(this.eventCodes)
      if (this.eventCodes.length >= 2) {
        let setp = []
        this.eventCodes.forEach((v, i) => {
          console.log(i)
          if (i >= 1) {
            setp.push(`第${i}步到第${i + 1}步`)
          }
        })
        this.setpValue = setp
      }
    },
    //细分维度
    getDimensionData (value) {
      this.target = value.map(item => item.property_code).join('')
    },
    //公共过滤条件
    getComonData (value) {
      this.filterParameter = value.map(item => {
        return {
          type: item.default.type,
          field: item.default.field,
          name: item.default.filter,
          tag: item.default.tag
        }
      })
    },
    //查询
    getFilterMapData () {
      this.mapData = []

      // console.log(this.date);
      // console.log(this.eventCodes)
      this.$http.post('https://cdp.h5sites.com/scrm-platform/v1/DataAnalysis/clickhouse/hopperAnalysis', {
        eventCodes: this.eventCodes,
        filterParameter: this.filterParameter,
        target: this.target,
        startDate: this.startDate,
        endDate: this.endDate
      }).then(data => {
        console.log("🚀 ~ file: index.vue ~ line 319 ~ getFilterMapData ~ data", data.data.data)
        this.mapData = data.data.data
        this.date = []
        this.date.push(this.startDate)
        this.date.push(this.endDate)
      })
    },
    handelClickSelect (res) {
      if (res.disable) return
      this.mapSelect = this.mapSelect.map(item => {
        item.show = false
        if (item.id === res.id) {
          item.show = true
          this.curSelect = item.id
        }
        return item
      })
      if (this.mapSelect[1].show) {
        this.headeSele = false
      } else {
        this.headeSele = true
      }
    },
    //查询列表
    _getInitMenuList () {
      this.$http.post(`https://cdp.h5sites.com/scrm-platform/v1/modelList/listHopperModel`).then(res => {
        this.menuListData = res.data.data.list
        if (this.menuListData.length !== 0) {
          this.handleCommand(this.menuListData[0])
        }
      })
    },
    // 删除菜单项
    removeMenulist (item) {
      this.$http.post(`https://cdp.h5sites.com/scrm-platform/v1/modelList/deleteHopperModel?modelId=${item.modelId}`).then(() => {
        this._getInitMenuList()
      })
    },
    handleCommand (item) {
      console.log(item)
      this.newModelName = item.modelName
      this.eventCodes = item.eventCodes
      this.eventCodes_ = item.eventCodes
      this.filterParameter = item.filterParameter
      this.filterParameter_ = item.filterParameter
      this.target = item.target
      this.target_ = item.target
      this.startDate = item.startDate
      this.endDate = item.endDate
      this.getFilterMapData()
    },
    handelDate () {
      console.log(this.date)
    },
    saveEvent () {
      if (!this.modelName) {
        this.$message.info('请添加命名')
        return
      }
      let targets = [this.target]
      this.$http.post(`https://cdp.h5sites.com/scrm-platform/v1/modelList/hopperAnalysisModel`, {
        eventCodes: this.eventCodes,
        filterParameter: this.filterParameter,
        target: targets.join(''),
        startDate: this.startDate,
        endDate: this.endDate,
        modelName: this.modelName
      }).then(res => {
        this._getInitMenuList()
      })

      this.modelName = ''
    },
    conversionRates (num) {
      this.setpNum = num.slice(-2, -1)
      this.filterLineData(this.setpNum + '', this.rateString)
      this.conversionRatesData = num
    },
    handleStep (num) {
      if (num === '1') {
        this.handleStepData = '转化率'
        this.rateString = 'rate'
      } else {
        this.handleStepData = '转化用户数'
        this.rateString = 'user'
      }
      this.filterLineData(this.setpNum + '', this.rateString)
    },
    filterLineData (step, rate) {
      let linexAxis = this.mapData.transformTendency.map(item => item.datadate)
      let dateSet = new Set()
      linexAxis.forEach(i => {
        dateSet.add(i)
      })
      linexAxis = Array.from(dateSet)
      if (this.target.length > 0) {
        let legend = []
        legend = this.mapData.transform.numUser.map(v => {
          console.log(v)
          return v.type
        }).filter(m => {
          console.log(m)
          return m !== '全部'
        })
        let lineCurrentData = []
        legend.forEach(v => {
          let lineData = {}
          lineData.data = []
          this.mapData.transformTendency.forEach(item => {
            if (v === item.legend) {
              lineData.name = item.legend,
                lineData.type = 'line'
              lineData.smooth = true
              item.overview.forEach(m => {
                if (m.step === step) {

                  let nData = rate === 'rate' ? m.rate : m.users
                  console.log('nData', nData)
                  lineData.data.push(nData)
                }
              })
            }
          })
          lineCurrentData.push(lineData)
        })
        this.lineDatas = {
          series: lineCurrentData,
          xAxis: linexAxis,
          legend: legend
        }
      } else {
        let legend = ['总体']
        let lineCurrentData = []
        legend.forEach(v => {
          let lineData = {}
          lineData.data = []
          this.mapData.transformTendency.forEach(item => {
            lineData.name = v
            lineData.type = 'line'
            lineData.smooth = true
            item.overview.forEach(m => {
              if (m.step === step) {
                let nData = rate === 'rate' ? m.rate : m.users
                console.log('nData', nData)
                if (typeof nData === 'string' && nData.includes('%')) {
                  nData = nData.split('%')[0]
                }
                lineData.data.push(nData)
              }
            })
          })
          lineCurrentData.push(lineData)
        })
        this.lineDatas = {
          series: lineCurrentData,
          xAxis: linexAxis,
          legend: legend
        }

      }
      console.log('%c 🎂 this.lineDatas: ', 'font-size:20px;background-color: #3F7CFF;color:#fff;', this.lineDatas);

    },
    handeltrend () {
      this.changeFlag = !this.changeFlag
      this.filterLineData(this.setpNum + '', this.rateString)
    }
  },
  components: {
    Indicate,
    Dimension,
    Common,
    BarChart,
    LineChart,
    TableChart
  },
  filters: {

  },
  watch: {
    date () {
      this.startDate = this.date[0]
      this.endDate = this.date[1]
    },
    changeFlag () {
      if (this.changeFlag) {
        this.mapSelect[0].icon = 'icon-zhuxingtu'
      } else {
        this.mapSelect[0].icon = 'icon-xian_huaban1'
      }
    }
  }
}
</script>
<style scoped>
@import url("./index.less");
</style>
