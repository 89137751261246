<template>
  <div class="crowd">
    <!-- 选择人群 -->
    <el-row class="headerSelect">
      <el-col :span="14">
        <span>请选择人群：</span>
        <el-select v-model="crowdValue" placeholder="请选择">
          <el-option v-for="item in getSelectCrowd" :key="item.group_id" :label="item.group_name" :value="item.group_id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="10" class="btn_box">
        <el-button @click="queryInfo">查询</el-button>
      </el-col>
    </el-row>
    <!-- 分析维度 -->
    <el-row class="dimensionality">
      <el-col :span="24" class="spansSelect">
        <span>分析维度：</span>
        <el-select @change="handleChange" @remove-tag="removeTag" multiple class="w300" v-model="dimensiValue" placeholder="请选择">
          <el-option v-for="(item, index) in dimensionality" :key="index + 'only'" :label="item" :value="item"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="echarts">
      <div v-for="(item, index) in echartsObjArr" class="echarts_item" :key="index + 'echart'" :id="`echart${index + 1}`"></div>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  /* eslint-disable */
  name: "Crowd",
  data () {
    return {
      crowdValue: "", // 所选择人群
      getSelectCrowd: [], // 选择人群下拉框数据
      dimensiValue: [], // 选择的分析维度
      dimensionality: [], // 分析维度下拉框数据
      dimensionalityLast: [], // 分析维度下拉框数据
      echartsArr: [],
      echartsNameArr: [],
      echartsObjArr: [],
      crowdNum: 0
    };
  },
  methods: {
    // 获取人群下拉框数据
    async _getSelectCrowd () {
      const { data } = await this.$http.get(
        "https://cdp.h5sites.com/scrm-platform/v1/UserGroup/analysis/list"
      );
      if (data.code !== 1) return;
      this.getSelectCrowd = data.data;
      this.crowdValue = data.data[0].group_id;
    },
    // 获取分析维度选项
    async _getDimensionalitySelect () {
      const { data } = await this.$http.post(
        "https://cdp.h5sites.com/scrm-platform/v1/UserGroup/analysisDimension"
      );
      if (data.code !== 1) return;
      this.dimensionality = data.data;
    },
    // 获取初始分析维度-用户人群分析维度历史保存项
    async _getInitDimensionality () {
      const { data } = await this.$http.get(
        "https://cdp.h5sites.com/scrm-platform/v1/UserGroup/analysis/dimension/list"
      );
      if (data.code !== 1) return;
      this.dimensiValue = Array.from(new Set(data.data));
      this.dimensionalityLast = [...this.dimensiValue];
      // this.dimensiValue.forEach((item) => {
      //   this.findAnalyse({ dimension: item }); // 这里发了请求 处理了echartsObjArr
      // });
      this.getEchartsData();
    },
    // 查询功能
    queryInfo () {
      this.getEchartsData();
    },
    // 根据分析维度获取echarts数据
    async getEchartsData () {
      this.echartsObjArr = [];
      let urlArr = [];
      for (let i = 0; i < this.dimensiValue.length; i++) {
        let params = {};
        params.groupId = this.crowdValue;
        params.dimension = this.dimensiValue[i];
        urlArr.push({
          groupId: params.groupId,
          dimension: params.dimension,
          path: `https://cdp.h5sites.com/scrm-platform/v1/UserGroup/dimension/analysis?groupId=${params.groupId}&dimension=${params.dimension}`
        });
      }
      let promises = urlArr.map(async ({ path }) => await this.$http.get(path));
      const res = await Promise.all(promises);
      res.forEach(({ data, config }) => {
        let params = urlArr.find(e => e.path == config.url);
        this.echartsObjArr.push({ title: params.dimension, data: data.data });
      });
      this.$nextTick(() => {
        this.echartsObjArr.forEach((item, index) => {
          if (!item.data.length) return;
          this.drawLine(item.data, `echart${index + 1}`, item.title);
        });
      });
    },
    // 查询功能
    async findAnalyse (params) {
      // params.groupId = this.crowdValue;
      params.groupId = "user_group_0002";
      const {
        data
      } = await this.$http.get(
        "https://cdp.h5sites.com/scrm-platform/v1/UserGroup/dimension/analysis",
        { params }
      );
      if (data.code !== 1) return;
      this.echartsObjArr.push({ title: params.dimension, data: data.data });
    },
    // echarts画图
    drawLine (data, idName, title) {
      let xData = data.map(item => item.dimension);
      let yData = data.map(item => item.value);
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById(idName));
      myChart.setOption({
        title: {
          show: true,
          text: title
        },
        xAxis: {
          type: "category",
          data: xData
        },
        yAxis: {
          type: "value",
          axisLabel: {
            interval: 0
          }
        },
        grid: {
          x: "13%"
        },
        series: [
          {
            data: yData,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "#E3E3E3"
            },
            itemStyle: {
              normal: {
                //这里是重点
                color: "#37A2DA"
              }
            }
          }
        ]
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    // 分析维度select值改变
    handleChange (data) {
      if (data.length) {
        if (this.dimensionalityLast.length < data.length)
          this._changeSelectCrowd("add", data[data.length - 1]);
      }
      this.dimensionalityLast = [...data];
      this.getEchartsData();
    },
    async _changeSelectCrowd (type, dimension) {
      let { data } = await this.$http.get(
        `https://cdp.h5sites.com/scrm-platform/v1/UserGroup/analysis/dimension/editAndadd?type=${type}&dimension=${dimension}`
      );
      if (!data.flag) {
        this.$message.error(data.message);
      }
    },
    removeTag (dimension) {
      this._changeSelectCrowd("delete", dimension);
    }
  },
  mounted () { },
  created () {
    this._getSelectCrowd();
    this._getDimensionalitySelect();
    this._getInitDimensionality();
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.crowd {
  min-height: 700px;
  text-align: left;
  padding: 26px;
}
.btn_box {
  text-align: center;
}
.btn_box .el-button {
  background-color: #ff781b;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 60px;
}
.dimensionality {
  margin-top: 20px;
}
.w300 {
  width: 300px;
}
.echarts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .echarts_item {
    flex: 1;
    min-width: 50%;
    height: 300px;
  }
}
</style>
