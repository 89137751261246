<template >
  <div class="pic-material">
    <el-container>
      <el-header class="groupheader">图片素材</el-header>
      <div class="replytop">
        <el-input placeholder="搜索素材名称" prefix-icon="el-icon-search" v-model="name" @focus="getPageList" :clearable="true" @clear="getPageList" @change="getPageList">
        </el-input>
        <el-button class="topBtn" type="primary" @click="dialogFormVisible = true">
          <i class="el-icon-plus"></i>
          上传素材
        </el-button>
      </div>
      <el-table :data="tableData" :header-cell-style="{ background: '#F9F9F9' }">
        <el-table-column prop="url" label="素材信息">
          <template slot-scope="scope">
            <div class="uesr-infos">
              <el-image :src="scope.row.url" alt=""></el-image>
              <div class="tips">
                <span>{{ scope.row.name }}</span>
                <span>{{ scope.row.width_height }} / {{ scope.row.size }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="230">
        </el-table-column>
        <el-table-column prop="creator" label="创建人" width="230">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="copyUrl(scope.row.url)">复制链接</el-button>
            <el-button type="text" size="medium" @click="downloadGroup(scope.row.url)">下载</el-button>
            <el-button type="text" size="medium" @click="deleteItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page="pageNo" :page-size="10" layout="prev, pager, next" background :total="total" :hide-on-single-page="true">
      </el-pagination>
    </el-container>
    <el-dialog title="上传图片" :visible.sync="dialogFormVisible" class="picDialog">
      <el-upload action="#" list-type="picture-card" ref="upload" :limit="1" :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :http-request="uploadPic" :auto-upload="false">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMaterialList, delMaterialList } from '@/service/material'
import { postForeverMaterial } from '@/service/reply'
import qs from 'qs'
import {
  Message
} from 'element-ui'
export default {
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      name: '',
      dialogFormVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      upload: {
        media: null,
        type: 'image'
      },
      downLoadUrl: 'https://cdp.h5sites.com/scrm-platform/qrcode/generate/download/pic?'
    }
  },
  computed: {

  },
  created() {
    this.getPageList()
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    getPageList() {
      getMaterialList({ pageNo: this.pageNo, pageSize: this.pageSize, name: this.name }).then(res => {
        this.tableData = res.list
        this.total = res.num
      })
    },
    addGroupWel() {
      this.$router.push('/Wechat/ParameterQRCode/addChannelQRcode')
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getPageList()
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getPageList()
    },
    deleteItem(id) {
      delMaterialList({ id: id }).then(res => {
        Message.success('删除成功')
        this.getPageList()
      })
    },
    handleRemove(file) {
      this.$refs.upload.clearFiles()
      this.src = require('@/assets/error.jpg')
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        Message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    submitUpload() {
      this.$refs.upload.submit()
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传文件超出个数
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    async uploadPic(val) {
      this.upload.media = val.file
      postForeverMaterial({ ...this.upload }).then((res) => {
        Message.success('上传成功')
        this.dialogFormVisible = false
        this.getPageList()
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    copyUrl(url) {
      var oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      Message.success('复制成功')
    },
    downloadGroup(url) {
      if (url) {
        window.open(this.downLoadUrl + qs.stringify({ urlString: url }), '_self')
      } else {
        Message.error('下载失败')
      }
    }
  },
  components: {

  }
}
</script>

<style lang="less">
  @import url("./index.less");
</style>
