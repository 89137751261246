<template>
  <div class="BehaviorAnalysis">
    <!-- 访问页面 -->
    <div class="tableBox">
      <div class="title">访问页面</div>
      <div class="timeBox">
        <el-date-picker
          v-model="date"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择日期"
          :clearable="false"
          @change="dateChange"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="table">
        <el-table :data="tableData" style="width: 100%" size="mini">
          <el-table-column prop="page_path" label="页面路径"></el-table-column>
          <el-table-column
            prop="page_visit_pv"
            label="访问次数"
            sortable
          ></el-table-column>
          <el-table-column
            prop="page_visit_uv"
            label="访问人数"
            sortable
          ></el-table-column>
          <el-table-column
            prop="page_staytime_pv"
            label="次均时长（s）"
            sortable
          ></el-table-column>
          <el-table-column
            prop="entrypage_pv"
            label="入口页次数"
            sortable
          ></el-table-column>
          <el-table-column
            prop="exitpage_pv"
            label="退出页次数"
            sortable
          ></el-table-column>
          <el-table-column prop="page_visit_uv" label="退出率" sortable>
            <template slot-scope="scope"
              >{{
                (scope.row.exitpage_pv / scope.row.entrypage_pv).toFixed(4) *
                100
              }}
              %</template
            >
          </el-table-column>
          <el-table-column
            prop="page_share_pv"
            label="分享次数"
            sortable
          ></el-table-column>
          <el-table-column
            prop="page_share_uv"
            label="分享人数"
            sortable
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 表格 -->
    <div>
      <el-row :gutter="20">
        <el-col :span="8" v-for="(item, index) in chartData" :key="index">
          <div class="echatBox">
            <Chart :source="item"></Chart>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getVisitpage, getVisitDistribution } from '@/service/minipro';
import Chart from './chart'
import moment from 'moment';
export default {
  data() {
    return {
      date: moment(new Date())
        .add(-1, 'd')
        .format('YYYY-MM-DD'),
      tableData: [],
      chartData: [],
      pickerOptions: {
        disabledDate: v => {
          var nowDate = new Date();
          return new Date(nowDate.setDate(nowDate.getDate() - 1)) < new Date(v);
        }
      }
    };
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() { },
  watch: {},
  methods: {
    init() {
      const obj = {
        begin_date: moment(this.date).format('YYYYMMDD'),
        end_date: moment(this.date).format('YYYYMMDD')
      };
      getVisitpage(obj).then(res => {
        this.tableData = res.list
      });
      getVisitDistribution(obj).then(res => {
        res && res.map((item, index) => {
          item.id = 'index' + index
        })
        this.chartData = res
      })
    },

    dateChange(date) {
      this.init();
    }
  },
  components: {
    Chart
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
