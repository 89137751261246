<template>
  <div class="customer-stage-main-new">
    <div class="topBox">
      <div>
        <el-radio-group v-model="radio" size="small" @change="radioChange">
          <el-radio-button label="1">
            <i class="iconfont icon-cols"></i>
          </el-radio-button>
          <el-radio-button label="2">
            <i class="iconfont icon-hengxiangtongbi"></i>
          </el-radio-button>
        </el-radio-group>
        <el-breadcrumb separator="|">
          <el-breadcrumb-item :to="{ path: '/' }">共{{ alltotal }}个客户</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a @click="downloadSelection" class="atag">下载Excel</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div>
        <el-button plain @click="showJourneyConfig">旅程阶段配置</el-button>
        <el-button plain @click="showNotset">未设置客户({{ notsetTotal }})</el-button>
        <notset :dialogTableVisible="show" @close-dialogStatus="hideNotSet" ref="notset"></notset>
        <journeystageconfig
          :dialogTableVisible="showJourney"
          @close-dialogStatus="hideJourneyConfig"
          ref="notset"
        ></journeystageconfig>
      </div>
    </div>
    <!-- 拖拽 -->
    <div v-show="radio === '1'">
      <div class="content-main">
        <div class="dragelist">
          <div class="items" v-for="(item, index) in userList" :key="index">
            <div class="titles">
              <p>{{ item.stage }}</p>
              <span>{{ item.count }}个客户</span>
            </div>
            <draggable
              v-model="userList[`${index}`].users"
              animation="300"
              group="site"
              dragClass="dragClass"
              ghostClass="ghostClass"
              chosenClass="chosenClass"
              :move="onMove"
              :stage="userList[`${index}`].stage"
            >
              <transition-group type="transition" tag="div" class="list-group">
                <div
                  class="user"
                  v-for="value in userList[`${index}`].users"
                  :key="value.externalUserid"
                >
                  <div class="tp">
                    <img :src="value.picUrl" alt />
                    <div class="names">
                      <p>{{ value.name }}</p>
                      <span
                        :style="{
                          color:
                            value.addType === '微信'
                              ? '#67C23A'
                              : '#E6A23C',
                        }"
                      >@{{ value.addType }}</span>
                    </div>
                  </div>
                  <div class="follow-up">上次跟进</div>
                  <div class="customer">
                    <span>客服：</span>
                    <p>{{ value.userId }}</p>
                  </div>
                  <div class="tags">
                    <el-tag size="mini" v-for="tag in value.tag.split(',')" :key="tag">{{ tag }}</el-tag>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="user-list" v-show="radio === '2'">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="全部客户" width="250">
          <template slot-scope="scope">
            <div class="uesr-infos">
              <img :src="scope.row.pic_url" alt />
              <div class="text">
                <div class="titles">
                  <p>{{ scope.row.name }}</p>
                  <span
                    :style="{
                      color:
                        scope.row.add_type === '微信用户'
                          ? '#67C23A'
                          : '#E6A23C',
                    }"
                  >@{{ scope.row.add_type }}</span>
                </div>
                <span class="nickName">昵称:{{ scope.row.name }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="addWay" label="客户阶段" width="250">
          <template slot-scope="scope">
            <el-select v-model="scope.row.classify" @change="slectChange(scope.row)">
              <el-option v-for="item in stagList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="user_name" label="所属客户" width="180"></el-table-column>
        <el-table-column prop="tag" label="标签">
          <template slot-scope="scope">
            <el-tag v-for="v in scope.row.tag.split(',')" :key="v" size="mini">{{ v }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="添加时间"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="goDetails(scope.row.external_userid)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      background
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
      :hide-on-single-page="true"
    ></el-pagination>
  </div>
</template>

<script>
import {
  getCustomerStageListSpread,
  getCustomerStageNotset,
  getCustomerStage,
  updateCustomerStage
} from '@/service/customerManage';
import notset from '@/views/CustomerStageNew/NotSetList';
import journeystageconfig from '@/views/CustomerStageNew/JourneyStageConfig';
import draggable from 'vuedraggable';
export default {
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      radio: '1',
      show: false,
      notsetTotal: 0,
      drag: false,
      alltotal: 0,
      userList: [],
      stagList: ['初步沟通', '新加客户', '意向客户', '即将成交', '已付款'],
      tableDataOld: null,
      downLoadUrl:
        'https://cdp.h5sites.com/scrm-platform/wechat/customer/custom_stage/file/download',
      showJourney: false
    };
  },
  components: {
    notset,
    draggable,
    journeystageconfig
  },
  computed: {},
  created() {
    // this.runList()
    this.initDataList();
    this.getCustomerStageNotset();
  },
  mounted() {},
  watch: {},
  methods: {
    radioChange(val) {
      this.radio = val;
      if (val === '1') {
        this.initDataList();
      } else {
        this.runList();
      }
    },
    initDataList() {
      getCustomerStage().then(res => {
        this.userList = res.list;
        this.alltotal = res.total;
      });
    },
    // 列表模块
    runList() {
      getCustomerStageListSpread({
        pageno: this.pageNo,
        pagesize: this.pageSize
      }).then(res => {
        this.tableData = res.list;
        this.tableDataOld = JSON.stringify(res.list);
        this.total = res.total;
      });
    },

    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.runList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.runList();
    },
    // 下载接口
    downloadSelection() {
      const a = document.createElement('a');
      a.href = this.downLoadUrl;
      document.body.appendChild(a);
      a.click(); // 下载
      URL.revokeObjectURL(a.href); // 释放URL 对象
      document.body.removeChild(a); // 删除 a 标签
    },
    // 未设置弹窗
    showNotset() {
      this.show = true;
    },
    // 旅程配置弹窗
    showJourneyConfig() {
      this.showJourney = true;
    },
    hideNotSet(type) {
      this.show = false;
      this.showJourney = false;
      this.getCustomerStageNotset();
      if (type) {
        if (this.radio === '1') {
          this.initDataList();
        } else {
          this.runList();
        }
      }
    },
    hideJourneyConfig(type) {
      this.showJourney = false;
       if (type) {
        if (this.radio === '1') {
          this.initDataList();
        } else {
          this.runList();
        }
      }
    },
    getCustomerStageNotset() {
      getCustomerStageNotset({
        pageno: this.pageNo,
        pagesize: this.pageSize
      }).then(res => {
        this.notsetTotal = res.num;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      clearTimeout(this.flag);
      this.flag = null;
      this.flag = setTimeout(() => {
        const relatedElement = relatedContext.component.$attrs.stage;
        const draggedElement = draggedContext.element;
        updateCustomerStage({
          userId: draggedElement.userId,
          externalUserid: draggedElement.externalUserid,
          tag: draggedElement.tag
            .split(',')
            .filter(v => v !== draggedElement.stage)
            .join(','),
          nowStage: draggedElement.stage,
          changeStage: relatedElement,
          type: '2'
        }).then(() => {
          this.initDataList();
        });
      }, 500);
    },
    goDetails(key) {
      this.$router.push(`/customer/userDetails?userId=${key}`);
    },
    slectChange(obj) {
      var oldObj = JSON.parse(this.tableDataOld).filter(
        item => item.external_userid === obj.external_userid
      );
      updateCustomerStage({
        userId: obj.user_id,
        externalUserid: obj.external_userid,
        tag: obj.tag
          .split(',')
          .filter(v => v !== oldObj[0].classify)
          .join(','),
        nowStage: oldObj[0].classify,
        changeStage: obj.classify
      }).then(() => {
        this.runList();
      });
    }
  }
};
</script>

<style  lang="less">
@import url("./index.less");
</style>
