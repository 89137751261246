<template>
  <div class="Assign-tiktok">
    <el-empty :image="pic" :description="msg"></el-empty>
  </div>
</template>
<script>
import {
  getDouyinCallbackGetAccessToken,
  postDouyinVerifyGetUserinfo
} from '@/service/douyin';
// import { Message } from "element-ui";
import eventBus from '../eventBus';
export default {
  components: {},
  data() {
    return {
      pic: require('@/assets/logo.png'),
      msg: '抖音授权中...'
    };
  },
  computed: {},
  created() {
    this.AssaginTiKTok();
  },
  mounted() {},
  watch: {},
  methods: {
    AssaginTiKTok() {
      getDouyinCallbackGetAccessToken().then(res => {
          this.postDouyinVerifyGetUserinfo(res.openId)
      });
    },
    postDouyinVerifyGetUserinfo(openid) {
      postDouyinVerifyGetUserinfo({
        open_id: openid
      }).then(res => {
        eventBus.$emit('loadPage');
        this.msg = '授权成功，请返回列表页刷新查看。';
      });
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
